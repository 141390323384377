const WeirdCircleIcon = ({ width = 261, height = 238, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 261 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g clipPath="url(#clip0_3224_13188)">
      <path
        d="M116.368 217.352C60.6449 209.249 16.5238 157.455 24.3109 100.486C26.9125 79.7718 34.6996 59.0575 47.6722 40.9216C65.848 22.7857 81.4222 7.24561 107.368 2.07143C115.155 2.07143 125.543 -0.524442 125.543 4.66728C122.942 22.8032 91.7933 33.1516 94.3949 56.4617C154.09 40.9216 234.545 72.0017 218.971 147.106C216.37 160.05 211.184 170.416 203.397 180.782C195.61 191.148 185.221 201.497 174.85 206.688C164.461 209.284 156.674 217.054 147.551 216.177C137.496 218.229 126.985 218.668 116.368 217.37L116.368 217.352Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M203.151 179.063C195.539 189.026 185.502 198.988 175.448 204.057C165.463 206.653 157.922 214.09 149.098 213.406C139.377 215.475 129.217 216.019 118.951 214.809C106.541 213.353 94.8343 209.845 83.8655 204.478C67.3948 196.34 53.3147 184.465 42.6448 169.978C37.6702 163.067 33.469 155.806 30.3577 147.878C27.0706 139.862 24.8381 131.531 23.7659 123.024C22.9573 115.166 22.3596 106.695 23.8889 98.9248C26.7893 86.1034 31.3421 76.0181 38.971 63.2493C41.6428 56.2335 45.0003 49.3755 49.0608 42.7981C58.9749 31.2395 68.6077 20.5404 80.719 12.5248C89.2444 7.10505 98.5784 3.15866 109.705 1.00129C117.281 1.00129 127.354 -1.36657 127.67 3.56205C127.266 8.12235 124.998 12.174 121.887 16.0853C113.344 26.9073 99.0002 36.5716 101.268 53.2868C118.055 49.6912 136.653 49.7613 153.844 53.7253C166.448 56.6369 178.471 61.4076 188.86 68.2831C201.393 76.6144 211.026 87.6644 216.651 101.521C221.555 114.184 222.733 129.093 218.918 146.212C216.106 158.822 210.867 168.978 203.133 179.063L203.151 179.063Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M203.309 178.555C195.856 188.096 186.17 197.673 176.45 202.637C166.87 205.25 159.557 212.354 151.049 211.845C141.68 213.915 131.889 214.476 121.957 213.476C109.354 212.213 97.5238 209.231 86.2562 204.005C69.2933 196.129 54.9848 184.466 43.8051 170.066C38.6547 163.085 34.1898 155.981 30.9906 147.913C27.4574 139.757 25.0844 131.356 23.9242 122.884C23.1859 114.939 21.9379 105.993 23.8715 98.5742C27.9672 86.5947 33.0648 80.1401 42.1 67.5116C44.2445 60.145 47.1449 52.8661 50.8539 45.8678C59.9593 33.4147 69.4164 21.821 82.1078 13.0863C91.1078 7.35083 100.846 3.29918 112.448 1.12427C119.813 1.12427 129.569 -1.0331 130.202 3.64997C130.078 7.96471 128.198 11.8059 125.543 15.5418C118.231 25.8025 105.909 35.0108 108.545 51.305C124.717 48.639 142.787 49.3406 159.188 53.9535C171.141 57.3211 182.549 62.3024 192.393 69.441C204.293 78.0529 212.907 89.0853 218.162 102.714C222.557 115.289 223.33 129.917 219.305 146.51C216.352 158.805 210.973 168.715 203.291 178.537L203.309 178.555Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M203.819 179.097C196.524 188.236 187.207 197.409 177.803 202.285C168.627 204.898 161.543 211.669 153.352 211.353C144.317 213.44 134.895 214.001 125.315 213.194C112.5 212.125 100.565 209.651 89.016 204.583C71.5785 196.935 57.0062 185.499 45.3344 171.222C39.9906 164.189 35.2973 157.208 32.0101 149.035C28.2484 140.738 25.7172 132.267 24.4515 123.813C23.7836 115.78 21.8851 106.378 24.2406 99.2924C29.5316 88.1723 35.1918 85.3133 45.6156 72.8602C47.2328 65.1252 49.6586 57.4429 53.0512 50.0237C61.3656 36.6936 70.6469 24.1703 83.8832 14.734C93.3402 8.66529 103.5 4.54349 115.577 2.3335C122.731 2.3335 132.17 0.386599 133.137 4.82412C133.313 8.8933 131.819 12.5415 129.604 16.0845C123.522 25.7839 113.221 34.5362 116.227 50.4095C131.801 48.6731 149.344 50.0061 164.918 55.268C176.221 59.0916 186.996 64.3009 196.313 71.685C207.563 80.5951 215.192 91.5925 220.061 104.993C223.928 117.481 224.297 131.811 220.061 147.895C216.914 159.857 211.448 169.556 203.836 179.097L203.819 179.097Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M204.556 180.536C197.437 189.254 188.455 198.058 179.402 202.812C170.63 205.443 163.775 211.88 155.9 211.739C147.216 213.826 138.146 214.423 128.918 213.809C115.892 212.932 103.869 210.968 92.021 206.056C74.1089 198.637 59.273 187.447 47.1089 173.275C41.5718 166.189 36.6499 159.349 33.2749 151.035C29.2671 142.598 26.5952 134.056 25.2241 125.62C24.6265 117.517 22.0777 107.624 24.855 100.907C31.3238 90.6459 37.564 91.3826 49.3765 79.1049C50.4488 71.0192 52.4351 62.9159 55.4761 55.0932C62.9995 40.8861 72.105 27.4508 85.9038 17.2954C95.8355 10.8934 106.418 6.68395 118.951 4.45642C125.894 4.45642 135.017 2.71999 136.3 6.91195C136.757 10.7356 135.668 14.1909 133.892 17.5585C129.041 26.6966 120.761 34.9928 124.154 50.4277C139.13 49.6208 156.164 51.5853 170.894 57.5137C181.546 61.7933 191.689 67.2306 200.46 74.8603C211.06 84.0511 217.687 95.0308 222.169 108.186C225.527 120.586 225.457 134.635 221.044 150.21C217.722 161.857 212.168 171.311 204.609 180.589L204.556 180.536Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M205.436 182.693C198.492 191.007 189.861 199.408 181.125 204.074C172.758 206.705 166.148 212.826 158.555 212.861C150.205 214.966 141.504 215.597 132.609 215.141C119.373 214.475 107.262 213.001 95.1153 208.266C76.7461 201.092 61.6465 190.13 48.9727 176.063C43.2598 168.924 38.0919 162.207 34.6114 153.77C30.3575 145.193 27.5274 136.581 26.0684 128.18C25.5411 119.989 22.3242 109.623 25.5235 103.256C33.1875 93.855 39.9727 98.2048 53.1914 86.0674C53.7364 77.6133 55.2657 69.1241 57.9551 60.863C64.67 45.7614 73.6172 31.4316 87.961 20.557C98.3672 13.8218 109.354 9.54216 122.361 7.27956C129.111 7.27956 137.9 5.77113 139.5 9.7C140.238 13.2781 139.553 16.5405 138.199 19.7151C134.578 28.292 128.338 36.1146 132.082 51.1461C146.461 51.2688 162.984 53.8647 176.871 60.442C186.891 65.1777 196.383 70.843 204.627 78.7183C214.594 88.2072 220.201 99.1519 224.314 112.079C227.145 124.391 226.652 138.16 222.064 153.209C218.566 164.522 212.906 173.765 205.4 182.763L205.436 182.693Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M206.403 185.447C199.635 193.357 191.338 201.355 182.936 205.916C174.973 208.564 168.592 214.335 161.315 214.545C153.317 216.65 144.967 217.334 136.424 217.053C122.977 216.597 110.778 215.615 98.315 211.037C79.4712 204.092 64.108 193.375 50.942 179.396C45.0357 172.205 39.6216 165.627 36.0709 157.068C31.5884 148.351 28.6001 139.669 27.0357 131.285C26.5787 123.006 22.7115 112.184 26.3326 106.151C35.1919 97.5913 42.5396 105.554 57.147 93.5922C57.147 84.7874 58.2193 75.8773 60.5748 67.1952C66.4986 51.2166 75.2525 35.9747 90.1763 24.381C101.057 17.3126 112.465 12.9628 125.93 10.6475C132.469 10.6475 140.942 9.3496 142.876 13.0329C143.913 16.3654 143.596 19.4349 142.7 22.4166C140.292 30.4497 136.09 37.7988 140.204 52.4093C153.985 53.4617 169.999 56.689 183.042 63.9153C192.428 69.107 201.27 75.0178 208.987 83.1036C218.303 92.8907 222.909 103.8 226.653 116.481C228.973 128.706 228.094 142.194 223.26 156.717C219.604 167.715 213.838 176.73 206.385 185.447L206.403 185.447Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M207.369 188.551C200.777 196.058 192.814 203.652 184.746 208.107C177.187 210.756 171.035 216.211 164.074 216.597C156.41 218.719 148.412 219.017 140.238 219.315C126.58 219.824 114.293 218.579 101.531 214.159C82.2129 207.458 66.5859 196.97 52.9277 183.096C46.8281 175.835 41.1855 169.415 37.5468 160.715C32.8183 151.84 29.6894 143.106 28.0195 134.739C27.6152 126.391 23.1152 115.077 27.1406 109.395C37.1953 101.695 45.1054 113.271 61.1015 101.449C60.5742 92.276 61.1894 82.9625 63.1933 73.8594C68.3261 57.0039 76.9043 40.8499 92.373 28.5371C103.711 21.1529 115.541 16.6979 129.48 14.3651C135.809 14.3651 143.965 13.2777 146.215 16.7154C147.533 19.8024 147.603 22.6789 147.164 25.4852C145.969 32.9922 143.807 39.8501 148.289 54.0221C161.473 56.0041 176.994 59.8804 189.176 67.7556C197.947 73.4209 206.121 79.5422 213.293 87.8735C221.977 97.9588 225.562 108.833 228.937 121.286C230.748 133.424 229.447 146.631 224.42 160.628C220.588 171.309 214.734 180.097 207.334 188.533L207.369 188.551Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M208.214 191.885C201.798 198.988 194.186 206.197 186.452 210.547C179.298 213.213 173.374 218.317 166.729 218.896C159.417 221.018 151.753 221.369 143.948 221.842C130.079 222.667 117.686 221.807 104.643 217.545C84.8679 211.073 68.9772 200.83 54.8092 187.061C48.5339 179.747 42.6452 173.451 38.9011 164.628C33.944 155.613 30.6569 146.808 28.8815 138.459C28.5475 130.023 23.3971 118.253 27.8444 112.904C39.0768 106.063 47.5671 121.235 64.9518 109.589C63.8971 100.065 64.0378 90.3304 65.7253 80.8064C70.0495 63.0739 78.4694 45.9904 94.5006 32.976C106.313 25.2586 118.548 20.7334 132.962 18.3655C139.079 18.3655 146.919 17.4885 149.503 20.6807C151.102 23.5397 151.577 26.2057 151.577 28.8191C151.577 35.7999 151.471 42.1667 156.323 55.9178C168.909 58.8293 183.956 63.337 195.276 71.8612C203.415 77.9826 210.956 84.3319 217.583 92.9087C225.616 103.292 228.2 114.132 231.188 126.357C232.471 138.406 230.749 151.316 225.546 164.804C221.538 175.152 215.596 183.729 208.231 191.885L208.214 191.885Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M208.899 195.304C202.658 202.022 195.381 208.81 187.981 213.054C181.213 215.72 175.553 220.509 169.207 221.263C162.229 223.403 154.934 223.841 147.463 224.42C133.401 225.525 120.902 225.086 107.561 220.982C87.3107 214.756 71.1564 204.741 56.4963 191.06C50.0275 183.693 43.9103 177.537 40.0783 168.574C34.8752 159.418 31.4474 150.543 29.5842 142.229C29.3205 133.705 23.5021 121.463 28.3713 116.464C40.799 110.483 49.8341 129.25 68.6251 117.762C67.0255 107.869 66.7267 97.7315 68.0626 87.8041C71.5958 69.177 79.8224 51.199 96.4162 37.4655C108.703 29.4148 121.36 24.8019 136.248 22.4165C142.154 22.4165 149.678 21.75 152.578 24.6966C154.459 27.31 155.32 29.8182 155.777 32.2036C157.008 38.7108 158.942 44.5339 164.145 57.8465C176.133 61.6876 190.688 66.8443 201.147 75.9999C208.688 82.5948 215.543 89.1722 221.643 97.977C229.043 108.676 230.608 119.445 233.227 131.443C234 143.405 231.856 156.033 226.442 168.995C222.258 179.027 216.229 187.376 208.916 195.252L208.899 195.304Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M209.303 198.865C203.256 205.179 196.313 211.564 189.229 215.72C182.865 218.386 177.434 222.859 171.387 223.789C164.742 225.928 157.817 226.911 150.697 227.174C136.371 227.682 123.856 228.542 110.18 224.595C89.4729 218.614 73.0373 208.827 57.885 195.252C51.2229 187.832 44.8772 181.816 40.9573 172.731C35.508 163.435 31.9397 154.49 29.971 146.194C29.7776 137.599 23.3088 124.883 28.5998 120.217C42.2229 115.078 51.8206 137.476 72.0002 126.146C69.8733 115.903 69.1174 105.361 70.1018 94.9954C72.844 75.4914 80.8948 56.6013 98.051 42.1487C110.813 33.7647 123.873 29.0817 139.237 26.6612C144.932 26.6612 152.139 26.2227 155.356 28.9063C157.535 31.2741 158.783 33.6069 159.68 35.7993C162.141 41.833 166.096 47.0948 171.686 60.004C183.076 64.7747 197.174 70.5979 206.737 80.385C213.662 87.471 219.85 94.2412 225.422 103.292C232.172 114.289 232.735 125.023 234.985 136.792C235.231 148.667 232.664 161.014 227.074 173.45C222.715 183.149 216.598 191.288 209.338 198.882L209.303 198.865Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M209.355 202.004C203.484 207.932 196.875 213.913 190.125 217.947C184.166 220.631 178.962 224.753 173.232 225.858C166.939 228.015 160.365 229.085 153.597 229.453C139.042 230.242 126.457 231.523 112.482 227.734C91.3003 221.981 74.6187 212.422 58.939 198.969C52.1011 191.48 45.4917 185.604 41.4839 176.396C35.8061 166.96 32.0796 157.944 30.0054 149.666C29.8823 140.984 22.7632 127.794 28.4585 123.479C43.2769 119.199 53.437 145.193 75.0054 134.038C72.3511 123.444 71.1206 112.482 71.771 101.695C73.7046 81.3138 81.5972 61.5117 99.2983 46.3399C112.517 37.6227 126.017 32.852 141.837 30.414C147.322 30.414 154.212 30.1859 157.763 32.6239C160.224 34.7462 161.894 36.9211 163.212 38.8856C166.957 44.4632 172.88 49.1638 178.839 61.652C189.615 67.3523 203.255 73.842 211.939 84.2429C218.267 91.82 223.787 98.8008 228.796 108.079C234.914 119.392 234.457 130.074 236.337 141.615C236.074 153.402 233.085 165.451 227.285 177.378C222.732 186.762 216.562 194.69 209.32 201.986L209.355 202.004Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M209.022 204.776C203.344 210.301 197.051 215.879 190.653 219.807C185.098 222.491 180.124 226.297 174.709 227.577C168.75 229.735 162.545 230.91 156.129 231.384C141.364 232.489 128.69 234.155 114.417 230.524C92.7778 225.017 75.8149 215.686 59.6431 202.338C52.6118 194.796 45.7739 189.061 41.6782 179.712C35.7544 170.118 31.8872 161.05 29.7075 152.806C29.6548 144.036 21.8853 130.391 28.0024 126.427C43.9985 123.006 54.7388 152.613 77.7134 141.598C74.5142 130.636 72.8442 119.271 73.1606 108.063C74.3032 86.7872 82.02 66.0905 100.284 50.1996C113.977 41.1667 127.881 36.3083 144.176 33.8352C149.45 33.8352 156.024 33.8352 159.891 36.0276C162.633 37.9044 164.725 39.9039 166.448 41.6578C171.475 46.7619 179.385 50.9187 185.696 62.9684C195.874 69.5984 209.057 76.7896 216.844 87.7694C222.575 95.8376 227.409 103.029 231.891 112.535C237.375 124.147 235.899 134.793 237.393 146.089C236.62 157.787 233.192 169.557 227.215 180.975C222.487 190.025 216.247 197.743 209.04 204.758L209.022 204.776Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M208.248 207.038C202.764 212.195 196.805 217.334 190.723 221.175C185.572 223.876 180.844 227.332 175.729 228.805C170.121 230.98 164.268 232.26 158.186 232.839C143.209 234.26 130.447 236.329 115.875 232.839C93.7617 227.56 76.5351 218.474 59.8711 205.214C52.6465 197.62 45.58 192.024 41.3789 182.553C35.2265 172.819 31.2011 163.68 28.916 155.454C28.916 146.614 20.5136 132.478 27.0351 128.864C44.2265 126.304 55.5117 159.506 79.8926 148.667C76.166 137.354 74.039 125.567 74.0039 113.956C74.3554 91.8032 81.8789 70.1769 100.705 53.5845C114.873 44.2183 129.199 39.2722 145.951 36.7815C151.014 36.7815 157.271 36.9745 161.473 38.9389C164.496 40.5701 167.01 42.3942 169.154 43.9552C175.5 48.5506 185.344 52.1988 192.023 63.8276C201.603 71.4047 214.312 79.2624 221.221 90.8561C226.336 99.4329 230.502 106.817 234.457 116.552C239.291 128.478 236.812 139.072 237.937 150.14C236.637 161.751 232.77 173.222 226.635 184.132C221.713 192.849 215.42 200.391 208.248 207.091L208.248 207.038Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M206.631 208.616C201.323 213.369 195.698 218.123 189.949 221.841C185.203 224.542 180.703 227.682 175.905 229.331C170.631 231.505 165.147 232.909 159.416 233.575C144.229 235.364 131.379 237.767 116.491 234.435C93.9202 229.401 76.43 220.543 59.2561 207.389C51.8382 199.741 44.5432 194.269 40.2542 184.675C33.8557 174.8 29.6722 165.592 27.2991 157.383C27.387 148.456 18.2991 133.863 25.26 130.583C43.6468 128.864 55.4944 165.679 81.2639 154.998C77.01 143.316 74.4085 131.126 74.0393 119.094C73.5999 96.0648 80.9475 73.5264 100.336 56.2148C114.961 46.5154 129.709 41.4991 146.936 38.9734C151.805 38.9734 157.729 39.3943 162.246 41.0957C165.569 42.4813 168.504 44.1125 171.053 45.4806C178.717 49.5498 190.494 52.7069 197.543 63.9147C206.526 72.4214 218.76 80.9982 224.807 93.1532C229.324 102.256 232.805 109.816 236.233 119.778C240.434 132.021 236.936 142.562 237.692 153.384C235.881 164.908 231.574 176.098 225.246 186.481C220.131 194.883 213.768 202.197 206.631 208.616Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M204.082 209.371C198.985 213.739 193.676 218.071 188.245 221.702C183.903 224.42 179.631 227.209 175.149 229.051C170.227 231.243 165.094 232.769 159.715 233.524C144.334 235.681 131.379 238.417 116.192 235.26C93.1644 230.472 75.3929 221.842 57.7269 208.793C50.1332 201.075 42.5746 195.761 38.2152 186.026C31.5707 176.011 27.2465 166.733 24.7679 158.542C24.9261 149.526 15.1879 134.46 22.5531 131.531C42.1351 130.671 54.5453 171.1 81.7035 160.576C76.9047 148.544 73.8636 135.951 73.1429 123.48C71.8949 99.5737 79.0843 76.1233 99.0179 58.0926C114.118 48.0599 129.27 42.9734 146.971 40.4126C151.629 40.4126 157.237 41.0441 162.071 42.4999C165.674 43.6575 169.032 45.0957 172.002 46.2533C180.967 49.7963 194.696 52.4623 202.114 63.2316C210.498 72.6679 222.258 81.9639 227.426 94.6801C231.328 104.309 234.141 112.044 237.024 122.235C240.575 134.793 236.075 145.282 236.461 155.876C234.141 167.312 229.377 178.186 222.891 188.078C217.582 196.129 211.184 203.285 204.065 209.389L204.082 209.371Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4981 158.786C21.7266 149.7 11.338 134.16 19.1251 131.564C39.8848 131.564 52.8751 175.589 81.4219 165.24C65.8477 128.986 65.8477 87.5401 96.9962 59.0558C112.57 48.6899 128.145 43.5157 146.303 40.9198C154.09 40.9198 164.479 43.5157 172.266 46.094C182.654 48.6899 198.229 51.2682 206.016 61.6341C213.803 72 224.191 82.3483 229.377 95.3101C231.979 105.676 234.563 113.446 237.164 123.794C239.766 136.739 234.563 147.104 234.563 157.47C229.377 178.185 216.387 196.321 200.813 209.265C187.84 219.631 174.85 229.979 159.293 232.575C143.719 235.171 130.746 237.749 115.172 235.171C91.8106 229.997 73.6524 222.227 55.4767 209.265C47.6895 201.495 39.9024 196.321 35.4376 186.463C28.5645 176.308 24.0821 166.959 21.4981 158.803L21.4981 158.786Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M149.01 230.331C133.981 232.506 121.412 235.418 106.524 232.471C83.9884 228.016 66.6036 219.878 49.0782 207.355C41.256 199.655 33.5743 194.323 29.0568 184.536C25.4181 179.133 22.4298 173.924 20.0216 168.96C17.965 164.716 16.3829 160.647 15.1876 156.823C15.2579 147.738 5.4845 132.583 12.8849 129.496C32.4493 128.567 44.9122 168.925 72.1407 158.367C66.9024 145.089 63.7911 131.145 63.5626 117.464C63.2638 94.8382 70.8927 73.0189 89.9474 56.0581C105.152 46.1132 120.41 41.0969 138.111 38.5361C146.022 38.4659 156.305 41.2021 164.32 43.4472C174.832 46.3938 190.354 48.7968 198.457 58.9346C206.824 69.3882 218.338 79.7015 223.014 93.3473C223.752 95.5222 224.227 97.6796 224.824 99.7317C226.916 107.414 229.324 115.026 230.59 122.551C232.084 134.688 226.582 142.616 225.246 152.315C225.053 152.842 224.877 153.35 224.666 153.876C223.594 156.648 222.856 159.857 221.836 162.874C220.535 166.733 219.006 170.592 217.231 174.45C211.412 186.956 202.641 198.111 192.375 206.776C179.262 217.861 165.34 227.981 148.992 230.349L149.01 230.331Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M140.537 224.771C126.07 226.578 113.959 229.349 99.6853 226.455C78.0994 222.088 61.365 214.213 44.49 202.11C36.6326 194.481 29.0564 188.991 24.4861 179.274C20.8826 173.907 17.8943 168.697 15.4861 163.699C13.447 159.454 11.8826 155.402 10.7048 151.544C10.6169 142.458 1.45874 127.69 8.48999 124.094C26.8591 122.253 38.8122 158.928 64.7048 148.176C60.0994 135.267 57.4802 121.761 57.7087 108.537C58.0603 86.7173 66.0759 65.8803 84.7614 49.7088C99.615 40.1848 114.539 35.3263 131.783 32.8006C139.816 32.6603 150.099 35.2386 158.238 37.4311C168.996 40.3426 184.342 42.9385 192.779 52.8659C201.744 63.4247 213.486 74.0011 218.513 88.0152C219.304 90.2252 219.832 92.4528 220.482 94.575C222.609 102.433 225.369 111.097 225.861 117.938C226.265 129.268 220.447 134.758 217.775 143.809C217.547 144.3 217.301 144.791 217.054 145.282C215.718 147.878 215.191 151.386 214.277 154.56C213.135 158.489 211.834 162.523 210.199 166.68C205.031 179.888 196.277 191.744 185.748 200.935C172.406 212.599 157.64 222.649 140.519 224.771L140.537 224.771Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M133.542 218.054C119.637 219.51 107.983 222.123 94.3424 219.299C73.7057 215.02 57.6217 207.39 41.4147 195.726C33.5397 188.167 26.0514 182.519 21.4107 172.872C17.8424 167.523 14.8541 162.331 12.4283 157.279C10.4068 153.052 8.87752 148.983 7.69979 145.107C7.45369 136.021 -1.08928 121.639 5.55525 117.552C22.7291 114.781 34.1725 147.79 58.7291 136.846C54.7564 124.287 52.6646 111.22 53.2974 98.4867C54.3346 77.4567 62.7017 57.6195 81.0182 42.2372C95.5025 33.1342 110.11 28.4336 126.897 25.9254C135.053 25.7149 145.319 28.1354 153.616 30.2928C164.62 33.1517 179.825 35.9581 188.544 45.675C198.124 56.3391 210.077 67.1785 215.473 81.561C216.317 83.8236 216.915 86.0862 217.618 88.2787C219.762 96.3118 222.891 106.029 222.61 112.185C221.924 122.709 215.79 125.761 211.782 134.162C211.501 134.618 211.219 135.074 210.903 135.53C209.303 137.951 209.004 141.757 208.178 145.089C207.212 149.071 206.104 153.298 204.645 157.753C200.11 171.644 191.391 184.203 180.616 193.92C167.028 206.18 151.454 216.142 133.542 218.019L133.542 218.054Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M127.722 212.423C114.398 213.58 103.183 215.983 90.1577 213.212C70.4702 209.02 55.0366 201.653 39.48 190.411C31.5698 182.921 24.187 177.116 19.4937 167.539C15.978 162.224 12.9722 157.033 10.5288 151.929C8.52491 147.719 7.01321 143.632 5.85305 139.739C5.46633 130.671 -2.49658 116.657 3.79638 112.061C19.7749 108.378 30.7085 137.704 53.9292 126.566C50.5718 114.376 49.0073 101.748 50.062 89.4878C51.8023 69.2646 60.5034 50.4095 78.4683 35.8341C92.5835 27.152 106.857 22.6093 123.205 20.1362C131.484 19.8731 141.75 22.1007 150.169 24.2229C161.402 27.0468 176.484 30.0461 185.501 39.5525C195.679 50.3043 207.861 61.4244 213.626 76.1927C214.523 78.4904 215.191 80.8232 215.929 83.0682C218.109 91.2943 221.607 102.046 220.552 107.536C218.759 117.253 212.326 117.867 206.964 125.619C206.63 126.04 206.296 126.461 205.945 126.9C204.082 129.162 203.994 133.249 203.273 136.739C202.447 140.773 201.568 145.193 200.285 149.964C196.365 164.522 187.681 177.817 176.677 188.043C162.861 200.882 146.46 210.792 127.757 212.405L127.722 212.423Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M122.783 210.108C110.039 210.985 99.2637 213.159 86.8535 210.441C68.1152 206.354 53.332 199.233 38.4258 188.411C30.4805 180.992 23.2031 175.046 18.4394 165.522C14.959 160.243 11.9531 155.051 9.50975 149.894C7.52343 145.702 6.0293 141.615 4.88672 137.687C4.3418 128.619 -3.00586 114.99 2.90039 109.904C17.6836 105.291 28.1074 130.951 49.9922 119.621C47.2676 107.799 46.2129 95.6091 47.6894 83.805C50.1152 64.3711 59.168 46.5158 76.7637 32.7297C90.5098 24.4686 104.467 20.0837 120.357 17.6281C128.76 17.2949 139.025 19.3645 147.568 21.4342C159.047 24.2055 173.988 27.3977 183.287 36.7112C194.098 47.5506 206.473 58.9514 212.59 74.088C213.539 76.4383 214.26 78.8062 215.068 81.139C217.283 89.5404 221.133 101.345 219.305 106.15C216.422 115.061 209.672 113.236 202.975 120.34C202.605 120.726 202.219 121.129 201.814 121.533C199.687 123.62 199.793 128.005 199.195 131.653C198.527 135.757 197.859 140.335 196.752 145.422C193.43 160.663 184.816 174.678 173.549 185.429C159.486 198.847 142.295 208.722 122.783 210.072L122.783 210.108Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M118.424 213.37C106.26 213.984 95.9242 215.914 84.1293 213.247C66.3578 209.248 52.2074 202.39 37.9691 191.99C29.9887 184.658 22.7992 178.537 18.0004 169.083C14.555 163.838 11.5492 158.647 9.08826 153.437C7.11951 149.263 5.66052 145.159 4.51794 141.212C3.81482 132.144 -2.91761 118.92 2.6195 113.307C16.2074 107.764 26.1039 129.759 46.6703 118.236C44.5785 106.782 44.0336 95.0131 45.932 83.7001C49.0433 65.0555 58.4476 48.2 75.6742 35.2031C89.0687 27.3629 102.692 23.1534 118.125 20.7154C126.651 20.312 136.899 22.2238 145.6 24.2584C157.307 26.9946 172.143 30.3622 181.723 39.5003C193.166 50.4099 205.717 62.1088 212.221 77.6138C213.223 80.0168 214.014 82.4372 214.858 84.8226C217.108 93.3995 221.309 106.238 218.707 110.395C214.735 118.481 207.668 114.236 199.635 120.691C199.213 121.042 198.791 121.41 198.334 121.779C195.944 123.708 196.26 128.356 195.768 132.197C195.241 136.354 194.784 141.125 193.852 146.509C191.127 162.418 182.584 177.151 171.071 188.429C156.762 202.443 138.78 212.3 118.459 213.335L118.424 213.37Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M114.768 219.543C103.307 221.139 93.305 221.578 82.1077 218.982C65.2854 215.071 51.7854 208.476 38.1975 198.513C30.1819 191.252 23.0979 184.973 18.2288 175.589C14.8186 170.38 11.8128 165.188 9.35181 159.926C7.40064 155.769 5.95925 151.648 4.83425 147.684C3.97292 138.633 -2.14429 125.777 3.0061 119.673C15.3987 113.218 24.7854 131.53 44.0159 119.813C42.5569 108.728 42.5393 97.3976 44.842 86.5581C48.6389 68.7028 58.3948 52.847 75.2698 40.657C88.2952 33.2377 101.602 29.1861 116.578 26.7832C125.209 26.3096 135.475 28.0636 144.299 30.0455C156.252 32.7291 170.965 36.2721 180.809 45.2348C192.885 56.2146 205.629 68.2292 212.502 84.1025C213.557 86.5405 214.418 89.0312 215.315 91.4692C217.6 100.221 222.17 114.113 218.778 117.586C213.698 124.865 206.332 118.182 196.963 123.988C196.489 124.303 196.014 124.654 195.504 124.987C192.832 126.741 193.36 131.687 192.99 135.686C192.604 139.896 192.358 144.842 191.619 150.56C189.457 167.135 181.02 182.605 169.26 194.409C154.723 209.002 135.756 216.649 114.785 219.578L114.768 219.543Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M112.306 223.437C101.408 224.788 91.8631 224.963 81.2987 222.42C65.4256 218.614 52.576 212.265 39.6385 202.723C31.5877 195.532 24.6092 189.095 19.6874 179.799C16.3299 174.625 13.3065 169.433 10.828 164.119C8.87679 159.962 7.47056 155.857 6.34556 151.858C5.34361 142.808 -0.175934 130.337 4.60531 123.742C15.8026 116.358 24.6795 131.004 42.5741 119.094C41.7303 108.378 42.2401 97.4681 44.9647 87.1022C49.4471 70.0362 59.5545 55.1977 76.0604 43.7794C88.7166 36.7811 101.707 32.8873 116.226 30.5019C124.98 29.9582 135.228 31.5543 144.193 33.5012C156.392 36.1497 171 39.8681 181.107 48.6379C193.834 59.6878 206.754 72.0006 213.978 88.2423C215.086 90.7329 216 93.2586 216.949 95.7843C219.269 104.712 224.191 119.656 220.025 122.462C213.855 128.934 206.174 119.814 195.451 124.97C194.924 125.251 194.414 125.567 193.851 125.882C190.916 127.478 191.637 132.705 191.39 136.862C191.144 141.142 191.127 146.263 190.564 152.279C188.982 169.521 180.615 185.727 168.627 198.04C153.844 213.212 134.103 220.754 112.306 223.472L112.306 223.437Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M110.901 225.175C100.565 226.262 91.4767 226.192 81.5275 223.701C66.6037 219.983 54.4045 213.897 42.1174 204.776C34.049 197.655 27.1584 191.06 22.1662 181.834C18.8439 176.678 15.8205 171.503 13.3244 166.136C11.3908 161.997 10.0021 157.875 8.89469 153.859C7.73453 144.808 2.83024 132.723 7.22477 125.62C17.2267 117.324 25.5939 128.286 42.1525 116.184C41.9416 105.835 42.9611 95.3465 46.1076 85.4717C51.2931 69.1774 61.7345 55.3562 77.8712 44.7272C90.1759 38.1499 102.832 34.414 116.877 32.0637C125.754 31.4498 136.002 32.888 145.108 34.7823C157.535 37.3782 172.055 41.2544 182.408 49.8663C195.785 60.9689 208.863 73.6149 216.475 90.2074C217.635 92.7331 218.619 95.3289 219.621 97.9073C221.977 107.01 227.25 123.006 222.311 125.129C215.033 130.794 207.053 119.235 194.994 123.743C194.432 123.989 193.852 124.287 193.254 124.55C190.037 125.988 190.969 131.478 190.846 135.81C190.74 140.143 190.916 145.44 190.565 151.771C189.545 169.679 181.283 186.605 169.031 199.444C154.002 215.212 133.524 222.737 110.865 225.139L110.901 225.175Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M110.408 225.05C100.635 225.875 92.004 225.559 82.67 223.139C68.713 219.508 57.1466 213.685 45.5275 204.985C37.4239 197.934 30.6388 191.181 25.5939 182.026C22.3068 176.904 19.3009 171.73 16.7872 166.328C14.8712 162.206 13.5177 158.067 12.4103 154.033C11.0919 144.982 6.80283 133.283 10.8282 125.689C19.6349 116.463 27.4923 123.759 42.7149 111.464C43.1368 101.484 44.6837 91.4163 48.2345 82.0326C54.1056 66.5101 64.8985 53.7062 80.6837 43.8665C92.6192 37.7101 104.959 34.132 118.547 31.7992C127.547 31.1327 137.777 32.378 147.024 34.2548C159.697 36.8155 174.111 40.8497 184.729 49.2862C198.756 60.4414 212.01 73.4207 219.99 90.3814C221.203 92.9598 222.258 95.5907 223.295 98.2392C225.686 107.535 231.328 124.566 225.598 126.004C217.231 130.863 208.934 116.866 195.539 120.725C194.924 120.935 194.309 121.199 193.641 121.444C190.143 122.724 191.268 128.495 191.285 132.985C191.303 137.388 191.707 142.86 191.549 149.49C191.074 168.064 182.936 185.727 170.438 199.092C155.162 215.439 133.945 223.086 110.426 225.05L110.408 225.05Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M110.655 223.332C101.444 223.858 93.27 223.332 84.5512 220.964C71.5434 217.439 60.6274 211.861 49.6587 203.583C41.52 196.602 34.8403 189.691 29.7251 180.606C26.4731 175.519 23.4672 170.345 20.936 164.89C19.0376 160.786 17.7016 156.647 16.6118 152.577C15.1352 143.545 11.4438 132.214 15.1001 124.111C22.7114 113.973 30.0415 117.586 43.9458 105.098C45.0004 95.4685 47.0747 85.8393 51.0297 76.9292C57.604 62.1784 68.7485 50.3918 84.1469 41.3589C95.7133 35.6234 107.737 32.2032 120.868 29.9055C129.991 29.1689 140.221 30.2563 149.608 32.0804C162.51 34.5886 176.836 38.7806 187.717 47.0592C202.413 58.2495 215.825 71.5796 224.174 88.8911C225.44 91.5221 226.547 94.2056 227.655 96.9067C230.081 106.378 236.075 124.444 229.571 125.233C220.114 129.285 211.5 112.85 196.77 116.06C196.102 116.235 195.434 116.481 194.731 116.674C190.969 117.779 192.27 123.848 192.428 128.496C192.586 132.968 193.202 138.598 193.219 145.562C193.29 164.802 185.256 183.184 172.53 197.075C157.008 214.001 135.071 221.911 110.672 223.297L110.655 223.332Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M111.499 220.316C102.868 219.807 95.1509 219.807 87.0474 217.492C74.9888 214.054 64.7232 208.74 54.4048 200.882C46.231 193.972 39.6568 186.921 34.4888 177.888C31.2896 172.836 28.2662 167.662 25.7349 162.155C23.854 158.068 22.5357 153.911 21.4634 149.824C19.8462 140.792 16.7525 129.847 20.0396 121.253C26.4556 110.185 33.2759 110.115 45.8443 97.4513C47.5142 88.1904 50.1157 78.9646 54.4927 70.5631C61.77 56.584 73.2486 45.8147 88.2778 37.5711C99.4927 32.2566 111.182 28.9943 123.856 26.7141C133.102 25.9073 143.315 26.8369 152.842 28.6084C165.991 31.0639 180.211 35.4138 191.338 43.5346C206.702 54.7599 220.254 68.4233 228.973 86.1032C230.292 88.7692 231.469 91.5229 232.629 94.2941C235.09 103.941 241.436 123.059 234.159 123.164C223.594 126.409 214.682 107.537 198.598 110.097C197.877 110.238 197.174 110.448 196.418 110.623C192.376 111.571 193.87 117.92 194.168 122.743C194.45 127.286 195.276 133.092 195.504 140.353C196.12 160.278 188.21 179.379 175.219 193.779C159.452 211.283 136.741 221.754 111.516 220.281L111.499 220.316Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M112.781 216.263C104.731 215.614 97.4532 215.246 89.9649 212.984C78.8555 209.651 69.2403 204.582 59.5723 197.163C51.3633 190.322 44.8946 183.114 39.6563 174.151C36.4922 169.135 33.4688 163.96 30.92 158.4C29.0567 154.314 27.7735 150.157 26.7012 146.052C24.9258 137.02 22.4473 126.461 25.3477 117.358C30.5684 105.361 36.8789 101.625 48.1114 88.7681C50.4141 79.8756 53.543 71.0707 58.3242 63.1604C66.3047 49.9355 78.1172 40.201 92.7774 32.7467C103.623 27.8532 114.996 24.7487 127.213 22.5036C136.582 21.6266 146.795 22.3983 156.445 24.1348C169.822 26.5552 183.973 31.0454 195.346 39.0083C211.377 50.2512 225.088 64.2829 234.176 82.3311C235.547 85.0497 236.795 87.8385 237.99 90.68C240.486 100.502 247.201 120.673 239.15 120.111C227.496 122.532 218.268 101.239 200.848 103.151C200.092 103.256 199.318 103.431 198.51 103.589C194.168 104.378 195.873 111.008 196.313 115.99C196.717 120.602 197.754 126.566 198.193 134.143C199.318 154.735 191.531 174.537 178.33 189.498C162.281 207.564 138.797 218.333 112.781 216.263Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M114.364 211.562C106.893 210.79 100.055 210.036 93.1819 207.844C83.0393 204.599 74.0569 199.776 65.0568 192.795C56.8303 186.025 50.4494 178.658 45.1584 169.765C42.0295 164.767 38.9885 159.61 36.4397 153.997C34.594 149.928 33.3283 145.771 32.2737 141.632C30.3401 132.599 28.4768 122.444 31.008 112.832C35.0334 99.9228 40.8342 92.5035 50.7307 79.4541C53.6662 70.9298 57.34 62.5284 62.508 55.1091C71.2092 42.6385 83.3381 33.9388 97.6467 27.2913C108.123 22.8187 119.18 19.8721 130.94 17.6621C140.432 16.7149 150.627 17.3113 160.436 19.0126C174.059 21.3805 188.104 26.0109 199.723 33.8336C216.422 45.094 230.274 59.494 239.748 77.893C241.172 80.6643 242.473 83.5057 243.738 86.3997C246.27 96.3973 253.336 117.62 244.512 116.375C231.768 117.989 222.223 94.2575 203.467 95.5203C202.658 95.5905 201.832 95.7483 200.988 95.8536C196.365 96.4674 198.264 103.413 198.844 108.535C199.371 113.218 200.637 119.357 201.27 127.249C202.975 148.525 195.258 169.046 181.828 184.516C165.551 203.161 141.24 214.316 114.416 211.545L114.364 211.562Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M116.068 206.53C109.16 205.635 102.797 204.495 96.5212 202.356C87.3279 199.216 78.9958 194.638 70.6462 188.078C62.3845 181.378 56.1091 173.854 50.7478 165.049C47.654 160.085 44.613 154.929 42.0642 149.263C40.236 145.212 38.988 141.037 37.9333 136.88C35.8415 127.865 34.5935 118.078 36.738 107.958C39.5681 94.1188 44.8591 83.0338 53.4196 69.7914C56.9704 61.6355 61.1892 53.655 66.7614 46.7268C76.1833 35.0279 88.6286 27.3456 102.568 21.4873C112.693 17.4357 123.398 14.6469 134.718 12.4545C144.334 11.4372 154.529 11.8757 164.461 13.5419C178.312 15.8571 192.287 20.6279 204.152 28.3278C221.537 39.5882 235.529 54.3916 245.355 73.1239C246.832 75.9302 248.203 78.8242 249.504 81.806C252.07 91.9789 259.488 114.254 249.89 112.325C236.039 113.132 226.195 86.9626 206.086 87.5765C205.224 87.6116 204.363 87.7344 203.467 87.8221C198.562 88.2781 200.654 95.5044 201.375 100.784C202.025 105.537 203.502 111.851 204.345 120.042C206.578 142.002 199.002 163.242 185.308 179.238C168.785 198.479 143.631 210.091 116.051 206.53L116.068 206.53Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
      <path
        d="M207.474 112.832C213.785 162.645 169.664 209.265 117.756 201.495C102.181 198.899 89.2088 193.725 76.2361 183.359C63.2635 172.993 55.4764 160.049 47.6892 144.509C42.5037 131.565 39.9021 118.603 42.5037 103.08C45.1052 79.7703 55.4763 56.4602 71.0506 38.3418C89.2263 20.2058 112.57 12.4358 138.533 7.26161C161.894 4.66575 187.84 9.85747 208.617 22.8017C229.377 35.7459 244.951 53.8818 255.34 77.1919C257.941 87.5578 265.728 110.868 255.34 108.272C239.765 108.272 229.377 77.1919 206.033 79.7878C200.847 79.7878 203.431 87.5578 203.994 93.0302C205.049 97.801 206.508 104.326 207.492 112.832L207.474 112.832Z"
        stroke="#594CE9"
        strokeWidth="0.0954054"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3224_13188">
        <rect
          width="261"
          height="237.118"
          fill="white"
          transform="translate(261 237.118) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WeirdCircleIcon;
