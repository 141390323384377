import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useEffect, useRef } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  fetchSuppliedInstances,
  getFormattedSuppliedInstances,
  getSuppliedInstancesInitialLoading,
} from '../../slices/instances';
import useUser from '../../hooks/useUser';
import { AppDispatch } from '../../store';
import useInterval from '../../hooks/useInterval';
import {
  Instance,
  InstanceBillingPeriod,
  InstanceStatus,
  ReservedInstance,
} from '../../utils/types';
import { displayModelName, displayPricePeriod } from '../../utils/instances';
import NvidiaLogo from '../common/icons/NvidiaLogo';
import AMDLogo from '../common/icons/AMDLogo';
import CopyButton from '../common/CopyButton';
import twClassnames from '../../utils/classnames';
import { formatCurrency } from '../../utils/value';
import LoadingSpinner from '../common/LoadingSpinner';
import PencilIcon from '../common/icons/PencilIcon';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { isMobile } from 'react-device-detect';
import { mobileStyles } from '../../utils/modals';
import stackedGPUs from '../../assets/stacked-gpus.png';
import SearchIcon from '../common/icons/SearchIcon';
import HyperLink from '../common/HyperLink';

const columnHelper = createColumnHelper<Instance>();

const GPUBadge = ({ children }: { children: ReactNode }) => (
  <div className="text-xs font-semibold p-1 text-theme-neutral-700 bg-theme-neutral-100 rounded">
    {children}
  </div>
);

const getSuppliedStatus = (
  status: InstanceStatus,
  instances: ReservedInstance[]
) => {
  if (status !== InstanceStatus.node_ready) {
    return status;
  }
  if (instances.length > 0) {
    return 'rented';
  }
  return 'online';
};

const SupplyList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const suppliedInstances = useSelector(getFormattedSuppliedInstances);
  const suppliedInitialLoading = useSelector(
    getSuppliedInstancesInitialLoading
  );
  const { authToken } = useUser();
  const authTokenRef = useRef('');

  const columns = [
    columnHelper.accessor((row) => row.gpu?.model, {
      id: 'Type',
      cell: (info) => {
        const { gpuCount, storageCapacity } = info.row.original;
        const gpuModel = info.getValue();
        return (
          <div className="flex gap-2 font-semibold text-theme-neutral-700 items-center">
            {gpuModel.toLowerCase().includes('amd') ? (
              <AMDLogo color="#000" />
            ) : (
              <NvidiaLogo color="#7FB131" />
            )}
            <div>{displayModelName(gpuModel)}</div>
            <GPUBadge>{gpuCount}X</GPUBadge>
            <GPUBadge>{storageCapacity}</GPUBadge>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.clusterName, {
      id: 'Cluster Name',
      cell: (info) => (
        <div className="flex items-center gap-2">
          <div className="max-w-32 truncate">{info.getValue()}</div>
          <CopyButton
            id={`${info.getValue()}-${info.row.original.id}-cluster-name`}
            size={20}
            copyText={info.getValue()}
            className="text-theme-neutral-600 hover:text-theme-primary-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'Node Name',
      cell: (info) => (
        <div className="flex items-center gap-2">
          <div className="max-w-32 truncate">{info.getValue()}</div>
          <CopyButton
            id={`${info.row.original.clusterName}-${info.row.original.id}-node-name`}
            size={20}
            copyText={info.getValue()}
            className="text-theme-neutral-600 hover:text-theme-primary-600"
          />
        </div>
      ),
    }),
    columnHelper.accessor(
      (row) => getSuppliedStatus(row.status, row.instances),
      {
        id: 'Status',
        cell: (info) => {
          const status = info.getValue();

          return (
            <div className="flex items-center gap-2">
              <div
                className={twClassnames(
                  'h-2 w-2 rounded-full bg-theme-neutral-600',
                  {
                    'bg-theme-primary-600': status === 'rented',
                    'bg-theme-success-600': status === 'online',
                    'bg-theme-danger-600': status === 'offline',
                  }
                )}
              />
              {status}
            </div>
          );
        },
      }
    ),
    columnHelper.accessor(
      (row) => [row.pricing.price.amount, row.pricing.price.period],
      {
        header: 'Price',
        cell: (info) => {
          const [price, pricePeriod] = info.getValue() as [
            number,
            InstanceBillingPeriod
          ];
          return (
            <div className="font-medium flex items-center gap-1">
              <span>
                <span className="text-theme-primary-600">
                  {formatCurrency(price / 100)}
                </span>
                &nbsp;/ GPU / {displayPricePeriod(pricePeriod)}
              </span>
              <PencilIcon
                className="text-theme-neutral-600 hover:text-theme-primary-600 cursor-pointer"
                onClick={() =>
                  dispatch(
                    openModal({
                      name: ModalName.SetNodePrice,
                      props: {
                        defaultPrice: price,
                        clusterName: info.row.original.clusterName,
                        nodeName: info.row.original.id,
                        gpuModel: info.row.original.gpu?.model,
                      },
                      styles: isMobile
                        ? mobileStyles
                        : { content: { maxWidth: 500 } },
                    })
                  )
                }
              />
            </div>
          );
        },
      }
    ),
  ];

  const table = useReactTable({
    data: suppliedInstances,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchSuppliedInstances());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchSuppliedInstances());
    }
  }, 5000);

  if (!suppliedInitialLoading && suppliedInstances.length === 0) {
    return (
      <div className="flex flex-col my-10 gap-10 items-center">
        <div className="text-theme-neutral-700">
          You don't have any supplied instances yet.
        </div>
        <HyperLink
          to="/supply/instructions"
          variant="outline"
          className="flex items-center py-2"
        >
          <SearchIcon size={20} className="mr-3" />
          Supply GPUs
        </HyperLink>
        <img src={stackedGPUs} style={{ width: 696 }} />
      </div>
    );
  }

  return (
    <table className="mt-4 mb-8 border-collapse text-sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="border-b border-theme-neutral-300"
          >
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="text-left py-5 font-semibold text-theme-neutral-700"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {suppliedInitialLoading ? (
          <tr className="h-48">
            <td colSpan={columns.length}>
              <div className="flex items-center justify-center">
                <LoadingSpinner className="fill-theme-primary-600 text-theme-primary-300" />
              </div>
            </td>
          </tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="border-b border-theme-neutral-300">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-5 text-theme-neutral-700">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default SupplyList;
