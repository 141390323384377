import linuxLogo from '../../assets/linux-logo.png';
import appleLogo from '../../assets/apple-logo.png';
import windowsLogo from '../../assets/windows-logo.png';
import nvidiaLogo from '../../assets/nvidia-logo.png';
import amdLogo from '../../assets/amd-logo.png';
import RadioButton from './RadioButton';
import { gpuTypes } from '../../utils/instances';
import GPUBadge from './GPUBadge';
import NvidiaLogo from '../common/icons/NvidiaLogo';
import AMDLogo from '../common/icons/AMDLogo';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSupply,
  setGpuBrand,
  setGpuType,
  setOperatingSystem,
} from '../../slices/supply';

const operatingSystems = [
  { id: 'linux', name: 'Linux', image: linuxLogo },
  { id: 'apple', name: 'Mac OS', image: appleLogo, disabled: true },
  { id: 'windows', name: 'Windows', image: windowsLogo, disabled: true },
];

const gpuBrands = [
  { id: 'nvidia', name: 'Nvidia', image: nvidiaLogo },
  { id: 'amd', name: 'AMD', image: amdLogo },
];

const CheckEligibility = () => {
  const dispatch = useDispatch();
  const { operatingSystem, gpuBrand, gpuType } = useSelector(getSupply);
  const gpusOfSelectedBrand = gpuTypes.filter((gpu) =>
    gpu.value.toLowerCase().includes(gpuBrand.toLowerCase())
  );
  return (
    <div className="flex flex-col gap-5">
      <div>
        <div className="text-black mb-4 text-lg font-semibold">
          Confirm Operating System
        </div>
        <div className="text-sm mb-5">
          Right now we can only support Linux. Please circle back in the future
          if you are using other OS.
        </div>
        <div className="flex gap-3 flex-wrap">
          {operatingSystems.map((os) => (
            <RadioButton
              key={os.id}
              text={os.name}
              image={os.image}
              selected={os.id === operatingSystem}
              onClick={() => dispatch(setOperatingSystem(os.id))}
              otherSelected={!!operatingSystem && os.id !== operatingSystem}
              disabled={os.disabled}
            />
          ))}
        </div>
      </div>
      {operatingSystem && (
        <div>
          <div className="text-black mb-4 text-lg font-semibold">
            Choose GPU Brand
          </div>
          <div className="text-sm mb-5">
            We'll soon allow AMD GPU supplies. Stay tuned!
          </div>
          <div className="flex gap-3 flex-wrap">
            {gpuBrands.map((brand) => (
              <RadioButton
                key={brand.id}
                text={brand.name}
                image={brand.image}
                selected={brand.id === gpuBrand}
                onClick={() => dispatch(setGpuBrand(brand.id))}
                otherSelected={!!gpuBrand && brand.id !== gpuBrand}
                // disabled={os.disabled}
              />
            ))}
          </div>
        </div>
      )}
      {gpuBrand && (
        <div className="rounded-lg border border-theme-neutral-300 p-6 flex flex-col gap-6">
          <div className="text-black font-medium text-sm">Support GPUs</div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
            {gpusOfSelectedBrand.map((gpu) => (
              <GPUBadge
                key={gpu.value}
                logo={gpuBrand === 'nvidia' ? <NvidiaLogo /> : <AMDLogo />}
                text={gpu.label}
                onClick={() => dispatch(setGpuType(gpu.value))}
                selected={gpu.value === gpuType}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckEligibility;
