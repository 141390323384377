import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "./useUser";
import { updateUserInfo } from '../slices/auth';
import { ModalName } from '../components/modals';
import { isMobile } from 'react-device-detect';
import { mobileStyles } from '../utils/modals';
import { closeModal, openModal } from '../slices/modals';

const useOnboardUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userInfo } = useUser();
  useEffect(() => {
    if (user?.providerId === 'firebase' && !user?.emailVerified) {
      if (location.pathname !== '/verify-email') {
        navigate('/verify-email');
      }
    }
    if (userInfo?.email_verified && !userInfo?.onboarded_at) {
      dispatch(
        openModal({
          name: ModalName.OnboardingModal,
          styles: isMobile ? mobileStyles : { content: { maxWidth: '677px' } },
          onClose: () => {
            dispatch(closeModal());
            dispatch(
              updateUserInfo({
                userId: user?.uid || '',
                update: { onboarded_for: 'no-reason' },
              })
            );
          },
        })
      );
    }
  }, [user, location, navigate, dispatch, userInfo]);
}

export default useOnboardUser;