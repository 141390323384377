import { useEffect, useRef } from 'react';
import Button from '../common/Button';
import SendIcon from '../common/icons/Send';
import TextArea from '../common/TextArea';
import AuthButton from '../AuthButton';
import useUser, { UserStatus } from '../../hooks/useUser';
import { FiX } from 'react-icons/fi';
import UploadDropdown from './UploadDropdown';

const llmDefaultText = 'Hello, how is your ';
const vlmDefaultText = 'In this image, I see ';

const CompletionInputBar = ({
  value,
  onChange,
  onSubmit,
  disabled = false,
  imageURL,
  onImageChange,
  allowImageUpload,
  defaultImage,
}: {
  value: string;
  onChange: (text: string) => void;
  onSubmit: (defaultText?: string) => void;
  disabled?: boolean;
  imageURL?: string;
  onImageChange?: any;
  allowImageUpload: boolean;
  defaultImage: string;
}) => {
  const { status } = useUser();
  const isAuthenticated = status !== UserStatus.LoggedOut;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const newLines = value.split('\n').length;
  const newHeight = 16 + newLines * 20;
  const defaultText = allowImageUpload ? vlmDefaultText : llmDefaultText;
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (value) {
      onSubmit();
    } else {
      onSubmit(defaultText);
    }
  };
  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result;
      const image = String(dataURL).replace(
        /data:image\/(jpg|png|jpeg);base64,/,
        ''
      );
      onImageChange?.(image);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form className="flex items-center px-10" onSubmit={handleSubmit}>
      <TextArea
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter text here and AI will help complete the sentence"
        onKeyDown={onEnterPress}
        className="resize-none bg-transparent"
        containerClassName="w-full bg-transparent border-0"
        innerContainerClassName="px-0"
        style={{ height: newHeight }}
        disabled={disabled}
        additionalContainer={
          allowImageUpload && (
            <div className="flex items-center gap-2">
              <UploadDropdown onDrop={onDrop} position="bottom" />
              {(imageURL || defaultImage) && (
                <div className="relative w-12 h-12 group transition">
                  <img
                    src={`data:image/png;base64,${imageURL || defaultImage}`}
                    className="w-12 h-12 rounded"
                  />
                  <div
                    className="transition hidden group-hover:flex cursor-pointer absolute top-0 right-0 -mt-1.5 -mr-1.5 p-1 text-black bg-white border border-theme-neutral-400 rounded-full"
                    role="button"
                    onClick={() => onImageChange?.('')}
                  >
                    <FiX size={12} />
                  </div>
                </div>
              )}
            </div>
          )
        }
        endIcon={
          value.length > 0 &&
          (!isAuthenticated ? (
            <AuthButton className="mr-2 text-sm py-1.5" />
          ) : (
            <Button
              variant="link"
              type="submit"
              className="hover:bg-theme-primary-600 hover:text-white bg-theme-neutral-200 disabled:bg-theme-neutral-200 disabled:text-theme-neutral-500 p-2 rounded-full duration-400"
              disabled={disabled}
            >
              <SendIcon size={20} />
            </Button>
          ))
        }
      />
    </form>
  );
};

export default CompletionInputBar;
