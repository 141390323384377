import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ModalName } from '../components/modals';

interface ModalsSlice {
  name: ModalName | undefined;
  props: any;
  styles: any;
  onRequestClose?: () => void;
  onClose?: () => void;
}

const initialState: ModalsSlice = {
  name: undefined,
  props: {},
  styles: {},
  onRequestClose: undefined,
  onClose: undefined,
};

const modalsSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.name = action.payload.name;
      state.props = action.payload.props || {};
      state.styles = action.payload.styles || {};
      state.onRequestClose = action.payload.onRequestClose;
      state.onClose = action.payload.onClose;
    },
    closeModal: () => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export const getModals = (state: RootState) => state.modals;

export default modalsSlice.reducer;
