import { useNavigate } from 'react-router-dom';

import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import { ReactNode, useState } from 'react';
import { InstanceStatus } from '../../utils/types';
import Card from '../common/Card';
import Input from '../common/Input';
import { formatCurrency } from '../../utils/value';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { SiNvidia, SiPytorch, SiTensorflow } from 'react-icons/si';
import {
  computePrice,
  displayModelName,
  dockerImages,
} from '../../utils/instances';
import LockIcon from '../common/icons/LockIcon';
import NumberStepper from '../common/NumberStepper';
import { AppDispatch, RootState } from '../../store';
import {
  getFormattedMarketplaceInstance,
  rentInstance,
} from '../../slices/instances';
import { usePostHog } from 'posthog-js/react';
import HyperLink from '../common/HyperLink';
import Dropdown, {
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  DropdownOverlay,
} from '../common/Dropdown';
import twClassnames from '../../utils/classnames';
import useUser from '../../hooks/useUser';
import RentInstanceButton from '../Compute/RentInstanceButton';
import { UserRole } from '../../slices/auth';

const dockerIconMap: { [key: string]: ReactNode } = {
  pytorch: <SiPytorch size={16} color="#DC583A" />,
  tensorflow: <SiTensorflow size={16} color="#EE8D34" />,
  nvidia: <SiNvidia size={16} color="#7FB131" />,
};

const ConfirmRentModal = ({
  onClose,
  instanceId,
}: {
  onClose: () => void;
  instanceId: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const posthog = usePostHog();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const [errorMessage, setErrorMessage] = useState('');
  const instance = useSelector((state: RootState) =>
    getFormattedMarketplaceInstance(state, instanceId)
  );

  const [gpuCount, setGPUCount] = useState(instance?.gpuCount || 1);
  const selectedImage = dockerImages[selectedImageIndex] || dockerImages[0];

  if (!instance) {
    onClose();
    return null;
  }

  const { gpu, gpuCount: defaultGPUCount } = instance;
  const gpuModel = displayModelName(gpu?.model);
  const currentPrice = computePrice(gpu.model, gpuCount, instance.pricing);
  const isLoading = instance.status === InstanceStatus.starting;

  const onSuccess = () => {
    onClose();
    posthog?.capture('Rent Instance Confirmed', {
      instanceId,
      clusterName: instance?.clusterName,
      dockerImage: selectedImage.title,
      gpuModel,
      price: currentPrice,
      gpuCount,
    });
    navigate('/compute/instances');
    toast.success('Created instance successfully!');
  };

  const onError = (error: any) => {
    setErrorMessage(error.message || 'Something went wrong');
    posthog?.capture('Rent Instance Failed', {
      instanceId,
      clusterName: instance?.clusterName,
      dockerImage: selectedImage.title,
      gpuModel,
      price: currentPrice,
      gpuCount,
    });
    toast.error('Error creating instance');
  };

  const handleConfirm = async () => {
    setErrorMessage('');
    const { packageId } = selectedImage;

    const newInstance = {
      nodeName: instance?.nodeName || '',
      clusterName: instance?.clusterName || '',
      ...(packageId && {
        image: {
          name: `ghcr.io/hyperboliclabs/hyper-dos/${packageId}`,
          tag: 'dev',
          port: 80,
        },
      }),
      gpuCount,
    };
    try {
      await dispatch(rentInstance(newInstance)).unwrap();
      onSuccess();
    } catch (error: any) {
      onError(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const addDisabled = gpuCount >= defaultGPUCount;
  const subtractDisabled = gpuCount <= 1;

  const addGpu = () => {
    if (gpuCount < defaultGPUCount) {
      const nextCount = gpuCount + 1;
      setGPUCount(nextCount);
      posthog?.capture('GPU Count Updated', {
        previousCount: gpuCount,
        nextCount,
      });
    }
  };

  const subtractGpu = () => {
    const nextCount = gpuCount - 1;
    setGPUCount(nextCount);
    posthog?.capture('GPU Count Updated', {
      previousCount: gpuCount,
      nextCount,
    });
  };

  const buttonsDisabled = !!isLoading;
  const selectedIcon = dockerIconMap[selectedImage.icon];

  return (
    <div className="p-4">
      <ModalClose onClose={handleClose} />
      <div className="text-black text-center text-lg font-semibold mb-8">
        Rent Confirmation
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">GPU Type:</div>
          <div>
            <Input
              type="text"
              value={gpuModel}
              disabled
              endIcon={<LockIcon size={20} />}
              endIconClassName="mt-2 right-4 text-theme-neutral-400"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 items-start">
          <div className="text-black font-semibold text-sm">GPU Count*:</div>
          <NumberStepper
            addDisabled={addDisabled}
            subtractDisabled={subtractDisabled}
            add={addGpu}
            subtract={subtractGpu}
            value={gpuCount}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Template*:</div>
          <div>
            <Dropdown>
              <DropdownButton textClassName="flex items-center gap-2">
                {selectedIcon}
                {selectedImage?.title}
                {selectedImageIndex === 0 && (
                  <div className="text-theme-primary-600">(default)</div>
                )}
              </DropdownButton>
              <DropdownOverlay>
                <DropdownMenu>
                  {dockerImages.map((image, i) => {
                    const icon = dockerIconMap[image.icon];
                    return (
                      <DropdownMenuItem
                        key={image?.title}
                        active={selectedImage?.title === image?.title}
                        onClick={() => setSelectedImageIndex(i)}
                        childClassName="flex items-center gap-2"
                      >
                        {icon}
                        {image?.title}
                        {i === 0 && (
                          <div className="text-theme-primary-600">
                            (default)
                          </div>
                        )}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenu>
              </DropdownOverlay>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Pricing*:</div>
          <div className="flex gap-4 overflow-auto hide-scrollbar">
            {/* {Object.keys(pricingTypes).map((p) => {
            const config = pricingTypes[p];
            return (
              <Card className="min-w-80 cursor-not-allowed select-none">
                <div className="font-2xl font-semibold">{config.label}</div>
                <div className="text-2xl text-theme-primary-500 font-bold my-3">
                  {formatCurrency(config.amount)} / hr
                </div>
                <div>{config.description}</div>
              </Card>
            );
          })} */}
            <Card
              noHover
              className="max-w-60 gap-2 flex flex-col text-sm bg-white"
            >
              <div className="font-semibold text-theme-neutral-700">
                On-Demand
              </div>
              <div className="text-theme-primary-600 font-semibold">
                {formatCurrency(currentPrice)} / hr
              </div>
              <div className="text-xs">
                Pay as you go, with costs based on actual usage time
              </div>
            </Card>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Summary:</div>
          <Card
            noHover
            className="transition-all duration-500 w-full py-4 text-sm flex flex-col gap-3 border-0 overflow-clip z-10"
            style={{ height: isExpanded ? 172 : 112 }}
          >
            <div className="flex justify-between items-center bg-theme-primary-50 z-10 -mx-5 -mt-4 px-5 pt-4">
              <div className="font-semibold text-black">
                {displayModelName(gpu?.model)} * {gpuCount}
              </div>
              <div>
                <Button
                  variant="link"
                  className="text-xs font-medium"
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                >
                  {isExpanded ? 'Hide' : 'Show'} Details
                </Button>
              </div>
            </div>
            <div
              className={twClassnames(
                'transition -translate-y-16 flex flex-col gap-3 duration-500',
                { 'translate-y-0': isExpanded }
              )}
            >
              <div className="flex justify-between text-xs text-black font-medium">
                <div>GPU RAM: {instance.gpuRamGB} GB</div>
                <div>{instance.storageCapacity} Storage</div>
              </div>
              <div className="flex justify-between text-xs text-black font-medium">
                <div>CPU RAM: {instance.ramCapacity}</div>
                <div>CPU Cores: {instance.cpu?.cores}</div>
              </div>
              <div className="flex justify-between text-xs text-theme-neutral-600">
                <div>Machine Cost</div>
                <div>{formatCurrency(currentPrice)} / hr</div>
              </div>
              <div className="flex justify-between text-xs text-theme-neutral-600">
                <div>Running Disk Cost</div>
                <div>$0.000 / hr</div>
              </div>
            </div>
          </Card>
        </div>
        {userInfo && !userInfo?.public_key && (
          <div className="text-theme-danger-500 text-sm">
            *You haven't uploaded your public key yet. Please go to&nbsp;
            <HyperLink
              to="/settings"
              className="text-theme-danger-500 underline hover:text-theme-danger-700"
              onClick={onClose}
            >
              Settings
            </HyperLink>
            &nbsp;and upload your public key to proceed.
          </div>
        )}
        {userInfo?.role === UserRole.User && (
          <div className="text-sm text-theme-danger-500">
            *In order to rent this instance, you must buy more credits.
          </div>
        )}
        {errorMessage && (
          <div className="text-theme-danger-500 text-sm">{errorMessage}</div>
        )}
        <div className="flex justify-between gap-x-8 mt-3">
          <Button className="flex-1" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <RentInstanceButton
            onClick={handleConfirm}
            className="flex-1"
            instanceId={instance.id}
            disabled={
              buttonsDisabled || !userInfo?.public_key || instance.reserved
            }
            isLoading={isLoading}
          >
            {instance.reserved ? 'Reserved' : 'Confirm'}
          </RentInstanceButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRentModal;
