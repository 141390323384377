import { useRef, useState } from 'react';
import { LuMusic } from 'react-icons/lu';
import Slider from 'rc-slider';
import PauseCircleIcon from './icons/PauseCircleIcon';
import PlayCircleIcon from './icons/PlayCircleIcon';
import RotateLeftIcon from './icons/RotateLeftIcon';
import RotateRightIcon from './icons/RotateRightIcon';

const AudioPlayer = ({
  title,
  audio,
  image,
}: {
  title: string;
  audio: string;
  image: string;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const handleProgressUpdate = (value: any) => {
    setCurrentTime(value);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
  };
  return (
    <div className="border border-theme-neutral-300 rounded-lg py-4 px-5 text-black bg-white flex gap-5">
      <div className="flex items-center justify-center">
        {image ? (
          <img src={image} className="w-[76px] h-[76px] rounded-lg" />
        ) : (
          <div className="bg-theme-neutral-300 rounded-lg p-5 text-theme-neutral-700">
            <LuMusic size={36} />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm">{title}</div>
        <audio
          ref={audioRef}
          src={`data:audio/wav;base64,${audio}`}
          onEnded={() => {
            if (isPlaying) {
              setIsPlaying(false);
            }
          }}
          onTimeUpdate={(e: any) => {
            setCurrentTime(e.target.currentTime);
          }}
          onError={() => {
            setCurrentTime(0);
            setIsPlaying(false);
            if (audioRef.current) {
              audioRef.current.load();
            }
          }}
        />
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <RotateLeftIcon
              className="transition-color cursor-pointer text-theme-neutral-700"
              onClick={() => {
                if (audioRef.current) {
                  const timeMinusTen = audioRef.current.currentTime - 10;
                  audioRef.current.currentTime = Math.max(timeMinusTen, 0);
                }
              }}
            />
            <div className="text-xs">10s</div>
          </div>
          {isPlaying ? (
            <PauseCircleIcon
              className="transition-color cursor-pointer text-theme-neutral-700"
              onClick={() => {
                audioRef.current?.pause();
                setIsPlaying(false);
              }}
            />
          ) : (
            <PlayCircleIcon
              className="transition-color cursor-pointer text-theme-neutral-700"
              onClick={() => {
                audioRef.current?.play();
                setIsPlaying(true);
              }}
            />
          )}
          <div className="flex items-center gap-1">
            <div className="text-xs">10s</div>
            <RotateRightIcon
              className="transition-color cursor-pointer text-theme-neutral-700"
              onClick={() => {
                if (audioRef.current) {
                  const timePlusTen = audioRef.current.currentTime + 10;
                  if (
                    timePlusTen > audioRef.current.duration &&
                    audioRef.current.duration
                  ) {
                    audioRef.current.currentTime = audioRef.current.duration;
                  } else {
                    audioRef.current.currentTime = timePlusTen;
                  }
                }
              }}
            />
          </div>
        </div>
        <Slider
          value={currentTime}
          min={0}
          step={0.001}
          max={audioRef.current?.duration}
          onChange={handleProgressUpdate}
          className="w-48"
          classNames={{
            rail: '!bg-theme-neutral-400',
            track: '!bg-black',
            handle:
              'transition !bg-theme-neutral-700 h-3 w-3 -mt-1 !border-white !ring-2 !ring-theme-neutral-700 !opacity-100 !shadow-none',
          }}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
