import Dropdown, {
  DropdownButton,
  DropdownOverlay,
  DropdownMenu,
} from '../common/Dropdown';
import { BsPaperclip } from 'react-icons/bs';
import UploadDropdownItem from './UploadDropdownItem';
import twClassnames from '../../utils/classnames';

const UploadDropdown = ({
  onDrop,
  position = 'top',
}: {
  onDrop: any;
  position?: 'top' | 'bottom';
}) => {
  return (
    <Dropdown>
      <DropdownButton
        className="text-sm text-theme-neutral-700 hover:text-theme-primary-600"
        variant="link"
        displayPosition={false}
      >
        <BsPaperclip size={20} />
      </DropdownButton>
      <DropdownOverlay
        className={twClassnames('w-48 left-0 bottom-0 mb-7', {
          'top-0 bottom-auto': position === 'bottom',
        })}
      >
        <DropdownMenu>
          <UploadDropdownItem onDrop={onDrop} />
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default UploadDropdown;
