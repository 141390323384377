import Card from '../../../../common/Card';
import { formatCurrency } from '../../../../../utils/value';
import { InstanceHistory } from '../../../../../slices/usage';
import {
  differenceInSeconds,
  format,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import { displayModelName } from '../../../../../utils/instances';

const GPUMarketplaceCard = ({
  instance_name,
  started_at,
  terminated_at,
  price,
  hardware,
}: InstanceHistory) => {
  const duration = intervalToDuration({
    end: terminated_at,
    start: started_at,
  });
  const gpus = hardware.gpus;
  const gpuModel = displayModelName(gpus[0].model); // todo: stop assuming all GPUs are the same model
  // TODO: might need to update: not sure what this does?
  const gpuCount = gpus.length;
  const seconds = differenceInSeconds(terminated_at, started_at);
  const dollarsPerHour = (price.amount / 100) * gpuCount;
  const billableSeconds = seconds < 60 ? 0 : seconds;
  const billedHours = billableSeconds / 60 / 60;
  const totalCost = billedHours * dollarsPerHour;

  return (
    <Card
      noHover
      className={
        'flex flex-col gap-3 pb-5 text-theme-neutral-600 bg-theme-neutral-50 border-none text-sm'
      }
    >
      <div className="flex justify-between gap-2">
        <div className="font-medium text-theme-primary-600 text-base">
          <span>{gpuCount}x</span> {gpuModel}
        </div>
        <div>
          <span className="ml-2 p-0.5 px-2 border border-theme-neutral-300 rounded-[8px]">
            {instance_name}
          </span>
        </div>
      </div>
      <div className="flex justify-between gap-2 items-center">
        <div>Start & End</div>
        <div>
          {format(started_at, 'MM/dd H:mm')} {' - '}
          {format(terminated_at, 'MM/dd H:mm')}
        </div>
      </div>
      <div className="flex justify-between gap-2 items-center">
        <div>Total GPU Hours</div>
        <div>{formatDuration(duration, { delimiter: ', ' })}</div>
      </div>
      <div className="flex justify-between gap-2 items-center">
        <div>Hourly Cost / GPU</div>
        <div>{formatCurrency(price.amount / 100)}</div>
      </div>
      <div className="flex justify-between gap-2 items-center font-semibold">
        <div>Total Cost</div> {/* this is approximate now! */}
        <div>{formatCurrency(totalCost)}</div>
      </div>
    </Card>
  );
};

export default GPUMarketplaceCard;
