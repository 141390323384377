const StarIcon = ({ size = 18, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="star-04">
      <path
        id="Solid"
        d="M9.70001 1.23077C9.58861 0.941122 9.31033 0.75 9 0.75C8.68967 0.75 8.41139 0.941122 8.29999 1.23077L6.79102 5.15408C6.56572 5.73986 6.49492 5.90867 6.39808 6.04486C6.30091 6.18152 6.18152 6.30091 6.04486 6.39808C5.90867 6.49492 5.73986 6.56572 5.15408 6.79102L1.23077 8.29999C0.941122 8.41139 0.75 8.68967 0.75 9C0.75 9.31033 0.941122 9.58861 1.23077 9.70001L5.15408 11.209C5.73986 11.4343 5.90867 11.5051 6.04486 11.6019C6.18152 11.6991 6.30091 11.8185 6.39808 11.9551C6.49492 12.0913 6.56572 12.2601 6.79102 12.8459L8.29999 16.7692C8.41139 17.0589 8.68967 17.25 9 17.25C9.31033 17.25 9.58861 17.0589 9.70001 16.7692L11.209 12.8459C11.4343 12.2601 11.5051 12.0913 11.6019 11.9551C11.6991 11.8185 11.8185 11.6991 11.9551 11.6019C12.0913 11.5051 12.2601 11.4343 12.8459 11.209L16.7692 9.70001C17.0589 9.58861 17.25 9.31033 17.25 9C17.25 8.68967 17.0589 8.41139 16.7692 8.29999L12.8459 6.79102C12.2601 6.56572 12.0913 6.49492 11.9551 6.39808C11.8185 6.30091 11.6991 6.18152 11.6019 6.04486C11.5051 5.90867 11.4343 5.73986 11.209 5.15408L9.70001 1.23077Z"
        fill={color}
      />
    </g>
  </svg>
);

export default StarIcon;
