import { useDispatch } from 'react-redux';
import ModalClose from '../common/ModalClose';
import Button from '../common/Button';
import { openModal } from '../../slices/modals';
import { ModalName } from '.';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';

const LowBalanceModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-4 p-3">
      <ModalClose onClose={onClose} />
      <div className="text-lg font-semibold text-theme-neutral-700">
        Oops! Looks like your balance is a bit short.
      </div>
      <div className="text-sm">
        You don't have enough balance at the moment. Please top up before trying
        to rent GPUs.
      </div>
      <div className="flex gap-3">
        <Button
          variant="neutral-outline"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          className="flex-1"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              openModal({
                name: ModalName.AddFundsModal,
                props: { defaultAmount: 5 },
                styles: isMobile
                  ? fullScreenStyles
                  : {
                      content: { maxWidth: '700px' },
                    },
              })
            );
          }}
        >
          Top up now
        </Button>
      </div>
    </div>
  );
};

export default LowBalanceModal;
