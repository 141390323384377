import { Outlet } from 'react-router-dom';
import MaxWidth from '../../common/MaxWidth';

const Billing = () => {
  return (
    <MaxWidth>
      <Outlet /> {/* BillingHome */}
    </MaxWidth>
  );
};

export default Billing;
