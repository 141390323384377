import { useDispatch } from 'react-redux';
import ModalClose from '../common/ModalClose';
import { AppDispatch } from '../../store';
import { deleteMachine } from '../../slices/machines';
import Button from '../common/Button';
import toast from 'react-hot-toast';

const ConfirmDeleteGPUModal = ({
  onClose,
  machineId,
  onDelete,
}: {
  onClose: () => void;
  machineId: string;
  onDelete?: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleDelete = async () => {
    await toast.promise(dispatch(deleteMachine(machineId)), {
      loading: 'Deleting...',
      success: 'Machine instance was deleted!',
      error: 'Machine failed to be deleted.',
    });
    onClose();
    onDelete?.();
  };
  return (
    <div className="lg:px-4 px-2 py-2 flex flex-col gap-5">
      <ModalClose onClose={onClose} />
      <div className="flex items-center flex-wrap text-lg font-semibold text-theme-neutral-700">
        Are you sure?
      </div>
      <div className="text-sm">
        All the data will be eliminated from our dashboard. You cannot undo this
        action.
      </div>
      <div className="flex gap-5">
        <Button className="flex-1" variant="neutral-outline" onClick={onClose}>
          No, cancel
        </Button>
        <Button className="flex-1" variant="danger" onClick={handleDelete}>
          Yes, delete
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDeleteGPUModal;
