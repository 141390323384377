import { InputHTMLAttributes, ReactElement, forwardRef } from 'react';
import twClassnames from '../../utils/classnames';
import { Tooltip } from 'react-tooltip';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeText?: (text: string) => void;
  containerClassName?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  endIconClassName?: string;
  className?: string;
  error?: boolean;
  tooltipText?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      value,
      onChange,
      onChangeText,
      containerClassName,
      className,
      startIcon,
      endIcon,
      endIconClassName,
      error,
      tooltipText,
      ...otherProps
    },
    ref
  ) => (
    <div
      id={id}
      className={twClassnames('relative flex', containerClassName)}
      {...(tooltipText && { 'data-tooltip-id': `${id}-tooltip` })}
    >
      {startIcon && (
        <div className="absolute left-4 text-theme-neutral-600 mt-3.5">
          {startIcon}
        </div>
      )}
      <input
        ref={ref}
        value={value}
        onChange={(e) => {
          onChange?.(e);
          onChangeText?.(e.target.value);
        }}
        className={twClassnames(
          'text-sm border border-theme-primary-200 text-black py-2 px-4 rounded-lg bg-white placeholder:text-theme-neutral-600 w-full disabled:border-theme-neutral-200 disabled:text-theme-neutral-500 disabled:bg-theme-neutral-100 focus:border-theme-primary-600 outline-none',
          {
            'pl-12': !!startIcon,
            'pr-12': !!endIcon,
            '!border-theme-danger-600': !!error,
          },
          className
        )}
        {...otherProps}
      />
      {endIcon && (
        <div
          className={twClassnames(
            'absolute right-3 text-theme-neutral-600 mt-3.5',
            endIconClassName
          )}
        >
          {endIcon}
        </div>
      )}
      {tooltipText && (
        <Tooltip id={`${id}-tooltip`} className="z-10" place="top">
          {tooltipText}
        </Tooltip>
      )}
    </div>
  )
);

export default Input;
