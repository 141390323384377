import { GoDash, GoPlus } from 'react-icons/go';
import Button from './Button';

const NumberStepper = ({
  value,
  disabled = false,
  addDisabled,
  subtractDisabled,
  subtract,
  add,
}: {
  value: number;
  disabled?: boolean;
  addDisabled?: boolean;
  subtractDisabled?: boolean;
  subtract?: () => void;
  add?: () => void;
}) => {
  return (
    <div className="flex items-center border border-theme-neutral-200 px-1 rounded-xl">
      <Button
        variant="simple"
        type="button"
        disabled={disabled || subtractDisabled}
        className="inline px-2"
        onClick={subtract}
      >
        <GoDash />
      </Button>
      <span className="text-center cursor-default font-bold text-theme-primary-600 min-w-4">
        {value}
      </span>
      <Button
        variant="simple"
        type="button"
        disabled={disabled || addDisabled}
        className="inline px-2"
        onClick={add}
      >
        <GoPlus />
      </Button>
    </div>
  );
};

export default NumberStepper;
