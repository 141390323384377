const BorderDashedLine = ({ color = 'currentColor', ...otherProps }) => (
  <svg
    width="964"
    height="1"
    viewBox="0 0 964 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <line
      x1="0.5"
      y1="0.5"
      x2="963.5"
      y2="0.500084"
      stroke={color}
      strokeLinecap="round"
      strokeDasharray="10 10"
    />
  </svg>
);

export default BorderDashedLine;
