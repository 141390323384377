import { ReactNode, useState } from 'react';
import { FiX } from 'react-icons/fi';
import twClassnames from '../../utils/classnames';

const Closeable = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const [closed, setClosed] = useState(false);
  if (closed) return null;
  return (
    <div
      className={twClassnames('flex w-full items-center rounded-lg', className)}
    >
      <div className="flex-1">{children}</div>
      <div className="hover:text-theme-primary-600">
        <FiX
          size={20}
          className="cursor-pointer"
          onClick={() => setClosed(true)}
        />
      </div>
    </div>
  );
};
export default Closeable;
