import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';
import {
  ResponseStatus,
  InstanceRental,
  InstanceResponse,
  InstanceStatus,
  Instance,
} from '../utils/types';
import {
  addNodePriceDb,
  fetchMarketplace,
  fetchRented,
  fetchSupplied,
  fetchSupplierInstructions,
  rentInstanceDb,
  terminateInstanceDb,
} from '../services/marketplace';
import { RootState } from '../store';
import { formatInstance } from '../utils/instances';

interface InstanceSlice {
  marketplaceInstances: InstanceResponse[];
  marketplaceStatus: ResponseStatus;
  marketplaceError: string;
  marketplaceInitialFetch: boolean;
  rentedInstances: InstanceRental[];
  rentedInstancesStatus: ResponseStatus;
  rentedInstancesError: string;
  rentedInstancesInitialFetch: boolean;
  suppliedInstances: InstanceResponse[];
  suppliedInstancesStatus: ResponseStatus;
  suppliedInstancesError: string;
  suppliedInstancesInitialFetch: boolean;
  addNodePriceStatus: ResponseStatus;
  addNodePriceError: string;
  supplierInstructions: string | undefined;
  status: ResponseStatus;
  error: string;
}

const initialState: InstanceSlice = {
  marketplaceInstances: [],
  marketplaceStatus: ResponseStatus.Unfetched,
  marketplaceError: '',
  marketplaceInitialFetch: false,
  rentedInstances: [],
  rentedInstancesStatus: ResponseStatus.Unfetched,
  rentedInstancesError: '',
  rentedInstancesInitialFetch: false,
  suppliedInstances: [],
  suppliedInstancesStatus: ResponseStatus.Unfetched,
  suppliedInstancesError: '',
  suppliedInstancesInitialFetch: false,
  addNodePriceStatus: ResponseStatus.Unfetched,
  addNodePriceError: '',
  supplierInstructions: undefined,
  status: ResponseStatus.Unfetched,
  error: '',
};

const fetchMarketplaceInstancesConstant = 'fetchMarketplaceInstances';
const fetchRentedInstancesConstant = 'fetchRentedInstances';
const fetchSuppliedInstancesConstant = 'fetchSuppliedInstances';
const fetchSupplierInstructionsConstant = 'fetchSupplierInstructions';
const terminateInstanceConstant = 'terminateInstance';
const rentInstanceConstant = 'rentInstanceConstant';
const addNodePriceConstant = 'addNodePrice';

const buildOptimisticInstanceRental = (
  instance: InstanceResponse,
  gpuCount: number
) => {
  const newId = uuidV4();
  const hardware = {
    ...instance.hardware,
    gpus: instance.hardware.gpus.slice(0, gpuCount),
  };
  const optimisticInstanceRental: InstanceRental = {
    id: newId,
    start: new Date().toISOString(),
    end: null,
    instance: formatInstance({ ...instance, hardware }),
    sshCommand: '',
  };
  return optimisticInstanceRental;
};

const instancesSlice = createSlice({
  name: 'instances',
  initialState,
  reducers: {
    // fetch marketplace instances
    [`${fetchMarketplaceInstancesConstant}/pending`]: (state) => {
      state.marketplaceStatus = ResponseStatus.Loading;
    },
    [`${fetchMarketplaceInstancesConstant}/fulfilled`]: (state, action) => {
      state.marketplaceStatus = ResponseStatus.Success;
      state.marketplaceInitialFetch = true;
      const existingInstances = state.marketplaceInstances;
      state.marketplaceInstances = action.payload.map((node: Instance) => {
        const existingInstance = existingInstances.find(
          (instance) => instance.id === node.id
        );
        if (existingInstance?.status === InstanceStatus.starting) {
          return existingInstance;
        }
        return node;
      });
    },
    [`${fetchMarketplaceInstancesConstant}/rejected`]: (state, action) => {
      state.marketplaceStatus = ResponseStatus.Failure;
      state.marketplaceError = action.payload;
    },
    // fetch rented instances
    [`${fetchRentedInstancesConstant}/pending`]: (state) => {
      state.rentedInstancesStatus = ResponseStatus.Loading;
    },
    [`${fetchRentedInstancesConstant}/fulfilled`]: (state, action) => {
      state.rentedInstancesStatus = ResponseStatus.Success;
      state.rentedInstancesInitialFetch = true;
      const existingRentals = state.rentedInstances;
      state.rentedInstances = action.payload.map((rental: InstanceRental) => {
        const existingRental = existingRentals.find(
          (eRental) => eRental.instance.id === rental.instance.id
        );
        const existingStoppage =
          existingRental?.instance.status === InstanceStatus.stopping ||
          existingRental?.end;
        if (existingStoppage) {
          return existingRental;
        }
        return rental;
      });
    },
    [`${fetchRentedInstancesConstant}/rejected`]: (state, action) => {
      state.rentedInstancesStatus = ResponseStatus.Failure;
      state.rentedInstancesError = action.payload;
    },
    // fetch supplied instances
    [`${fetchSuppliedInstancesConstant}/pending`]: (state) => {
      state.suppliedInstancesStatus = ResponseStatus.Loading;
    },
    [`${fetchSuppliedInstancesConstant}/fulfilled`]: (state, action) => {
      state.suppliedInstancesStatus = ResponseStatus.Success;
      state.suppliedInstancesInitialFetch = true;
      state.suppliedInstances = action.payload;
    },
    [`${fetchSuppliedInstancesConstant}/rejected`]: (state, action) => {
      state.suppliedInstancesStatus = ResponseStatus.Failure;
      state.suppliedInstancesError = action.payload;
    },
    // fetch supplier instructions
    [`${fetchSupplierInstructionsConstant}/pending`]: () => {},
    [`${fetchSupplierInstructionsConstant}/fulfilled`]: (state, action) => {
      state.supplierInstructions = action.payload;
    },
    [`${fetchSupplierInstructionsConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
    },
    [`${addNodePriceConstant}/pending`]: (state) => {
      state.addNodePriceStatus = ResponseStatus.Loading;
    },
    [`${addNodePriceConstant}/fulfilled`]: (state, action: any) => {
      state.suppliedInstances.forEach((instance) => {
        const { clusterName, nodeName, amount } = action.meta.arg;
        if (instance.cluster_name === clusterName && instance.id === nodeName) {
          instance.pricing.price.amount = amount;
        }
      });
      state.addNodePriceStatus = ResponseStatus.Success;
    },
    [`${addNodePriceConstant}/rejected`]: (state, action: any) => {
      state.addNodePriceError = action.error.message;
      state.addNodePriceStatus = ResponseStatus.Failure;
    },
    [`${terminateInstanceConstant}/pending`]: (state, action: any) => {
      const index = state.rentedInstances.findIndex(
        (rental) => rental.id === action.meta.arg
      );
      state.rentedInstances[index].instance.status = InstanceStatus.stopping;
    },
    [`${terminateInstanceConstant}/fulfilled`]: (state, action: any) => {
      const rentedInstanceIndex = state.rentedInstances.findIndex(
        (i) => i.id === action.meta.arg
      );
      if (rentedInstanceIndex > -1) {
        state.rentedInstances[rentedInstanceIndex].end =
          new Date().toISOString();
      }
    },
    [`${terminateInstanceConstant}/rejected`]: () => {},
    [`${rentInstanceConstant}/pending`]: (state, action: any) => {
      const { clusterName, nodeName } = action.meta.arg;
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === `${clusterName}-${nodeName}`
      );
      if (state.marketplaceInstances[instanceIndex]) {
        state.marketplaceInstances[instanceIndex].status =
          InstanceStatus.starting;
      }
    },
    [`${rentInstanceConstant}/fulfilled`]: (state, action: any) => {
      const { clusterName, nodeName, gpuCount } = action.meta.arg;
      const instanceId = `${clusterName}-${nodeName}`;
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === instanceId
      );
      const marketplaceInstance = state.marketplaceInstances[instanceIndex];
      if (marketplaceInstance) {
        state.rentedInstances.push(
          buildOptimisticInstanceRental(marketplaceInstance, gpuCount)
        );
        state.marketplaceInstances = state.marketplaceInstances.filter(
          (instance) => instance.id !== instanceId
        );
      }
    },
    [`${rentInstanceConstant}/rejected`]: (state, action: any) => {
      const { clusterName, nodeName } = action.meta.arg;
      const instanceId = `${clusterName}-${nodeName}`;
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === instanceId
      );
      const marketplaceInstance = state.marketplaceInstances[instanceIndex];
      if (marketplaceInstance) {
        marketplaceInstance.status = InstanceStatus.node_ready;
      }
    },
  },
});

// marketplace instances
export const fetchMarketplaceInstances = createAsyncThunk(
  `instances/${fetchMarketplaceInstancesConstant}`,
  fetchMarketplace
);

const getMarketplaceInstances = (state: RootState) =>
  state.instances.marketplaceInstances;

const getFlattenedMarketplaceInstances = createSelector(
  [getMarketplaceInstances],
  (instances) => {
    const newInstances: InstanceResponse[] = instances.flatMap((node) => {
      if (node.reserved || node.gpus_reserved === 0) {
        return node;
      }

      const reservedNode = {
        ...node,
        reserved: true,
        gpus_total: node.gpus_reserved,
        gpus_reserved: node.gpus_reserved,
        id: `${node.id}-reserved`,
      };
      return [node, reservedNode];
    });
    return newInstances;
  }
);

export const getFormattedMarketplaceInstances = createSelector(
  [getFlattenedMarketplaceInstances],
  (instances) => instances.map(formatInstance)
);

const getMarketplaceInstance = (state: RootState, instanceId?: string) =>
  state.instances.marketplaceInstances.find(
    (instance) => instance.id === instanceId
  );

export const getFormattedMarketplaceInstance = createSelector(
  [getMarketplaceInstance],
  (instance) => (instance ? formatInstance(instance) : instance)
);

export const getMarketplaceStatus = (state: RootState) =>
  state.instances.marketplaceStatus;

export const getMarketplaceInitialLoading = (state: RootState) =>
  state.instances.marketplaceStatus === ResponseStatus.Loading &&
  !state.instances.marketplaceInitialFetch;

// rented instances
export const fetchRentedInstances = createAsyncThunk(
  `instances/${fetchRentedInstancesConstant}`,
  fetchRented
);

const getRentedInstances = (state: RootState) =>
  state.instances.rentedInstances;

const getFilteredRentedInstances = createSelector(
  [getRentedInstances],
  (rInstances) => rInstances.filter((rental) => !rental.end)
);

export const getFormattedRentedInstances = createSelector(
  [getFilteredRentedInstances],
  (rentals) =>
    rentals.map((rental) => ({
      ...rental,
      instance: formatInstance(rental.instance),
    }))
);

export const getRentedInstancesInitialLoading = (state: RootState) =>
  state.instances.rentedInstancesStatus === ResponseStatus.Loading &&
  !state.instances.rentedInstancesInitialFetch;

export const getRentedInstance = (state: RootState, rentalId?: string) =>
  state.instances.rentedInstances.find((rental) => rental.id === rentalId);

export const getFormattedRentedInstance = createSelector(
  [getRentedInstance],
  (rental) =>
    rental
      ? {
          ...rental,
          instance: formatInstance(rental.instance),
        }
      : rental
);

export const rentInstance = createAsyncThunk(
  `instances/${rentInstanceConstant}`,
  rentInstanceDb
);

// supplied instances
export const fetchSuppliedInstances = createAsyncThunk(
  `instances/${fetchSuppliedInstancesConstant}`,
  fetchSupplied
);

const getSuppliedInstances = (state: RootState) =>
  state.instances.suppliedInstances;

export const getFormattedSuppliedInstances = createSelector(
  [getSuppliedInstances],
  (instances) => instances.map(formatInstance)
);

export const getSuppliedInstancesStatus = (state: RootState) =>
  state.instances.suppliedInstancesStatus;

export const getSuppliedInstancesInitialLoading = (state: RootState) =>
  state.instances.suppliedInstancesStatus === ResponseStatus.Loading &&
  !state.instances.suppliedInstancesInitialFetch;

export const getUnpricedSupplied = createSelector(
  [getFormattedSuppliedInstances],
  (instances) => instances.filter((inst) => !inst.pricing?.price?.amount)
);

export const getSupplierInstructions = (state: RootState) =>
  state.instances.supplierInstructions;

export const addNodePrice = createAsyncThunk(
  `instances/${addNodePriceConstant}`,
  addNodePriceDb
);

export const getAddNodePriceStatus = (state: RootState) =>
  state.instances.addNodePriceStatus;

export const fetchMarketplaceSupplierInstructions = createAsyncThunk(
  `instances/${fetchSupplierInstructionsConstant}`,
  fetchSupplierInstructions
);

export const getStatus = (state: RootState) => state.instances.status;

export const terminateInstance = createAsyncThunk(
  `instances/${terminateInstanceConstant}`,
  terminateInstanceDb
);

export default instancesSlice.reducer;
