import { useState } from 'react';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import InputSlider from '../common/InputSlider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { addNodePrice, getAddNodePriceStatus } from '../../slices/instances';
import { ResponseStatus } from '../../utils/types';
import { mapModelToPrice } from '../../utils/instances';
import { formatCurrency } from '../../utils/value';

const SetNodePriceModal = ({
  defaultPrice,
  clusterName,
  nodeName,
  gpuModel,
  onClose,
}: {
  defaultPrice: number;
  clusterName: string;
  nodeName: string;
  gpuModel: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [nodePrice, setNodePrice] = useState(defaultPrice / 100 || 0);
  const [nodePriceError, setNodePriceError] = useState('');
  const nodePriceStatus = useSelector(getAddNodePriceStatus);
  const nodePriceLoading = nodePriceStatus === ResponseStatus.Loading;
  const maxModelPrice = mapModelToPrice[gpuModel] || mapModelToPrice.Default;
  const validate = (value: number) => {
    if (value <= 0) {
      setNodePriceError('Node must be priced at more than $0');
      return false;
    } else if (value > maxModelPrice / 100) {
      setNodePriceError(
        `Nodes of this GPU type must be priced at ${formatCurrency(
          maxModelPrice / 100
        )}`
      );
      return false;
    }
    setNodePriceError('');
    return true;
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const valid = validate(nodePrice);
    if (valid) {
      await dispatch(
        addNodePrice({
          amount: Math.round(nodePrice * 100),
          clusterName,
          nodeName,
        })
      ).unwrap();
      onClose();
    }
  };
  const handleInputChange = (value: number) => {
    validate(value);
    setNodePrice(value);
  };
  return (
    <div className="p-3 flex flex-col gap-3">
      <ModalClose onClose={onClose} />
      <div className="text-center text-lg font-semibold text-black">
        Update Node Price
      </div>
      <div className="text-sm font-medium text-black">
        <div>Cluster name: {clusterName}</div>
        <div>Node name: {nodeName}</div>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <div>
          <InputSlider
            id="node-price"
            label="Price / GPU / hr"
            name="node-price"
            value={nodePrice}
            min={0}
            max={maxModelPrice / 100}
            step={0.01}
            onChange={handleInputChange}
            error={nodePriceError}
          />
        </div>
        <div className="flex gap-3">
          <Button
            variant="outline"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className="flex-1"
          >
            Cancel
          </Button>
          <Button type="submit" className="flex-1" isLoading={nodePriceLoading}>
            Update price
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SetNodePriceModal;
