import { ReactNode, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import DropdownContext from './DropdownContext';
import twClassnames from '../../../utils/classnames';

const Dropdown = ({
  children,
  className,
  defaultOpen = false,
}: {
  children: ReactNode;
  className?: string;
  defaultOpen?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  useOnClickOutside(ref, () => isOpen && setIsOpen(false));
  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div ref={ref} className={twClassnames('relative', className)}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
