import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextArea from '../FormTextArea';
import FormInput from '../FormInput';
import Button from '../common/Button';
import { createMessageDb } from '../../services/messages';
import { useSelector } from 'react-redux';
import { getUser } from '../../slices/auth';
import { RootState } from '../../store';
import { getMachine } from '../../slices/machines';
import { gpuTypes } from '../../utils/instances';
import { contactSupplier } from '../../services/machines';
import { usePostHog } from 'posthog-js/react';
import toast from 'react-hot-toast';
import ModalClose from '../common/ModalClose';

interface Inputs {
  name: string;
  email: string;
  message: string;
}

const ContactSupplierModal = ({
  supplier,
  machineId,
  onClose,
}: {
  supplier: any;
  machineId: string;
  onClose: () => void;
}) => {
  const posthog = usePostHog();
  const user = useSelector(getUser);
  const machine = useSelector((state: RootState) =>
    getMachine(state, machineId)
  );
  const { control, handleSubmit } = useForm<Inputs>();
  const gpuModel = gpuTypes.find(
    (types) => types.value === machine?.gpuModel
  )?.label;
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    createMessageDb({ ...data, machineId });
    posthog?.capture('Contact Supplier Sent', {
      supplierId: supplier.owner,
      machineId,
    });
    toast.promise(
      contactSupplier({
        message: data.message,
        supplierId: supplier.owner,
        email: supplier.email,
        gpuDetails: `${machine?.gpuCount}x ${gpuModel}`,
      }),
      {
        loading: 'Sending...',
        success: 'Message was sent to supplier!',
        error: 'Message failed to send.',
      }
    );
    onClose();
  };
  return (
    <div className="lg:px-4 py-2 px-2">
      <div className="mb-5 flex items-center justify-center">
        <ModalClose size={20} onClose={onClose} />
        <div className="font-semibold text-lg text-theme-neutral-700">
          Contact {supplier.name || 'the Supplier'}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          control={control}
          defaultValue={user?.displayName || ''}
          label="Name"
          name="name"
          rules={{ required: true }}
          disabled={!!user?.displayName}
        />
        <FormInput
          control={control}
          defaultValue={user?.email || ''}
          type="email"
          label="Email"
          name="email"
          rules={{ required: true }}
          disabled={!!user?.email}
        />
        <FormTextArea
          label="Message"
          name="message"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          className="min-h-28"
          labelClassName="font-semibold text-base text-theme-neutral-600"
        />
        <Button type="submit" className="px-12">
          Send
        </Button>
      </form>
    </div>
  );
};

export default ContactSupplierModal;
