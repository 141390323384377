import classNames from 'classnames';
import { FiXCircle } from 'react-icons/fi';

const ModalClose = ({
  size = 20,
  className,
  onClose,
}: {
  size?: number;
  className?: string;
  onClose: () => void;
}) => (
  <FiXCircle
    className={classNames(
      'transition cursor-pointer text-theme-neutral-500 hover:text-theme-neutral-600 transition absolute right-3 top-3',
      className
    )}
    size={size}
    onClick={onClose}
  />
);

export default ModalClose;
