import { Tooltip } from 'react-tooltip';
import Button from './Button';
import CopyIcon from './icons/CopyIcon';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import twClassnames from '../../utils/classnames';

const CopyButton = ({
  id,
  copyText,
  size = 20,
  className = '',
  tooltipText = 'Copied!',
  text,
  disabled = false,
  textClassName = '',
  copyClassName = '',
}: {
  id: string;
  copyText: string;
  size?: number;
  className?: string;
  tooltipText?: string;
  text?: string;
  disabled?: boolean;
  textClassName?: string;
  copyClassName?: string;
}) => {
  const posthog = usePostHog();
  const copy = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  return (
    <Button
      variant="link"
      className={twClassnames('flex items-center', className)}
      data-tooltip-id={`${id}-tooltip`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        posthog?.capture(`Copy Button Clicked`, {
          id,
        });
        copy(copyText);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 500);
      }}
      disabled={disabled}
    >
      {text && (
        <div className={twClassnames('text-xs mr-2', textClassName)}>
          {text}
        </div>
      )}
      <CopyIcon size={size} className={copyClassName} />
      <Tooltip id={`${id}-tooltip`} isOpen={copied}>
        {tooltipText}
      </Tooltip>
    </Button>
  );
};

export default CopyButton;
