import { TextareaHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import TextAreaContainer from './common/TextAreaContainer';

export interface FormTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  labelClassName?: string;
  name: string;
  control: any;
  rules?: any;
  defaultValue?: string;
}

const FormTextArea = ({
  label,
  name,
  control,
  rules,
  defaultValue,
  ...otherProps
}: FormTextAreaProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  return (
    <TextAreaContainer
      label={label}
      required={rules?.required}
      error={
        fieldState.error?.type === 'required' ? 'This field is required.' : ''
      }
      {...otherProps}
      {...field}
    />
  );
};

export default FormTextArea;
