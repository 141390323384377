import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { PostHogProvider } from 'posthog-js/react';
import { POSTHOG_KEY, POSTHOG_OPTIONS } from './utils/constants';
import Root from './Root';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider apiKey={POSTHOG_KEY} options={POSTHOG_OPTIONS}>
      <Root />
    </PostHogProvider>
  </React.StrictMode>
);
