export const pluralize = (num: number, unit: string) =>
  num === 1 ? `${num} ${unit}` : `${num} ${unit}s`;

export const middleTruncate = (str: string, chars: number) => {
  const longEnough = str.length > chars * 2;
  if (!longEnough) return str;
  const firstSub = str.slice(0, chars);
  const lastSub = str.slice(str.length - chars, str.length);
  return `${firstSub}...${lastSub}`;
};
