import { ButtonHTMLAttributes } from 'react';
import twClassnames from '../../utils/classnames';
import { ButtonVariant, buttonClasses } from '../../utils/button';
import LoadingSpinner from './LoadingSpinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  isLoading?: boolean;
}

const Button = ({
  variant = 'primary',
  className,
  children,
  isLoading = false,
  disabled,
  ...props
}: ButtonProps) => (
  <button
    className={twClassnames(
      'transition text-sm',
      { 'inline-block px-4 py-2 border rounded-lg': variant !== 'link' },
      { 'border-none': variant === 'simple' },
      buttonClasses[variant],
      className
    )}
    disabled={isLoading || disabled}
    {...props}
  >
    {isLoading ? (
      <div className="flex items-center justify-center">
        <LoadingSpinner size={16} className="w-4 h-4 mr-2" />
        Loading...
      </div>
    ) : (
      children
    )}
  </button>
);

export default Button;
