const ResetIcon = ({ size = 20, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="repeat-04">
      <path
        id="Icon"
        d="M9.99935 17.0835C13.9114 17.0835 17.0827 13.9122 17.0827 10.0002C17.0827 7.64555 15.9338 5.55927 14.166 4.27133M10.8327 18.6668L9.16602 17.0002L10.8327 15.3335M9.99935 2.91683C6.08733 2.91683 2.91602 6.08815 2.91602 10.0002C2.91602 12.3548 4.0649 14.4411 5.83268 15.729M9.16602 4.66683L10.8327 3.00016L9.16602 1.3335"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ResetIcon;
