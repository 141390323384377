export const formatAuthError = (error: string) => {
  if (error.includes('auth/account-exists-with-different-credential')) {
    return 'Account exists with different credentials';
  } else if (error.includes('auth/cancelled-popup-request')) {
    return 'Pop up request cancelled! Please try again.';
  } else if (error.includes('auth/popup-closed')) {
    return 'Pop up closed! Please try again.'
  } else if (error.includes('auth/invalid-credential')) {
    return 'Invalid login. Please try again!';
  }
  return error;
};