import { useNavigate } from 'react-router-dom';
import NewMachineForm, {
  NewMachineInputs,
} from '../components/Compute/NewMachineForm';
import { createMachine } from '../slices/machines';
import { SubmitHandler } from 'react-hook-form';
import { getUser } from '../slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import toast from 'react-hot-toast';
import { submitHubspotForm } from '../services/machines';

const NewMachineSupply = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const onSubmit: SubmitHandler<NewMachineInputs> = (data) => {
    toast.promise(
      dispatch(
        createMachine({
          ...data,
          owner: user?.uid,
        })
      ).unwrap(),
      {
        loading: 'Loading...',
        success: 'GPU supply was created!',
        error: 'GPU supply failed to create.',
      }
    );
    const fields = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }));
    submitHubspotForm({
      fields,
    });
    navigate('/compute');
  };
  return (
    <div className="flex flex-col w-full px-10">
      <h1>Supply GPU</h1>
      <NewMachineForm onSubmit={onSubmit} isSupply />
    </div>
  );
};

export default NewMachineSupply;
