import { collection, doc, setDoc } from '@firebase/firestore/lite';
import { db } from '../utils/firebase';

export const createMessageDb = async (message: any) => {
  const collRef = collection(db, 'messages');
  const newMessageRef = doc(collRef);
  const newMessage = {
    ...message,
    id: newMessageRef.id,
    created: Date.now(),
  };
  await setDoc(newMessageRef, newMessage);
  return newMessage;
};
