import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from '@firebase/auth';
import { ResponseStatus } from '../utils/types';
import { auth } from '../utils/firebase';
import { RootState } from '../store';
import {
  fetchUserInfoDb,
  resetApiKeyDb,
  updatePublicKeyDb,
  updateUserInfoDb,
} from '../services/auth';
import { sleep } from '../utils/time';

export enum SignInProvider {
  Google = 'google',
  GitHub = 'github',
  Password = 'password',
}

const googleProvider = new GoogleAuthProvider();
const ghProvider = new GithubAuthProvider();

const providerMap = {
  [SignInProvider.Google]: googleProvider,
  [SignInProvider.GitHub]: ghProvider,
  [SignInProvider.Password]: null,
};

export enum UserRole {
  User = 'user',
  Pro = 'pro',
  Elite = 'elite',
}

interface UserInfo {
  api_key: string;
  email: string;
  email_verified: boolean;
  id: string;
  is_active: boolean;
  name: string;
  picture: string;
  provider: string;
  public_key: string;
  onboarded_at: string | null;
  onboarded_for: string | null;
  role: UserRole;
}

interface AuthSlice {
  user: User | null;
  userInfo: UserInfo | null;
  userInfoStatus: ResponseStatus;
  provider: SignInProvider | null;
  status: ResponseStatus;
  error: string;
  publicKeyStatus: ResponseStatus;
  publicKeyError: string;
}

const initialState: AuthSlice = {
  user: null,
  userInfo: null,
  userInfoStatus: ResponseStatus.Unfetched,
  provider: null,
  status: ResponseStatus.Unfetched,
  error: '',
  publicKeyStatus: ResponseStatus.Unfetched,
  publicKeyError: '',
};

const signInConstant = 'signIn';
const signOutConstant = 'signOut';
const fetchUserInfoConstant = 'fetchUserInfo';
const updateUserInfoConstant = 'updateUserInfo';
const updatePublicKeyConstant = 'updatePublicKey';

const signOutAuthReducer = (state: AuthSlice) => {
  state.error = '';
  state.status = ResponseStatus.Success;
  state.user = null;
  state.userInfo = null;
  state.provider = null;
};
const setAuthReducer = (state: AuthSlice, action: any) => {
  state.error = '';
  state.status = ResponseStatus.Success;
  state.user = action.payload.user;
  state.provider = action.payload.providerName;
};

const setUserInfo = (state: AuthSlice, action: any) => {
  state.userInfo = action.payload;
  state.userInfoStatus = ResponseStatus.Success;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [`${signInConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${signInConstant}/fulfilled`]: setAuthReducer,
    [`${signInConstant}/rejected`]: (state, action: any) => {
      state.error = action.error.message;
      state.status = ResponseStatus.Failure;
    },
    [`${signOutConstant}/fulfilled`]: signOutAuthReducer,
    [`${signOutConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
      state.status = ResponseStatus.Failure;
    },
    [`${fetchUserInfoConstant}/pending`]: (state) => {
      state.userInfoStatus = ResponseStatus.Loading;
    },
    [`${fetchUserInfoConstant}/fulfilled`]: setUserInfo,
    [`${updateUserInfoConstant}/pending`]: (state) => {
      state.userInfoStatus = ResponseStatus.Loading;
    },
    [`${updateUserInfoConstant}/fulfilled`]: setUserInfo,
    [`${updateUserInfoConstant}/rejected`]: (state, action: any) => {
      state.error = action.error.message;
      state.userInfoStatus = ResponseStatus.Failure;
    },
    [`${fetchUserInfoConstant}/rejected`]: (state, action: any) => {
      state.error = action.error.message;
      state.userInfoStatus = ResponseStatus.Failure;
    },
    [`${updatePublicKeyConstant}/pending`]: (state) => {
      state.publicKeyStatus = ResponseStatus.Loading;
      state.publicKeyError = '';
    },
    [`${updatePublicKeyConstant}/fulfilled`]: (state, action) => {
      if (state.userInfo) {
        state.userInfo.public_key = action.payload;
      }
      state.publicKeyStatus = ResponseStatus.Success;
      state.publicKeyError = '';
    },
    [`${updatePublicKeyConstant}/rejected`]: (state, action: any) => {
      state.publicKeyError = action.error.message;
      state.publicKeyStatus = ResponseStatus.Failure;
    },
    setUser: setAuthReducer,
    resetUser: signOutAuthReducer,
  },
});

export const { setUser, resetUser } = authSlice.actions;

export const signIn = createAsyncThunk(
  `auth/${signInConstant}`,
  async ({
    providerName,
    mode,
    email,
    password,
  }: {
    providerName: SignInProvider;
    mode: string;
    email?: string;
    password?: string;
  }) => {
    let response;
    if (providerName !== SignInProvider.Password) {
      const provider = providerMap[providerName];
      response = await signInWithPopup(auth, provider);
    } else {
      if (!email || !password) {
        throw Error('Email and password are required for password');
      }
      const signInMethod =
        mode === 'signIn'
          ? signInWithEmailAndPassword
          : createUserWithEmailAndPassword;
      response = await signInMethod(auth, email, password);
      if (mode === 'register' && !response.user.emailVerified) {
        sendEmailVerification(response.user);
      }
    }
    return {
      user: response.user,
      providerName,
    };
  }
);

export const signOut = createAsyncThunk(`auth/${signOutConstant}`, () =>
  firebaseSignOut(auth)
);

export const fetchUserInfo = createAsyncThunk(
  `auth/${fetchUserInfoConstant}`,
  fetchUserInfoDb
);

export const updateUserInfo = createAsyncThunk(
  `auth/${updateUserInfoConstant}`,
  updateUserInfoDb
);

export const resetApiKey = createAsyncThunk(
  `auth/${updateUserInfoConstant}`,
  resetApiKeyDb
);

export const updatePublicKey = createAsyncThunk(
  `auth/${updatePublicKeyConstant}`,
  async ({ publicKey, delay }: { publicKey: string; delay?: number }) => {
    await updatePublicKeyDb(publicKey);
    if (delay) await sleep(delay);
    return publicKey;
  }
);

export const getUser = (state: RootState) => state.auth.user;
export const getAuthError = (state: RootState) => state.auth.error;
export const getAuthStatus = (state: RootState) => state.auth.status;
export const getAuth = (state: RootState) => state.auth;
export const getIsAuthenticated = createSelector([getUser], (user) => !!user);
export const getUserInfo = (state: RootState) => state.auth.userInfo;
export const getUserInfoStatus = (state: RootState) =>
  state.auth.userInfoStatus;
export const getPublicKeyStatus = (state: RootState) =>
  state.auth.publicKeyStatus;
export const getPublicKeyError = (state: RootState) =>
  state.auth.publicKeyError;

export default authSlice.reducer;
