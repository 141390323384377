const SettingsIcon = ({ size = 24, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <g>
      <path
        id='Icon'
        d='M10 18.1586V20.5C10 21.6046 10.8954 22.5 12 22.5C13.1046 22.5 14 21.6046 14 20.5V18.1586M12 2.5V3.5M3 12.5H2M5.5 6L4.8999 5.3999M18.5 6L19.1002 5.3999M22 12.5H21M18 12.5C18 15.8137 15.3137 18.5 12 18.5C8.68629 18.5 6 15.8137 6 12.5C6 9.18629 8.68629 6.5 12 6.5C15.3137 6.5 18 9.18629 18 12.5Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default SettingsIcon;
