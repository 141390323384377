import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';

export const fetchStripeCheckoutSessionURL = async (amount: number) => {
  const data = await hyperFetch(
    `${backendHost}/stripe/create-checkout-session`,
    { method: 'POST', body: JSON.stringify({ amount }) }
  );
  return data?.url;
};

export const fetchStripeCheckoutSessionStatus = async (sessionId: string) => {
  const data = await hyperFetch(
    `${
      backendHost
    }/stripe/session-status?session_id=${sessionId}`
  );
  return data?.status;
};
