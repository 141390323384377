import { useDispatch, useSelector } from 'react-redux';
import {
  getGeneratedImagesStatus,
  getSortedGeneratedImages,
  resetGeneratedImages,
} from '../../slices/models';
import GeneratedImageSet from './GeneratedImageSet';
import { useEffect } from 'react';
import WeirdCircleIcon from '../common/icons/WeirdCircleIcon';
import { ResponseStatus } from '../../utils/types';

const ModelImagesList = () => {
  const dispatch = useDispatch();
  const images = useSelector(getSortedGeneratedImages);
  const imageStatus = useSelector(getGeneratedImagesStatus);
  const isLoading = imageStatus === ResponseStatus.Loading;

  useEffect(() => {
    return () => {
      dispatch(resetGeneratedImages());
    };
  }, [dispatch]);

  if (images.length === 0 && !isLoading) {
    return (
      <div className="flex-1 flex flex-col justify-center items-center">
        <WeirdCircleIcon className="mb-6" />
        <div className="font-medium text-lg mb-4 text-theme-neutral-600">
          No images generated yet
        </div>
        <div className="text-sm w-[350px] text-theme-neutral-500 text-center">
          Start by describing the images you want to generate in the prompt box
          on the sidebar 👉
        </div>
      </div>
    );
  }
  return (
    <div className="lg:overflow-y-auto -mx-10 px-10">
      {isLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-6 bg-theme-neutral-200 rounded w-full lg:w-2/3" />
              <div className="h-6 bg-theme-neutral-200 rounded w-full lg:w-1/2" />
              <div className="h-80 w-80 bg-theme-neutral-200 rounded-lg" />
              <div className="h-5 bg-theme-neutral-200 rounded w-1/2 lg:w-1/4" />
            </div>
          </div>
        </div>
      )}
      {images.map((img) => (
        <GeneratedImageSet key={`image-${img.id}`} imageSet={img} />
      ))}
    </div>
  );
};

export default ModelImagesList;
