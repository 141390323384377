import MessageBar from '../../common/Chat/MessageBar';
import { usePostHog } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from '../../../slices/auth';
import AuthButton from '../../AuthButton';
import { useParams } from 'react-router-dom';
import AudioHistory from './AudioHistory';
import { getAudioValue, setAudioValue } from '../../../slices/chat';
import {
  generateAudio,
  getAudioInputParameters,
  getAudioStatus,
  resetGeneratedAudio,
} from '../../../slices/models';
import { AppDispatch } from '../../../store';
import { useEffect } from 'react';
import { ResponseStatus } from '../../../utils/types';

const defaultText =
  'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.';

const ModelAudio = () => {
  const { modelId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const audioInputParams = useSelector(getAudioInputParameters);
  const audioStatus = useSelector(getAudioStatus);
  const isLoading = audioStatus === ResponseStatus.Loading;
  const posthog = usePostHog();
  const audioValue = useSelector(getAudioValue);
  const handleAudioValue = (text: string) => dispatch(setAudioValue(text));

  useEffect(() => {
    return () => {
      dispatch(resetGeneratedAudio());
    };
  }, [dispatch]);

  return (
    <div className="flex flex-col flex-1 lg:overflow-y-hidden -mx-10">
      <AudioHistory />
      <MessageBar
        defaultText={defaultText}
        className="px-10"
        value={audioValue}
        onChange={handleAudioValue}
        onSubmit={() => {
          posthog?.capture('Generate Audio Clicked', {
            model: modelId,
            script: audioValue,
          });
          dispatch(
            generateAudio({
              text: audioValue || defaultText,
              ...audioInputParams.values,
            })
          );
          handleAudioValue('');
        }}
        disabled={isLoading}
        overrideButton={
          !isAuthenticated ? (
            <AuthButton className="mr-2 text-sm py-1.5" />
          ) : undefined
        }
      />
    </div>
  );
};

export default ModelAudio;
