const PencilIcon = ({ size = 16, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="pencil-02" clipPath="url(#clip0_8332_15716)">
      <path
        id="Icon"
        d="M11.9999 1.33301L14.6666 3.99967M1.33325 14.6663L2.18419 11.5462C2.2397 11.3427 2.26746 11.2409 2.31007 11.146C2.34791 11.0617 2.3944 10.9816 2.4488 10.907C2.51006 10.8229 2.58466 10.7483 2.73385 10.5991L9.6228 3.71013C9.7548 3.57813 9.8208 3.51212 9.89691 3.48739C9.96386 3.46564 10.036 3.46564 10.1029 3.48739C10.179 3.51212 10.245 3.57813 10.377 3.71013L12.2895 5.62255C12.4215 5.75456 12.4875 5.82056 12.5122 5.89667C12.534 5.96362 12.534 6.03573 12.5122 6.10268C12.4875 6.17879 12.4215 6.24479 12.2895 6.3768L5.40052 13.2657C5.25133 13.4149 5.17673 13.4895 5.09264 13.5508C5.01798 13.6052 4.93787 13.6517 4.8536 13.6895C4.75868 13.7321 4.6569 13.7599 4.45334 13.8154L1.33325 14.6663Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8332_15716">
        <rect width="16" height="16" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default PencilIcon;
