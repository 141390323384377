import { useDispatch, useSelector } from 'react-redux';
import BillingItemHeader from './headers/BillingItemHeader';
import {
  fetchPurchaseHistory,
  getPurchaseHistory,
} from '../../../../slices/usage';
import { useEffect, useState } from 'react';
import { AppDispatch } from '../../../../store';
import { getUser } from '../../../../slices/auth';
import BillingItemHeaderPeriodSubtitle from './headers/BillingItemHeaderPeriodSubtitle';
import Button from '../../../common/Button';
import { format } from 'date-fns';
import { formatCurrency } from '../../../../utils/value';

const MIN_SHOWN_AMOUNT = 5;
const STEP_SIZE = 5;

const BillingItemsPurchaseHistory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const purchaseHistory = useSelector(getPurchaseHistory);
  const user = useSelector(getUser);
  const [maxShown, setMaxShown] = useState(MIN_SHOWN_AMOUNT);

  useEffect(() => {
    if (user) {
      dispatch(fetchPurchaseHistory());
    }
  }, [user, dispatch]);

  const showMore = () => {
    setMaxShown(Math.min(maxShown + STEP_SIZE, purchaseHistory.length));
  };

  const collapse = () => {
    setMaxShown(MIN_SHOWN_AMOUNT);
  };

  const showButton = MIN_SHOWN_AMOUNT < purchaseHistory.length;
  const canCollapse = maxShown >= purchaseHistory.length;

  return (
    <div className="grow">
      <BillingItemHeader
        title="Recent Transactions"
        subtitle={
          <BillingItemHeaderPeriodSubtitle prefix="Below is your top-up history." />
        }
      />
      <div className="mt-2 flex flex-col gap-4 max-w-xl">
        <div>
          {!purchaseHistory.length && (
            <div className="text-sm">
              You don't have any transaction history yet.
            </div>
          )}
          {purchaseHistory.slice(0, maxShown).map((purchase) => {
            const { amount, timestamp } = purchase;
            return (
              <div
                className="flex justify-between py-3 border-b first:border-t border-theme-neutral-200 text-sm"
                key={purchase.timestamp}
              >
                <div>{format(timestamp, 'MM/dd/y HH:mm:ss')}</div>
                <div className="font-semibold">
                  {formatCurrency(amount / 100)}
                </div>
                <div>Stripe Purchase</div>
              </div>
            );
          })}
        </div>
        {showButton && (
          <Button variant="link" onClick={canCollapse ? collapse : showMore}>
            {canCollapse ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BillingItemsPurchaseHistory;
