import { format } from 'date-fns';

const BillingItemHeaderPeriodSubtitle = ({
  prefix = 'Current period',
  startDate,
  endDate,
}: {
  prefix?: string | undefined;
  startDate?: Date;
  endDate?: Date;
}) => {
  return (
    <div className="flex items-center justify-between mt-2 font-light text-sm text-theme-neutral-600">
      <div>
        {prefix}{' '}
        {(startDate || endDate) && (
          <>
            {startDate ? format(startDate, 'MMM dd') : ''}{' '}
            {endDate ? ' - ' : ''}
            {endDate ? format(endDate, 'MMM dd') : ''}
          </>
        )}
      </div>
    </div>
  );
};

export default BillingItemHeaderPeriodSubtitle;
