import { useDispatch } from 'react-redux';
import { openModal } from '../slices/modals';
import { ModalName } from './modals';
import Button from './common/Button';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../utils/modals';
import { usePostHog } from 'posthog-js/react';

const AuthButton = ({
  className,
  from,
}: {
  className?: string;
  from?: string;
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  return (
    <Button
      id="auth-button"
      className={className}
      onClick={(e) => {
        e.preventDefault();
        posthog?.capture("Login Button Clicked", {
          from
        })
        dispatch(
          openModal({
            name: ModalName.AuthModal,
            ...(from && { props: { from } }),
            ...(isMobile && { styles: fullScreenStyles }),
          })
        );
      }}
    >
      Log In
    </Button>
  );
};

export default AuthButton;
