import { ButtonHTMLAttributes } from 'react';
import twClassnames from '../../utils/classnames';

interface RadioButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  image?: string;
  selected?: boolean;
  otherSelected?: boolean;
  disabled?: boolean;
}

const RadioButton = ({
  text,
  image,
  selected,
  otherSelected,
  disabled,
  onClick,
  className,
}: RadioButtonProps) => (
  <button
    className={twClassnames(
      'flex transition items-center group py-4 px-6 rounded-lg text-black border border-theme-primary-400 hover:border-theme-primary-600 w-64 disabled:bg-theme-neutral-100 disabled:border-theme-neutral-100',
      {
        'border-theme-neutral-500': otherSelected,
        'border-theme-primary-600': selected,
      },
      className
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {image && <img src={image} className="w-[30px] h-[30px] mr-3" />}
    <div
      className={twClassnames('flex-1 text-left', {
        'text-theme-neutral-500': otherSelected,
        'text-theme-neutral-700': disabled,
      })}
    >
      {text}
    </div>
    <div
      className={twClassnames(
        'w-5 h-5 rounded-full border border-theme-primary-400 group-hover:border-theme-primary-600 flex items-center justify-center',
        {
          'border-theme-primary-600': selected,
          'border-theme-neutral-400 group-hover:border-theme-neutral-400 bg-theme-neutral-200':
            disabled,
          'border-theme-neutral-500 group-hover:border-theme-neutral-500':
            otherSelected,
        }
      )}
    >
      <div
        className={twClassnames(
          'transition-opacity w-3 h-3 rounded-full bg-theme-primary-600 group-hover:opacity-100',
          {
            'opacity-0 group-hover:bg-theme-neutral-500': !selected,
            '!opacity-0': disabled,
          }
        )}
      />
    </div>
  </button>
);

export default RadioButton;
