import { ReactNode, TextareaHTMLAttributes, forwardRef } from 'react';
import twClassnames from '../../utils/classnames';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  additionalContainer?: ReactNode;
  innerContainerClassName?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      containerClassName,
      startIcon,
      additionalContainer,
      endIcon,
      innerContainerClassName,
      disabled,
      ...otherProps
    },
    ref
  ) => (
    <div
      className={twClassnames(
        'relative transition text-sm flex rounded-xl bg-white border border-theme-primary-200 has-[:focus]:border-theme-primary-600 hover:border-theme-primary-600 text-theme-neutral-700 placeholder:text-theme-neutral-400 placeholder:font-light disabled:border-theme-neutral-400 disabled:text-theme-neutral-400 py-2',
        { 'bg-theme-neutral-200 !border-theme-neutral-400': disabled },
        containerClassName
      )}
    >
      {startIcon && (
        <div className="flex flex-col justify-end py-2 ml-3">{startIcon}</div>
      )}
      <div className={twClassnames('flex-1 flex flex-col px-4', innerContainerClassName)}>
        {additionalContainer}
        <textarea
          ref={ref}
          className={twClassnames('w-full py-2 outline-none', className)}
          disabled={disabled}
          {...otherProps}
        />
      </div>
      {endIcon && <div className="flex flex-col justify-end">{endIcon}</div>}
    </div>
  )
);

export default TextArea;
