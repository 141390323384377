const SaveIcon = ({ size = 18, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="save-01">
      <path
        id="Icon"
        d="M5.59292 2.86719V5.18406C5.59292 5.56569 5.59292 5.75651 5.66719 5.90228C5.73252 6.0305 5.83676 6.13474 5.96498 6.20008C6.11075 6.27435 6.30157 6.27435 6.68321 6.27435H11.3169C11.6986 6.27435 11.8894 6.27435 12.0352 6.20008C12.1634 6.13474 12.2676 6.0305 12.333 5.90228C12.4072 5.75651 12.4072 5.56569 12.4072 5.18406V3.54862M12.4072 15.133V10.7718C12.4072 10.3902 12.4072 10.1993 12.333 10.0536C12.2676 9.92536 12.1634 9.82111 12.0352 9.75578C11.8894 9.68151 11.6986 9.68151 11.3169 9.68151H6.68321C6.30157 9.68151 6.11075 9.68151 5.96498 9.75578C5.83676 9.82111 5.73252 9.92536 5.66719 10.0536C5.59292 10.1993 5.59292 10.3902 5.59292 10.7718V15.133M15.133 7.17757V11.8621C15.133 13.007 15.133 13.5795 14.9101 14.0168C14.7142 14.4014 14.4014 14.7142 14.0168 14.9101C13.5795 15.133 13.007 15.133 11.8621 15.133H6.13806C4.99315 15.133 4.42069 15.133 3.98339 14.9101C3.59873 14.7142 3.286 14.4014 3.09 14.0168C2.86719 13.5795 2.86719 13.007 2.86719 11.8621V6.13806C2.86719 4.99315 2.86719 4.42069 3.09 3.98339C3.286 3.59873 3.59873 3.286 3.98339 3.09C4.42069 2.86719 4.99315 2.86719 6.13806 2.86719H10.8226C11.1559 2.86719 11.3226 2.86719 11.4794 2.90484C11.6185 2.93823 11.7514 2.99329 11.8734 3.06802C12.0109 3.1523 12.1288 3.27016 12.3645 3.50587L14.4943 5.63567C14.73 5.87138 14.8479 5.98923 14.9321 6.12677C15.0069 6.24871 15.0619 6.38165 15.0953 6.52071C15.133 6.67756 15.133 6.84423 15.133 7.17757Z"
        stroke={color}
        strokeWidth="1.53322"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SaveIcon;
