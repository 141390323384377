const AMDLogo = ({ size = 14, color = '#5C5C5C', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="AMD mark">
      <path
        id="Vector"
        d="M3.75 21.8291V17.4076L10.0503 11.0987V19.941H18.8873L12.5815 26.25H3.75V21.8291ZM23.1737 23.005L20.1183 19.9539V9.89395H10.0644L3.9241 3.75H26.231L26.2334 14.7994L26.2487 25.9065C26.2521 25.9414 26.2488 25.9765 26.2389 26.0102C26.2267 26.0439 25.4297 25.2636 23.1737 23.005Z"
        fill={color}
      />
    </g>
  </svg>
);

export default AMDLogo;
