const AuthDividerLine = ({
  className = '',
  width = 490,
}: {
  className?: string;
  width?: string | number;
}) => (
  <svg
    width={width}
    height="19"
    viewBox="0 0 872 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    preserveAspectRatio="xMinYMin slice"
  >
    <path d="M1 18.2939H871" stroke="#594CE9" strokeLinecap="round" />
    <rect x="1" y="0.293945" width="8.05932" height="18.1698" fill="#594CE9" />
    <rect
      x="11.6973"
      y="0.293945"
      width="8.05932"
      height="18.1698"
      fill="#594CE9"
    />
    <rect
      x="22.3926"
      y="0.293945"
      width="8.05932"
      height="18.1698"
      fill="#594CE9"
    />
    <rect
      x="117.021"
      y="0.293945"
      width="8.05932"
      height="18.1698"
      fill="#594CE9"
    />
    <circle cx="130.545" cy="14.6485" r="3.81455" fill="#594CE9" />
    <circle cx="263.186" cy="14.6485" r="3.81455" fill="#594CE9" />
  </svg>
);

export default AuthDividerLine;
