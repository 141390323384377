import { useController } from 'react-hook-form';
import { Option } from '../utils/types';
import Dropdown, { DropdownButton, DropdownOverlay } from './common/Dropdown';
import DropdownMenu from './common/Dropdown/DropdownMenu';
import DropdownMenuItem from './common/Dropdown/DropdownMenuItem';
import twClassnames from '../utils/classnames';

interface FormSelectProps {
  label?: string;
  name: string;
  control: any;
  rules?: any;
  defaultValue?: string;
  options: Option[];
  placeholder?: string;
  menuClassName?: string;
}

const FormSelect = ({
  label,
  name,
  control,
  rules,
  defaultValue,
  options,
  placeholder = 'Select...',
  menuClassName,
}: FormSelectProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const selectedOptions = options.find((opt) => opt.value === field.value);
  return (
    <div className="flex flex-col mb-4">
      {label && (
        <label className="mb-2 font-semibold text-black text-sm">
          {label}
          {rules?.required && '*'}
        </label>
      )}
      <Dropdown>
        <DropdownButton
          className={twClassnames({
            'border-theme-danger-600 outline-theme-danger-600':
              !!fieldState.error,
          })}
          textClassName={twClassnames({
            'text-theme-neutral-400': !selectedOptions?.label && placeholder,
          })}
        >
          {selectedOptions?.label || placeholder}
        </DropdownButton>
        <DropdownOverlay>
          <DropdownMenu className={menuClassName}>
            {options.map((option) => (
              <DropdownMenuItem
                key={option.value}
                active={option.value === field.value}
                onClick={() => field.onChange(option.value)}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        </DropdownOverlay>
      </Dropdown>
      {fieldState.error?.type === 'required' && (
        <div className="text-theme-danger-600 mt-2">
          This field is required.
        </div>
      )}
    </div>
  );
};

export default FormSelect;
