import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';

export const fetchUsageDb = async () => {
  const usage = await hyperFetch(
    `${backendHost}/v1/usage`
  );
  return usage;
};

export const fetchBillingActivityDb = async () => {
  const data = await hyperFetch(
    `${backendHost}/billing/daily_usage`
  );
  return data?.daily_credits_used;
};

export const fetchInstanceHistoryDb = async () => {
  const data = await hyperFetch(
    `${backendHost}/v1/marketplace/instances/history`
  );
  return data?.instance_history;
};

export const fetchGpuHourlySpendDb = async () => {
  const data = await hyperFetch(
    `${backendHost}/billing/gpu_hourly_spend`
  );
  return data?.gpu_hourly_spend;
};

export const fetchPurchaseHistoryDb = async () => {
  const data = await hyperFetch(
    `${backendHost}/billing/purchase_history`
  );
  return data?.purchase_history;
};
