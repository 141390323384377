import { ReactElement } from 'react';
import twClassnames from '../../utils/classnames';

const ParamBadge = ({
  text,
  icon,
  onClick,
}: {
  text: string | number;
  icon: ReactElement;
  onClick?: () => void;
}) => (
  <div
    role={onClick ? 'button' : 'none'}
    tabIndex={0}
    className={twClassnames(
      'transition text-xs px-2 py-[3px] border border-theme-neutral-300 rounded flex items-center text-theme-neutral-700',
      {
        'hover:border-theme-primary-600 hover:text-theme-primary-600 cursor-pointer':
          !!onClick,
      }
    )}
    onClick={onClick}
  >
    {text}
    <div className="ml-2">{icon}</div>
  </div>
);

export default ParamBadge;
