import { ReactNode, useState } from 'react';
import CopyButton from './CopyButton';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import DotsHorizontalIcon from './icons/DotsHorizontalIcon';
import twClassnames from '../../utils/classnames';
import { middleTruncate } from '../../utils/strings';

const APIKey = ({
  apiKey,
  text = 'API Key',
  className,
  containerClassName,
  additionalButtons,
  innerClassName,
  textClassName,
}: {
  apiKey?: string;
  text?: string;
  containerClassName?: string;
  className?: string;
  additionalButtons?: ReactNode;
  innerClassName?: string;
  textClassName?: string;
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  if (!apiKey) return null;
  return (
    <div
      className={twClassnames(
        'bg-theme-neutral-100 w-full flex',
        containerClassName
      )}
    >
      <div
        className={twClassnames(
          'w-full lg:w-[480px] flex items-center gap-4 rounded-lg px-5 py-3',
          className
        )}
      >
        <div
          className={twClassnames(
            'text-black text-sm font-medium',
            textClassName
          )}
        >
          {text}
        </div>
        {showApiKey ? (
          <div
            className={twClassnames(
              'flex-1 px-5 sm:px-10 md:px-12 px-16 h-6 text-sm text-center',
              innerClassName
            )}
          >
            {middleTruncate(apiKey, 16)}
          </div>
        ) : (
          <div
            className={twClassnames(
              'flex items-center justify-center gap-6 flex-1',
              innerClassName
            )}
          >
            <div className="flex items-center gap-3">
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
            </div>
            <div className="flex items-center gap-3">
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
              <DotsHorizontalIcon />
            </div>
          </div>
        )}
        {showApiKey ? (
          <LuEyeOff
            className="transition cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
            size={22}
            onClick={() => setShowApiKey(false)}
          />
        ) : (
          <LuEye
            className="transition cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
            size={22}
            onClick={() => setShowApiKey(true)}
          />
        )}
        {/* copy */}
        <CopyButton
          id="api-key"
          size={20}
          copyText={apiKey}
          className="text-theme-neutral-600 hover:text-theme-primary-600"
        />
        {additionalButtons}
      </div>
    </div>
  );
};

export default APIKey;
