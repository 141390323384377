const NvidiaLogo = ({ size = 14, color = '#5C5C5C', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="Nvidia mark">
      <path
        id="Vector"
        d="M5.36822 5.3522V4.61628L5.59482 4.60724C7.69164 4.54372 9.06626 6.34247 9.06626 6.34247C9.06626 6.34247 7.58318 8.323 5.99136 8.323C5.77873 8.323 5.57092 8.29124 5.37252 8.22746V5.99232C6.18977 6.08786 6.35515 6.4331 6.8411 7.21886L7.9322 6.33756C7.9322 6.33756 7.13401 5.33361 5.79242 5.33361C5.65066 5.32896 5.5089 5.33826 5.36742 5.35168M5.36742 2.91669V4.01618L5.59401 4.00249C8.5089 3.90489 10.4124 6.30167 10.4124 6.30167C10.4124 6.30167 8.23022 8.85467 5.95565 8.85467C5.75698 8.85467 5.56367 8.8366 5.36983 8.80458V9.48627C5.53038 9.50435 5.69577 9.51803 5.85632 9.51803C7.97247 9.51803 9.50281 8.47742 10.9859 7.25088C11.2316 7.44196 12.2375 7.90056 12.4453 8.103C11.0377 9.23864 7.75501 10.1517 5.8939 10.1517C5.72034 10.1519 5.5469 10.1428 5.3744 10.1243V11.0834H13.4167V2.9172L5.36742 2.91669ZM5.36742 8.2272V8.80871C3.41181 8.47251 2.86867 6.5147 2.86867 6.5147C2.86867 6.5147 3.80835 5.51539 5.36742 5.35168V5.98768H5.36258C4.54533 5.89213 3.90313 6.62806 3.90313 6.62806C3.90313 6.62806 4.26692 7.86828 5.36742 8.2272ZM1.89597 6.43259C1.89597 6.43259 3.05044 4.78774 5.37225 4.6155V4.0167C2.8037 4.21656 0.583374 6.30632 0.583374 6.30632C0.583374 6.30632 1.83986 9.8044 5.36822 10.1223V9.48627C2.78008 9.17744 1.89678 6.43336 1.89678 6.43336L1.89597 6.43259Z"
        fill={color}
      />
    </g>
  </svg>
);

export default NvidiaLogo;
