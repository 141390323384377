import Button from '../common/Button';
import { Instance } from '../../utils/types';
import Card from '../common/Card';
import ModalClose from '../common/ModalClose';

const SuppliedInstanceModal = ({
  instance,
  onClose,
}: {
  instance: Instance;
  onClose: () => void;
}) => {
  return (
    <div className="p-4">
      <ModalClose onClose={onClose} />
      <h2 className="text-center">{`My Instance - ${instance.id}`}</h2>
      <div className="flex flex-col gap-4 my-4">
        <div>Template</div>
        <Card className="min-w-80 cursor-not-allowed select-none">
          <div className="font-2xl font-semibold">
            Start script: Not Configured
          </div>
          <div className="text-2xl text-theme-primary-500 font-bold my-3">
            Pytorch (default)
          </div>
          <div>CUDA version: 12.1</div>
        </Card>
        <div className="flex justify-end">
          <Button
            onClick={() => onClose()}
            className="flex items-center gap-2 shrink"
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuppliedInstanceModal;

