const RotateLeftIcon = ({ color = 'currentColor', ...otherProps }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="rotate-ccw" clipPath="url(#clip0_2933_12796)">
      <path
        id="Vector"
        d="M0.75 3.5V8H5.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M2.6325 11.75C3.1188 13.1303 4.0405 14.3152 5.25874 15.1261C6.47698 15.937 7.92576 16.33 9.38679 16.2459C10.8478 16.1618 12.2419 15.6052 13.3591 14.6598C14.4763 13.7145 15.2559 12.4318 15.5807 11.0048C15.9054 9.57783 15.7576 8.08399 15.1595 6.74835C14.5614 5.4127 13.5454 4.30762 12.2646 3.5996C10.9839 2.89157 9.50768 2.61897 8.05851 2.82286C6.60934 3.02675 5.26568 3.69609 4.23 4.73002L0.75 8.00002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2933_12796">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RotateLeftIcon;
