import { ReactNode } from 'react';
import HyperLink from './HyperLink';

const LinkBlock = ({
  href,
  text,
  icon,
}: {
  href: string;
  text: string;
  icon?: ReactNode;
}) => (
  <HyperLink
    href={href}
    className="transition py-6 flex flex-col gap-3 items-center justify-center w-64 shadow-theme-02 rounded-lg border border-theme-primary-200 hover:no-underline hover:border-theme-primary-600"
    rel="noreferrer noopener"
    target="_blank"
  >
    {icon}
    <div className="text-black font-semibold text-base">{text}</div>
  </HyperLink>
);

export default LinkBlock;
