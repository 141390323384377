import { AiOutlineInfoCircle } from 'react-icons/ai';
import Switch, { SwitchProps } from './Switch';
import { Tooltip } from 'react-tooltip';
import twClassnames from '../../utils/classnames';

interface SwitchContainer extends SwitchProps {
  id: string;
  label?: string;
  required?: boolean;
  error?: string;
  infoTooltip?: string;
  labelClassName?: string;
}

const SwitchContainer = ({
  id,
  label,
  required,
  error,
  infoTooltip,
  labelClassName,
  ...otherProps
}: SwitchContainer) => (
  <div id={id} className="flex justify-between items-center mb-4">
    {label && (
      <label className={twClassnames('text-black mr-3 flex items-center text-sm', labelClassName)}>
        {label}
        {required && '*'}
        {infoTooltip && (
          <AiOutlineInfoCircle
            data-tooltip-id={`info-${id}`}
            className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
          />
        )}
        <Tooltip id={`info-${id}`} className="z-10" place="top">
          {infoTooltip}
        </Tooltip>
      </label>
    )}
    <Switch className={error ? 'bg-theme-danger-600' : ''} {...otherProps} />
    {error && <div className="text-theme-danger-600 mt-2">error</div>}
  </div>
);

export default SwitchContainer;
