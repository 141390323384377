import { backendHost } from '../utils/constants';
import hyperFetch from '../utils/fetch';

export const redeemCouponCodeDb = async (couponCode: string) => {
  const result = await hyperFetch(`${backendHost}/settings/redeem_coupon_code`, {
    method: 'POST',
    body: JSON.stringify({ coupon_code: couponCode }),
  });
  return result;
};
