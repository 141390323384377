import { ReactNode } from 'react';
import twClassnames from '../../../utils/classnames';

const DropdownMenu = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => <div className={twClassnames('w-full p-2', className)}>{children}</div>;

export default DropdownMenu;
