import { useController } from 'react-hook-form';
import twClassnames from '../utils/classnames';
import NumberStepper from './common/NumberStepper';

interface FormNumberStepperProps {
  label?: string;
  labelClassName?: string;
  rules?: any;
  hideAsterick?: boolean;
  labelRight?: string;
  control: any;
  name: string;
  defaultValue?: number;
  containerClassName?: string;
}

const FormNumberStepper = ({
  label,
  labelClassName,
  rules,
  hideAsterick,
  labelRight,
  control,
  defaultValue,
  name,
  containerClassName,
}: FormNumberStepperProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  return (
    <div className={twClassnames('flex flex-col mb-4 items-start', containerClassName)}>
      {label && (
        <label
          className={twClassnames(
            'mb-2 font-semibold flex text-black text-sm',
            labelClassName
          )}
        >
          {label}
          {rules?.required && !hideAsterick && '*'}
          {labelRight && (
            <div className="flex-1 flex justify-end">{labelRight}</div>
          )}
        </label>
      )}
      <NumberStepper
        value={field.value}
        add={() => field.onChange(field.value + 1)}
        subtract={() => field.onChange(field.value - 1)}
        subtractDisabled={field.value === rules.minValue}
      />
      {fieldState.error?.type === 'required' && (
        <div className="text-theme-danger-600 mt-2">{label} is required.</div>
      )}
    </div>
  );
};

export default FormNumberStepper;
