import twClassnames from '../../utils/classnames';

export interface SwitchProps {
  className?: string;
  selected: boolean;
  onSelect: (value: boolean) => void;
}

const Switch = ({ className, selected, onSelect }: SwitchProps) => {
  return (
    <div
      role="button"
      className={twClassnames(
        'w-9 h-5 bg-theme-neutral-400 flex items-center transition cursor-pointer p-0.5 rounded-full',
        { 'bg-theme-primary-600': selected },
        className
      )}
      onClick={() => onSelect(!selected)}
    >
      <input
        type="checkbox"
        value={String(selected)}
        onChange={() => onSelect(!selected)}
        className="hidden"
      />
      <div
        className={twClassnames('bg-white rounded-full h-4 w-4 transition', {
          'translate-x-4': selected,
        })}
      />
    </div>
  );
};

export default Switch;
