import { ReactNode } from 'react';
import Button, { ButtonProps } from '../Button';
import twClassnames from '../../../utils/classnames';

interface ButtonGroupButtonProps extends ButtonProps {
  active?: boolean;
  children: ReactNode;
  className?: string;
}

const ButtonGroupButton = ({
  active = false,
  children,
  className,
  ...otherProps
}: ButtonGroupButtonProps) => (
  <Button
    variant={active ? 'primary' : 'outline'}
    className={twClassnames(
      'first:rounded-l-lg first:border-l last:border-r last:rounded-r-lg rounded-none flex-1 border-x-0 hover:border-theme-primary-600 outline-none',
      { 'hover:bg-theme-primary-600': active, 'bg-transparent': !active },
      className
    )}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonGroupButton;
