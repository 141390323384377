import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseStatus } from '../utils/types';
import { fetchFAQDb } from '../services/support';
import { RootState } from '../store';

interface FAQ {
  question: string;
  answer: string;
  hideAlpha?: boolean;
  index: string;
}

interface FAQSection {
  title: string;
  description: string;
  questions: FAQ[];
  slug: string;
}

interface SupportSlice {
  faqSections: FAQSection[];
  status: ResponseStatus;
  error: string;
}

const initialState: SupportSlice = {
  faqSections: [],
  status: ResponseStatus.Unfetched,
  error: '',
};

const fetchFAQConstant = 'fetchFAQ';

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    [`${fetchFAQConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchFAQConstant}/fulfilled`]: (state, action) => {
      state.status = ResponseStatus.Success;
      state.faqSections = action.payload;
    },
    [`${fetchFAQConstant}/rejected`]: (state, action: any) => {
      state.error = action.error.message;
      state.status = ResponseStatus.Failure;
    },
  },
});

export const fetchFAQ = createAsyncThunk(
  `support/${fetchFAQConstant}`,
  fetchFAQDb
);

export const getFAQSections = (state: RootState) => state.support.faqSections;

export default supportSlice.reducer;
