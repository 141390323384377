export const formatCurrency = (
  number?: number,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    space = false,
  }: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    space?: boolean;
  } = {}
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Math.min(
      minimumFractionDigits,
      maximumFractionDigits
    ),
    maximumFractionDigits,
  });
  // return formatter.format(number || 0);
  const [symbol, ...rest] = formatter.formatToParts(number || 0);
  return `${symbol.value}${space ? ' ' : ''}${rest
    .map(({ value }) => value)
    .join('')}`;
};