import { usePostHog } from 'posthog-js/react';
import { Tooltip } from 'react-tooltip';
import { UserRole } from '../../slices/auth';
import useUser from '../../hooks/useUser';
import Button, { ButtonProps } from '../common/Button';
import AuthButton from '../AuthButton';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../modals';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';
import { openModal } from '../../slices/modals';
import { getBalance } from '../../slices/usage';

interface RentInstanceButtonProps extends ButtonProps {
  instanceId: string;
  reserved?: boolean;
  className?: string;
}

const RentInstanceButton = ({
  instanceId,
  className,
  ...props
}: RentInstanceButtonProps) => {
  const { userInfo, isAuthenticated } = useUser();
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const balance = useSelector(getBalance);
  const notEnoughBalance = !balance || balance <= 0;
  if (!isAuthenticated) {
    return <AuthButton className={className} />;
  }
  if (notEnoughBalance || userInfo?.role === UserRole.User) {
    return (
      <Button
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          posthog?.capture('Upgrade Button Clicked', {
            instanceId,
          });
          dispatch(
            openModal({
              name:
                userInfo?.role === UserRole.User
                  ? ModalName.FreeCredits
                  : ModalName.LowBalance,
              styles: isMobile
                ? fullScreenStyles
                : {
                    content: { maxWidth: 500 },
                  },
            })
          );
        }}
        data-tooltip-id={`buy-credits-tooltip-${instanceId}`}
      >
        Buy Credits
        <Tooltip
          id={`buy-credits-tooltip-${instanceId}`}
          className="z-10"
          place="top"
        >
          You must buy credits to rent GPUs.
        </Tooltip>
      </Button>
    );
  }
  return <Button className={className} {...props} />;
};

export default RentInstanceButton;
