import { FormEvent, ReactNode, useEffect, useRef } from 'react';
import twClassnames from '../../../utils/classnames';
import Button from '../Button';
import SendIcon from '../icons/Send';
import TextArea from '../TextArea';
import UploadDropdown from '../../Models/UploadDropdown';
import { FiX } from 'react-icons/fi';

const defaultHeight = 36;

const MessageBar = ({
  value,
  onChange,
  onSubmit,
  onImageChange,
  imageURL,
  className = '',
  disabled = false,
  overrideButton,
  defaultText = 'What can I do in LA?',
  allowImageUpload = false,
  defaultImage,
}: {
  value: string;
  onChange: (text: string) => void;
  onSubmit: (defaultText?: string, image?: string) => void;
  onImageChange?: (image: string) => void;
  imageURL?: string;
  className?: string;
  disabled?: boolean;
  overrideButton?: ReactNode;
  defaultText?: string;
  allowImageUpload?: boolean;
  defaultImage?: string;
}) => {
  const placeholderText = `User Prompt: ${defaultText}`;
  const textRef = useRef<HTMLTextAreaElement>(null);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (disabled) return;
    if (value) {
      onSubmit('');
    } else {
      onSubmit(defaultText);
    }
  };
  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result;
      const image = String(dataURL).replace(
        /data:image\/(jpg|png|jpeg);base64,/,
        ''
      );
      onImageChange?.(image);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (textRef.current?.scrollHeight) {
      if (textRef.current) {
        // In order to get an accurate scroll height, we must set height to default height before resetting it to the accurate scroll height
        // Also must keep logic as mutating style object because updating a separate state variable will lead to race conditions and not update consistently
        textRef.current.style.height = `${defaultHeight}px`;
        textRef.current.style.height = `${textRef?.current.scrollHeight}px`;
      }
    }
  }, [value, textRef])

  const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
    onChange((e.target as HTMLTextAreaElement).value);
    
  };
  return (
    <form
      className={twClassnames('flex relative', className)}
      onSubmit={handleSubmit}
    >
      <TextArea
        ref={textRef}
        value={value}
        onChange={handleChange}
        placeholder={placeholderText}
        onKeyDown={onEnterPress}
        className="resize-none"
        containerClassName="w-full"
        startIcon={allowImageUpload ? <UploadDropdown onDrop={onDrop} /> : null}
        style={{ height: defaultHeight }}
        additionalContainer={
          allowImageUpload &&
          (imageURL || defaultImage) && (
            <div className="relative w-12 h-12 group transition">
              <img
                src={`data:image/png;base64,${imageURL || defaultImage}`}
                className="w-12 h-12 rounded"
              />
              <div
                className="transition hidden group-hover:flex cursor-pointer absolute top-0 right-0 -mt-1.5 -mr-1.5 p-1 text-black bg-white border border-theme-neutral-400 rounded-full"
                role="button"
                onClick={() => onImageChange?.('')}
              >
                <FiX size={12} />
              </div>
            </div>
          )
        }
        endIcon={
          overrideButton || (
            <Button
              variant="link"
              type="submit"
              className="hover:bg-theme-primary-600 hover:text-white bg-theme-neutral-200 disabled:bg-theme-neutral-200 disabled:text-theme-neutral-500 p-2 mr-4 rounded-full duration-400"
              disabled={disabled}
            >
              <SendIcon size={20} />
            </Button>
          )
        }
      />
    </form>
  );
};

export default MessageBar;
