import { ReactNode } from 'react';
import twClassnames from '../../../utils/classnames';

interface ChatProps {
  children: ReactNode;
  className?: string;
  style?: any;
}

const Chat = ({ children, className, style }: ChatProps) => (
  <div
    className={twClassnames('flex flex-col h-full', className)}
    style={style}
  >
    {children}
  </div>
);

export default Chat;
