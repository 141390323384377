import { useState } from 'react';
import AuthForm, { AuthMode } from '../AuthForm';
import ModalClose from '../common/ModalClose';

const AuthModal = ({
  from,
  onClose,
}: {
  from?: string;
  onClose: () => void;
}) => {
  const [mode, setMode] = useState<AuthMode>('signIn');
  return (
    <div className="px-8">
      <ModalClose onClose={onClose} />
      <AuthForm
        mode={mode}
        onChangeMode={setMode}
        from={from}
        onComplete={onClose}
      />
    </div>
  );
};

export default AuthModal;
