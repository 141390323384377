import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import hyperFetch from '../utils/fetch';
import {
  InstanceBillingPeriod,
  InstanceHardwareCPU,
  InstanceHardwareGPU,
  InstanceHardwareRAM,
  InstanceHardwareStorage,
  ResponseStatus,
} from '../utils/types';
import {
  fetchBillingActivityDb,
  fetchGpuHourlySpendDb,
  fetchInstanceHistoryDb,
  fetchPurchaseHistoryDb,
  fetchUsageDb,
} from '../services/usage';
import { backendHost } from '../utils/constants';

export interface ModelUsage extends Usage {
  model_id: number;
  model_name: string;
  model_type: string;
}

export interface Usage {
  date: string;
  completion_tokens: number;
  prompt_tokens: number;
  image_steps: number;
  audio_tokens: number;
}

export interface BillingActivity {
  date: string;
  amount: number;
}

export interface Purchase {
  amount: number;
  timestamp: string;
}

// TODO: move this to types
export interface InstanceHistory {
  instance_name: string;
  node_name: string;
  cluster_name: string;
  started_at: string;
  terminated_at: string;
  hardware: {
    cpus: InstanceHardwareCPU[];
    gpus: InstanceHardwareGPU[];
    ram: InstanceHardwareRAM[];
    storage: InstanceHardwareStorage[];
  };
  price: {
    amount: number;
    period: InstanceBillingPeriod;
  };
  gpu_count: number;
}

interface UsageSlice {
  balance: number | undefined;
  balanceLoading: boolean;
  balance_error: string | undefined;
  usage: ModelUsage[];
  usageStatus: ResponseStatus;
  usageError: string;
  billingActivity: BillingActivity[];
  billingActivityLoading: boolean;
  billingActivityError: string | undefined;
  instanceHistory: InstanceHistory[];
  instanceHistoryStatus: ResponseStatus;
  instanceHistoryError: string | undefined;
  gpuHourlySpend: number;
  gpuHourlySpendLoading: boolean;
  gpuHourlySpendError: string | undefined;
  purchaseHistory: Purchase[];
  purchaseHistoryLoading: boolean;
  purchaseHistoryError: string | undefined;
  instanceHistoryInitialFetch: boolean;
}

const initialState: UsageSlice = {
  balance: undefined,
  balanceLoading: false,
  balance_error: undefined,
  usage: [],
  usageStatus: ResponseStatus.Unfetched,
  usageError: '',
  billingActivity: [],
  billingActivityLoading: false,
  billingActivityError: undefined,
  instanceHistory: [],
  instanceHistoryStatus: ResponseStatus.Unfetched,
  instanceHistoryError: undefined,
  gpuHourlySpend: 0,
  gpuHourlySpendLoading: false,
  gpuHourlySpendError: undefined,
  purchaseHistory: [],
  purchaseHistoryLoading: false,
  purchaseHistoryError: undefined,
  instanceHistoryInitialFetch: false,
};

const fetchBalanceKey = 'fetchBalance';
const fetchUsageConstant = 'fetchUsage';
const fetchBillingActivityConstant = 'fetchBillingActivity';
const fetchInstanceHistoryConstant = 'fetchInstanceHistory';
const fetchGpuHourlySpendConstant = 'fetchGpuHourlySpend';
const fetchPurchaseHistoryConstant = 'fetchPurchaseHistory';

const usageSlice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    [`${fetchBalanceKey}/pending`]: (state) => {
      state.balanceLoading = true;
    },
    [`${fetchBalanceKey}/fulfilled`]: (state, action) => {
      state.balanceLoading = false;
      state.balance = action.payload;
    },
    [`${fetchBalanceKey}/rejected`]: (state, action) => {
      state.balanceLoading = false;
      state.balance_error = action.payload;
    },
    [`${fetchUsageConstant}/pending`]: (state) => {
      state.usageStatus = ResponseStatus.Loading;
    },
    [`${fetchUsageConstant}/fulfilled`]: (state, action) => {
      state.usageStatus = ResponseStatus.Success;
      state.usage = action.payload;
    },
    [`${fetchUsageConstant}/rejected`]: (state, action: any) => {
      state.usageStatus = ResponseStatus.Failure;
      state.usageError = action.error;
    },
    [`${fetchBillingActivityConstant}/pending`]: (state) => {
      state.billingActivityLoading = true;
    },
    [`${fetchBillingActivityConstant}/fulfilled`]: (state, action) => {
      state.billingActivityLoading = false;
      state.billingActivity = (action.payload || []).filter(
        (a: BillingActivity) => a.amount !== 0
      );
    },
    [`${fetchBillingActivityConstant}/rejected`]: (state, action) => {
      state.billingActivityLoading = false;
      state.billingActivityError = action.payload;
    },
    [`${fetchInstanceHistoryConstant}/pending`]: (state) => {
      state.instanceHistoryStatus = ResponseStatus.Loading;
    },
    [`${fetchInstanceHistoryConstant}/fulfilled`]: (state, action) => {
      state.instanceHistoryStatus = ResponseStatus.Success;
      state.instanceHistoryInitialFetch = true;
      state.instanceHistory = action.payload;
    },
    [`${fetchInstanceHistoryConstant}/rejected`]: (state, action) => {
      state.instanceHistoryStatus = ResponseStatus.Failure;
      state.instanceHistoryError = action.payload;
    },
    [`${fetchGpuHourlySpendConstant}/pending`]: (state) => {
      state.gpuHourlySpendLoading = true;
    },
    [`${fetchGpuHourlySpendConstant}/fulfilled`]: (state, action) => {
      state.gpuHourlySpendLoading = false;
      state.gpuHourlySpend = action.payload;
    },
    [`${fetchGpuHourlySpendConstant}/rejected`]: (state, action) => {
      state.gpuHourlySpendLoading = false;
      state.gpuHourlySpendError = action.payload;
    },
    [`${fetchPurchaseHistoryConstant}/pending`]: (state) => {
      state.purchaseHistoryLoading = true;
    },
    [`${fetchPurchaseHistoryConstant}/fulfilled`]: (state, action) => {
      state.purchaseHistoryLoading = false;
      state.purchaseHistory = action.payload;
    },
    [`${fetchPurchaseHistoryConstant}/rejected`]: (state, action) => {
      state.purchaseHistoryLoading = false;
      state.purchaseHistoryError = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    resetUsage: () => initialState,
  },
});

const getCurrentBalanceURL = `${
  backendHost
}/billing/get_current_balance`;

const fetchBalanceDb = async () => {
  const balance = await hyperFetch(`${getCurrentBalanceURL}`).then((data) => {
    return data?.credits;
  });
  return balance;
};

export const fetchBalance = createAsyncThunk(
  `usage/${fetchBalanceKey}`,
  fetchBalanceDb
);

export const fetchUsage = createAsyncThunk(
  `usage/${fetchUsageConstant}`,
  fetchUsageDb
);

export const fetchBillingActivity = createAsyncThunk(
  `usage/${fetchBillingActivityConstant}`,
  fetchBillingActivityDb
);

export const fetchInstanceHistory = createAsyncThunk(
  `usage/${fetchInstanceHistoryConstant}`,
  fetchInstanceHistoryDb
);

export const fetchGpuHourlySpend = createAsyncThunk(
  `usage/${fetchGpuHourlySpendConstant}`,
  fetchGpuHourlySpendDb
);

export const fetchPurchaseHistory = createAsyncThunk(
  `usage/${fetchPurchaseHistoryConstant}`,
  fetchPurchaseHistoryDb
);

export const getInstanceHistoryInitialLoading = (state: RootState) =>
  state.usage.instanceHistoryStatus === ResponseStatus.Loading &&
  !state.usage.instanceHistoryInitialFetch;

export const { setBalance, resetUsage } = usageSlice.actions;

export const getBalance = (state: RootState) => state.usage.balance;

export const getUsage = (state: RootState) => state.usage.usage;
export const getUsageLoading = (state: RootState) => state.usage.usageStatus === ResponseStatus.Loading;

export const getBillingActivity = (state: RootState) =>
  state.usage.billingActivity;

export const getInstanceHistory = (state: RootState) =>
  state.usage.instanceHistory;

export const getGpuHourlySpend = (state: RootState) =>
  state.usage.gpuHourlySpend;

export const getPurchaseHistory = (state: RootState) =>
  state.usage.purchaseHistory;

export default usageSlice.reducer;
