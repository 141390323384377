import { intervalToDuration } from 'date-fns';
import { displayDuration } from '../../utils/instances';
import useInterval from '../../hooks/useInterval';
import { useState, memo } from 'react';

const InstanceAgeDiff = ({
  start,
  className,
}: {
  start: string;
  className?: string;
}) => {
  const startDate = new Date(start);
  const defaultDiff = intervalToDuration({ end: new Date(), start: startDate });
  const [diff, setDiff] = useState(defaultDiff);

  useInterval(() => {
    setDiff(intervalToDuration({ end: new Date(), start: startDate }));
  }, 1000);

  return <div className={className}>Age: {displayDuration(diff)}</div>;
};

const MemoizedInstanceAgeDiff = memo(InstanceAgeDiff);
export default MemoizedInstanceAgeDiff;
