import { Fragment, useContext, useState } from 'react';
import { DateBefore, DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './DurationOverlay.css';
import { DropdownContext } from '../../common/Dropdown';
import Button from '../../common/Button';

const DurationContent = ({
  range,
  onChangeRange,
}: {
  range: DateRange | undefined;
  onChangeRange: (dateRange: DateRange | undefined) => void;
}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    range
  );
  const intervalMatcher: DateBefore = {
    before: new Date(),
  };
  const { setIsOpen } = useContext(DropdownContext);
  return (
    <Fragment>
      <DayPicker
        mode="range"
        selected={selectedRange}
        onSelect={setSelectedRange}
        disabled={intervalMatcher}
        modifiersClassNames={{
          selected: '!bg-theme-primary-600 !text-white',
        }}
      />
      <div className="flex p-4 gap-4 border-t border-theme-neutral-200">
        <Button
          variant="outline"
          className="flex-1"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="flex-1"
          onClick={() => {
            onChangeRange(selectedRange);
            setIsOpen(false);
          }}
        >
          Apply
        </Button>
      </div>
    </Fragment>
  );
};

export default DurationContent;
