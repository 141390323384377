export const encodeUtf8 = (str: string) => {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);
  return uint8Array;
};

export const decodeUtf8 = (uint8Array: Uint8Array | undefined) => {
  if (!uint8Array) return '';
  const decoder = new TextDecoder('utf-8', { ignoreBOM: true });
  const str = decoder.decode(uint8Array);
  return str;
};

export const encodeData = (value: any): Uint8Array => {
  if (typeof value === 'string') {
    return encodeUtf8(value);
  }
  return encodeUtf8(JSON.stringify(value));
};

export const decodeData = (value: Uint8Array) => {
  try {
    return JSON.parse(decodeUtf8(value));
  } catch {
    return decodeUtf8(value);
  }
};
