import { ReactNode } from 'react';
import twClassnames from '../../utils/classnames';
import { Tooltip } from 'react-tooltip';

const Badge = ({
  id,
  logo,
  text,
  className,
  tooltipText,
  logoClassName,
}: {
  id?: string;
  logo?: ReactNode;
  text: string;
  className?: string;
  tooltipText?: string;
  logoClassName?: string;
}) => (
  <div
    id={id}
    data-tooltip-id={`tooltip-${id}`}
    className={twClassnames(
      'py-1 px-3 bg-theme-primary-100 border-theme-primary-400 rounded-lg border text-theme-primary-600 text-sm flex items-center',
      className
    )}
  >
    {logo && <span className={twClassnames('mr-2', logoClassName)}>{logo}</span>}
    {text}
    {tooltipText && (
      <Tooltip id={`tooltip-${id}`} className="z-10 !text-xs" place="top">
        {tooltipText}
      </Tooltip>
    )}
  </div>
);

export default Badge;
