import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../slices/modals';
import Button from '../common/Button';
import { ModalName } from '../modals';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';
import gpuNeutral from '../../assets/gpu-neutral-100.png';
import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import HyperLink from '../common/HyperLink';
import { fetchMachines, getMachines } from '../../slices/machines';
import { AppDispatch } from '../../store';
import AuthButton from '../AuthButton';

const SupplyWaitlist = () => {
  const { user } = useUser();
  const machines = useSelector(getMachines);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchMachines());
  }, [dispatch]);
  const isSignedUp =
    user?.email &&
    machines.filter((mac) => mac.email === user?.email).length > 0;
  return (
    <div className="flex-1 flex flex-col justify-center items-center gap-8">
      <div className="text-center text-theme-neutral-700 text-lg font-light">
        {isSignedUp ? (
          <>
            Thank you for joining the waitlist! We will review your application
            and get back to you shortly.
            <br />
            While waiting for approval, feel free to explore our{' '}
            <HyperLink to="/models" className="text-lg">
              AI inference playground
            </HyperLink>
            !
          </>
        ) : (
          <>
            Hi! We are currently testing our GPU marketplace product.
            <br />
            Sign up for the waitlist if you are interested in supplying GPUs for
            Hyperbolic and we will be in touch shortly. Thank you!
          </>
        )}
      </div>
      {isSignedUp ? (
        <div className="px-5 py-2 border rounded-lg border-theme-primary-200 bg-theme-primary-50">
          Application status:&nbsp;
          <span className="text-theme-primary-600 font-medium">Pending</span>
        </div>
      ) : user ? (
        <Button
          variant="outline"
          className="flex items-center gap-1 text-base"
          onClick={() =>
            dispatch(
              openModal({
                name: ModalName.GPURequestModal,
                props: { mode: 'supply' },
                styles: isMobile
                  ? fullScreenStyles
                  : { content: { maxWidth: '700px' } },
              })
            )
          }
        >
          <FiPlus size={20} /> Join Waitlist
        </Button>
      ) : (
        <AuthButton className="text-base" />
      )}
      <img
        src={gpuNeutral}
        style={{ height: 360 }}
        className="object-contain"
      />
    </div>
  );
};

export default SupplyWaitlist;
