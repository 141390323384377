import { useDispatch, useSelector } from 'react-redux';
import { getUser, signOut } from '../slices/auth';
import { AppDispatch } from '../store';
import DropdownButton from './common/Dropdown/DropdownButton';
import DropdownOverlay from './common/Dropdown/DropdownOverlay';
import Dropdown from './common/Dropdown/Dropdown';
import DropdownMenu from './common/Dropdown/DropdownMenu';
import DropdownMenuItem from './common/Dropdown/DropdownMenuItem';
import { GoTriangleDown } from 'react-icons/go';

const UserDropdown = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const handleLogout = () => dispatch(signOut());
  return (
    <Dropdown>
      <DropdownButton
        className="text-sm"
        variant="ghost"
        PositionIcon={GoTriangleDown}
        iconClassName="ml-0 mt-0.5"
      >
        {user && <div className="mr-2">{user?.email}</div>}
      </DropdownButton>
      <DropdownOverlay>
        <DropdownMenu>
          <DropdownMenuItem onClick={handleLogout}>Log Out</DropdownMenuItem>
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default UserDropdown;
