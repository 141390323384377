import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, ButtonGroupButton } from '../common/ButtonGroup';
import { getModel } from '../../slices/models';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import Button from '../common/Button';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { fullScreenStyles, mobileStyles } from '../../utils/modals';
import { RiListSettingsLine } from 'react-icons/ri';
import TextInputParametersForm from './TextInputParametersForm';
import ModelDropdown from './ModelDropdown';
import ImageInputParametersForm from './ImageInputParametersForm';
import GenerateImageButton from './GenerateImageButton';
import { usePostHog } from 'posthog-js/react';
import AudioInputParametersForm from './AudioInputParametersForm';
import Badge from '../common/Badge';
import {
  badgeClassName,
  displayMaxTokens,
  displayModelPrice,
  mapNumberFormatToTooltip,
} from '../../utils/models';

const mapModelTypeToParamsForm: { [key: string]: any } = {
  llm: TextInputParametersForm,
  image: ImageInputParametersForm,
  audio: AudioInputParametersForm,
};

const ModelInputParameters = () => {
  const posthog = usePostHog();
  const { modelId, mode } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const ParamsComponent =
    mapModelTypeToParamsForm[model?.type || 'llm'] ||
    mapModelTypeToParamsForm['llm'];
  const contextLength = model?.contextLength || model?.maxTokens;
  return (
    <div className="flex flex-col rounded-xl mb-6 border border-theme-primary-100 bg-theme-primary-50 py-6 gap-5 lg:w-[300px]">
      <div className="px-6 flex md:hidden">
        <ButtonGroup className="flex-1">
          <ButtonGroupButton
            active={mode !== 'api'}
            onClick={() => {
              posthog?.capture('Model Switch Clicked', {
                type: 'demo',
              });
              navigate(`/models/${model?.slug}?${searchParams.toString()}`);
            }}
          >
            Demo
          </ButtonGroupButton>
          <ButtonGroupButton
            active={mode === 'api'}
            onClick={() => {
              posthog?.capture('Model Switch Clicked', {
                type: 'api',
              });
              navigate(`/models/${model?.slug}/api?${searchParams.toString()}`);
            }}
          >
            API
          </ButtonGroupButton>
        </ButtonGroup>
        <div>
          <Button
            variant="ghost"
            className="-mr-3"
            onClick={() =>
              dispatch(
                openModal({
                  name: ModalName.InputParametersModal,
                  styles:
                    model?.type === 'image' ? fullScreenStyles : mobileStyles,
                })
              )
            }
          >
            <RiListSettingsLine />
          </Button>
        </div>
      </div>
      <div className="hidden md:flex flex-col lg:overflow-y-auto px-6 gap-4">
        <div>
          <div className="text-sm mb-4 text-black">Models</div>
          <ModelDropdown />
        </div>
        {(model?.numberFormat || contextLength) && (
          <div className="flex gap-2">
            {model?.numberFormat && (
              <Badge
                id={`${model?.slug}-number-format`}
                text={model?.numberFormat.toUpperCase()}
                className={badgeClassName}
                tooltipText={
                  mapNumberFormatToTooltip[model?.numberFormat.toLowerCase()]
                }
              />
            )}
            {contextLength && (
              <Badge
                id={`${model?.slug}-context-length`}
                text={`Context length: ${displayMaxTokens(
                  contextLength
                )} tokens`}
                className={badgeClassName}
              />
            )}
          </div>
        )}
        {model?.price?.amount && (
          <div className="text-sm text-black">
            Price:&nbsp;
            <span className="text-theme-primary-600">
              {displayModelPrice(model?.price, model?.type)}
            </span>
          </div>
        )}
        <ParamsComponent />
      </div>
      {model?.type === 'image' && (
        <div className="hidden md:flex flex-col px-8">
          <GenerateImageButton />
        </div>
      )}
    </div>
  );
};

export default ModelInputParameters;
