import range from 'lodash/range';
import twClassnames from '../../utils/classnames';

interface StepsProgressBarProps {
  progressIndex: number;
}

const stepTitles = [
  'Check Eligibility',
  'Set up Cluster',
  'Final Setup',
];
const steps = range(1, 4).map((step, i) => ({ step, title: stepTitles[i] }));

const StepsProgressBar = ({ progressIndex }: StepsProgressBarProps) => (
  <div className="flex gap-4">
    <div className="flex flex-col items-center gap-2">
      <div
        className={twClassnames(
          'transition h-4 w-4 rounded-full bg-theme-primary-600',
          {
            'border-4 border-white ring-2 ring-theme-neutral-400 bg-theme-neutral-400':
              progressIndex < 0,
            'ring-8 ring-theme-primary-200': progressIndex === 0,
          }
        )}
      />
      <div className="w-0.5 h-24 bg-theme-neutral-200" />
      <div
        className={twClassnames(
          'transition h-4 w-4 rounded-full bg-theme-primary-600',
          {
            'border-4 border-white ring-2 ring-theme-neutral-400 bg-theme-neutral-400':
              progressIndex < 1,
            'ring-8 ring-theme-primary-200': progressIndex === 1,
          }
        )}
      />
      <div className="w-0.5 h-24 bg-theme-neutral-200" />
      <div
        className={twClassnames(
          'transition h-4 w-4 rounded-full bg-theme-primary-600',
          {
            'border-4 border-white bg-theme-neutral-400 ring-2 ring-theme-neutral-400':
              progressIndex < 2,
            'ring-8 ring-theme-primary-200': progressIndex === 2,
          }
        )}
      />
    </div>
    <div className="flex flex-col gap-16 -mt-1.5">
      {steps.map(({ step, title }, i) => (
        <div
          className={twClassnames('flex flex-col gap-2 mb-1.5', {
            'text-theme-primary-600': progressIndex === i,
          })}
          key={title}
        >
          <div className="text-lg font-semibold">Step {step}</div>
          <div>{title}</div>
        </div>
      ))}
    </div>
  </div>
);

export default StepsProgressBar;
