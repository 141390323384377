import { useSelector } from 'react-redux';
import { getAudioStatus, getSortedAudioHistory } from '../../../slices/models';
import twClassnames from '../../../utils/classnames';
import GeneratedAudioSet from './GeneratedAudioSet';
import { ResponseStatus } from '../../../utils/types';
import WeirdCircleIcon from '../../common/icons/WeirdCircleIcon';

const AudioHistory = () => {
  const audioHistory = useSelector(getSortedAudioHistory);
  const audioStatus = useSelector(getAudioStatus);
  const isLoading = audioStatus === ResponseStatus.Loading;
  const noResults = audioHistory.length === 0 && !isLoading;
  return (
    <div
      className={twClassnames('flex-1 px-10 flex flex-col', {
        'flex-col lg:overflow-y-auto divide-y divide-theme-neutral-300':
          !noResults,
        'items-center justify-center': noResults,
      })}
    >
      {noResults ? (
        <>
          <WeirdCircleIcon width={160} height={145.6} className="mb-6" />
          <div className="font-medium text-lg mb-4 text-theme-neutral-600">
            No audio generated yet
          </div>
          <div className="text-sm w-[350px] text-theme-neutral-500 text-center">
            Start by typing some narrative in the text field below
          </div>
        </>
      ) : (
        <>
          {isLoading && (
            <div className="pb-4 pt-4 first:pt-0">
              <div className="animate-pulse">
                <div className="flex flex-col gap-3.5">
                  <div className="h-7 bg-theme-neutral-200 rounded w-1/2 lg:w-1/4" />
                  <div className="h-[52px] bg-theme-neutral-200 rounded w-full lg:w-1/2" />
                  <div className="h-7 bg-theme-neutral-200 rounded w-1/3 lg:w-1/6" />
                  <div className="h-28 bg-theme-neutral-200 rounded w-2/3 lg:w-1/3" />
                  <div className="h-4 bg-theme-neutral-200 rounded w-1/2 lg:w-1/4" />
                </div>
              </div>
            </div>
          )}
          {audioHistory.map((audio, i) => (
            <GeneratedAudioSet
              key={`audio-${audio.created.toString()}`}
              audio={audio}
              position={audioHistory.length - i}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default AudioHistory;
