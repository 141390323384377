import { ReactNode } from 'react';
import twClassnames from '../../utils/classnames';

const MaxWidth = ({
  className,
  children,
  centered,
  ...otherProps
}: {
  className?: string;
  children: ReactNode;
  centered?: boolean;
}) => (
  <div
    className={twClassnames(
      'max-w-screen-xl',
      { 'self-center w-full': centered },
      className
    )}
    {...otherProps}
  >
    <div className="w-full">{children}</div>
  </div>
);

export default MaxWidth;
