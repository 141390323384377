const ImageMountainIcon = ({
  width = 24,
  height = 25,
  color = 'currentColor',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="image-05">
      <path
        id="Icon"
        d="M18.9999 21.5H20.0103C20.9814 21.5 21.467 21.5 21.7347 21.2975C21.9679 21.1211 22.1122 20.8515 22.1296 20.5596C22.1496 20.2246 21.8802 19.8205 21.3415 19.0125L18.3312 14.4969C17.886 13.8292 17.6635 13.4954 17.383 13.3791C17.1377 13.2773 16.8621 13.2773 16.6168 13.3791C16.3363 13.4954 16.1137 13.8292 15.6686 14.4969L14.9244 15.6132M18.9999 21.5L11.3154 10.4002C10.8735 9.76182 10.6525 9.44264 10.3765 9.33044C10.135 9.23228 9.86477 9.23228 9.62331 9.33044C9.3473 9.44264 9.12633 9.76182 8.68439 10.4002L2.7381 18.9893C2.17507 19.8025 1.89356 20.2092 1.90958 20.5473C1.92354 20.8419 2.06676 21.1152 2.30097 21.2943C2.5699 21.5 3.06447 21.5 4.0536 21.5H18.9999ZM20.9999 6.5C20.9999 8.15685 19.6567 9.5 17.9999 9.5C16.343 9.5 14.9999 8.15685 14.9999 6.5C14.9999 4.84315 16.343 3.5 17.9999 3.5C19.6567 3.5 20.9999 4.84315 20.9999 6.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ImageMountainIcon;
