const FilePlusIcon = ({
  width = 17,
  height = 16,
  color = 'currentColor',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g clipPath="url(#clip0_6164_25215)">
      <path
        d="M9.66667 1.3335H4.33333C3.97971 1.3335 3.64057 1.47397 3.39052 1.72402C3.14048 1.97407 3 2.31321 3 2.66683V13.3335C3 13.6871 3.14048 14.0263 3.39052 14.2763C3.64057 14.5264 3.97971 14.6668 4.33333 14.6668H12.3333C12.687 14.6668 13.0261 14.5264 13.2761 14.2763C13.5262 14.0263 13.6667 13.6871 13.6667 13.3335V5.3335L9.66667 1.3335Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72266 1.61084V5.44417H13.5527"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 11.8328V7.99951"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 9.91748H10.4167"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6164_25215">
        <rect
          width="16.6667"
          height="15.3333"
          fill="white"
          transform="translate(0 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FilePlusIcon;
