import Dropdown, { DropdownButton, DropdownOverlay } from '../common/Dropdown';
import DropdownMenu from '../common/Dropdown/DropdownMenu';
import DropdownMenuItem from '../common/Dropdown/DropdownMenuItem';
import { useSelector } from 'react-redux';
import { getModel, getSortedModels } from '../../slices/models';
import { RootState } from '../../store';
import { createModelPath } from '../../utils/models';
import { useParams, useSearchParams } from 'react-router-dom';

const ModelDropdown = () => {
  const { modelId, mode, lang } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const models = useSelector(getSortedModels);
  const modelVariants = model?.variants;
  const currentVariant = searchParams.get('variant') || modelVariants?.[0];
  return (
    <Dropdown>
      <DropdownButton>
        {modelVariants ? (
          currentVariant
        ) : (
          <>
            {model?.name}
            {model?.badgeText && (
              <span className="text-theme-primary-600 ml-1">
                ({model?.badgeText.toLowerCase()})
              </span>
            )}
          </>
        )}
      </DropdownButton>
      <DropdownOverlay>
        <DropdownMenu className="overflow-y-auto max-h-40">
          {modelVariants
            ? modelVariants.map((v) => (
                <DropdownMenuItem
                  key={`dropdown-${v}`}
                  active={currentVariant === v}
                  onClick={() => {
                    setSearchParams({ ...searchParams, variant: v });
                  }}
                >
                  {v}
                </DropdownMenuItem>
              ))
            : models
                .filter((m) => m.type === model?.type)
                .map((m) => (
                  <DropdownMenuItem
                    key={`dropdown-${m.model}`}
                    active={m.model === model?.model}
                    to={createModelPath({ slug: m?.slug, mode, lang })}
                  >
                    {m.name}
                    {m.badgeText && (
                      <span className="text-theme-primary-600 ml-1">
                        ({m.badgeText.toLowerCase()})
                      </span>
                    )}
                  </DropdownMenuItem>
                ))}
        </DropdownMenu>
      </DropdownOverlay>
    </Dropdown>
  );
};

export default ModelDropdown;
