import { ReactNode } from 'react';
import twClassnames from '../utils/classnames';
import Input, { InputProps } from './common/Input';
import { useController } from 'react-hook-form';

interface FormInputProps extends InputProps {
  label?: string;
  labelRight?: ReactNode;
  name: string;
  defaultValue?: string | number;
  control: any;
  containerClassName?: string;
  labelClassName?: string;
  hideAsterick?: boolean;
  rules?: any;
}

const FormInput = ({
  label,
  labelClassName,
  labelRight,
  control,
  defaultValue,
  name,
  rules,
  containerClassName,
  hideAsterick = false,
  ...otherProps
}: FormInputProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  return (
    <div className={twClassnames('flex flex-col mb-4 text-sm', containerClassName)}>
      {label && (
        <label className={twClassnames('mb-2 font-semibold flex text-black', labelClassName)}>
          {label}
          {rules?.required && !hideAsterick && '*'}
          {labelRight && <div className="flex-1 flex justify-end">{labelRight}</div>}
        </label>
      )}
      <Input {...otherProps} {...field} error={!!fieldState.error} />
      {fieldState.error?.type === 'required' && (
        <div className="text-theme-danger-600 mt-2">{label} is required.</div>
      )}
      {fieldState.error?.type === 'minLength' && (
        <div className="text-theme-danger-600 mt-2">
          {fieldState.error?.message}
        </div>
      )}
    </div>
  );
};

export default FormInput;
