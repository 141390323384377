const ChevronLeftDoubleIcon = ({
  height = 24,
  width = 27,
  color = 'currentColor',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M19.723 17L14.3239 12L19.723 7M12.1643 17L6.76526 12L12.1643 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronLeftDoubleIcon;
