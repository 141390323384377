import { ReactNode } from 'react';
import twClassnames from '../../../utils/classnames';

const ButtonGroup = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={twClassnames('flex', className)}>{children}</div>;

export default ButtonGroup;
