import { useDispatch, useSelector } from 'react-redux';
import Button from './common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SignInProvider,
  signIn,
  getAuthError,
  getAuthStatus,
} from '../slices/auth';
import { AppDispatch } from '../store';
import { FaGithub } from 'react-icons/fa6';
import FormInput from './FormInput';
import { FcGoogle } from 'react-icons/fc';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import noop from 'lodash/noop';
import AuthDividerLine from './common/icons/AuthDividerLine';
import ArrowNarrowUpRight from './common/icons/ArrowNarrowUpRight';
import twClassnames from '../utils/classnames';
import { formatAuthError } from '../utils/auth';
import { openModal } from '../slices/modals';
import { ModalName } from './modals';
import { isMobile } from 'react-device-detect';
import { mobileStyles } from '../utils/modals';
import { ResponseStatus } from '../utils/types';
import { usePostHog } from 'posthog-js/react';
import HyperLink from './common/HyperLink';

type UserSignInInputs = {
  email: string;
  password: string;
};

export type AuthMode = 'signIn' | 'register' | 'password';

const AuthForm = ({
  mode = 'register',
  className = '',
  onChangeMode,
  from = '',
  onComplete = noop,
}: {
  mode: AuthMode;
  className?: string;
  onChangeMode: (mode: AuthMode) => void;
  from?: string;
  onComplete?: () => void;
}) => {
  const posthog = usePostHog();
  const authError = useSelector(getAuthError);
  const authStatus = useSelector(getAuthStatus);
  const isLoading = authStatus === ResponseStatus.Loading;
  const dispatch = useDispatch<AppDispatch>();
  const { control, handleSubmit } = useForm<UserSignInInputs>();
  const location = useLocation();
  const navigate = useNavigate();
  const signInCallback = useCallback(() => {
    const fromPath = location.state?.from || from;
    if (fromPath) {
      navigate(location.state?.from || from);
    }
    onComplete();
  }, [location.state?.from, from, navigate, onComplete]);
  const handleGoogleLogin = async () => {
    const providerName = SignInProvider.Google;
    await dispatch(signIn({ providerName, mode })).unwrap();
    posthog?.capture('Login Completed', { type: mode, provider: providerName });
    signInCallback();
  };

  const handleGithubLogin = async () => {
    const providerName = SignInProvider.GitHub;
    await dispatch(signIn({ providerName, mode })).unwrap();
    posthog?.capture('Login Completed', { type: mode, provider: providerName });
    signInCallback();
  };

  const onSubmit = async (data: UserSignInInputs) => {
    const providerName = SignInProvider.Password;
    const response = await dispatch(
      signIn({ providerName, mode, ...data })
    ).unwrap();
    posthog?.capture('Login Completed', { type: mode, provider: providerName });
    if (
      response.providerName === SignInProvider.Password &&
      !response.user.emailVerified
    ) {
      navigate('/verify-email');
    }
    signInCallback();
  };

  return (
    <div className={twClassnames('mb-6', className)}>
      <div className="mb-8 text-2xl font-['AcidGrotesk-Light'] text-black">
        Hyperbolic
      </div>
      <AuthDividerLine width="100%" className="mb-12" />
      {authError && (
        <div className="text-red-500 mb-6">{formatAuthError(authError)}</div>
      )}
      {mode === 'register' ? (
        <>
          {/* <div className="text-lg mb-20">
            Get <span className="text-theme-primary-600">$10</span> free credits
            when you sign up now!
          </div> */}
          <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="email"
              name="email"
              label="Email"
              labelClassName="text-theme-neutral-700 font-medium"
              placeholder="E.g. johndoe@gmail.com"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              hideAsterick
              defaultValue=""
              control={control}
              rules={{ required: true }}
            />
            <FormInput
              type="password"
              name="password"
              label="Password"
              labelClassName="text-theme-neutral-700 font-medium"
              placeholder="Enter your password"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              hideAsterick
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be 8 characters or more.',
                },
              }}
            />
            <Button type="submit" className="w-full" isLoading={isLoading}>
              Sign Up
            </Button>
          </form>
          <div className="flex items-center justify-center">
            Already a user?
            <Button
              variant="link"
              onClick={() => onChangeMode('signIn')}
              className="flex items-center ml-1 text-base"
            >
              Log In
              <ArrowNarrowUpRight className="ml-1" />
            </Button>
          </div>
        </>
      ) : (
        <>
          <Button
            variant="outline"
            onClick={handleGoogleLogin}
            className="flex items-center justify-center mb-5 w-full border-theme-neutral-400 text-theme-neutral-700 hover:border-theme-primary-600"
            disabled={isLoading}
          >
            <FcGoogle size={22} className="mr-4" />
            <span>Continue with Google</span>
          </Button>
          <Button
            variant="outline"
            onClick={handleGithubLogin}
            className="flex items-center justify-center w-full mb-5 border-theme-neutral-400 text-theme-neutral-700 hover:border-theme-primary-600"
            disabled={isLoading}
          >
            <FaGithub color="#171515" size={22} className="mr-4" />
            <span>Continue with GitHub</span>
          </Button>
          <div className="flex gap-8 items-center my-8">
            <div className="h-[1px] bg-theme-neutral-400 flex-1" />
            <div className="text-theme-neutral-400">or Log in with Email</div>
            <div className="h-[1px] bg-theme-neutral-400 flex-1" />
          </div>
          <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="email"
              name="email"
              label="Email"
              labelClassName="text-theme-neutral-700 font-medium"
              defaultValue=""
              placeholder="E.g. johndoe@gmail.com"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-5"
              control={control}
              rules={{ required: true }}
              hideAsterick
            />
            <FormInput
              type="password"
              name="password"
              label="Password"
              labelClassName="text-theme-neutral-700 font-medium"
              labelRight={
                <Button
                  type="button"
                  variant="link"
                  className="font-normal"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      openModal({
                        name: ModalName.ForgotPasswordModal,
                        ...(isMobile && { styles: mobileStyles }),
                      })
                    );
                  }}
                >
                  Forgot password?
                </Button>
              }
              defaultValue=""
              placeholder="Enter your password"
              className="placeholder:text-theme-neutral-400"
              containerClassName="mb-6"
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be 8 characters or more.',
                },
              }}
              hideAsterick
            />
            <Button type="submit" className="w-full" isLoading={isLoading}>
              Log In
            </Button>
          </form>
          <div className="flex items-center justify-center text-theme-neutral-700 flex-wrap gap-1">
            <div>Not registered yet?</div>
            <Button
              variant="link"
              onClick={() => onChangeMode('register')}
              className="flex items-center text-base"
            >
              Create an account
              <ArrowNarrowUpRight className="ml-1" />
            </Button>
          </div>
        </>
      )}
      <div className="flex flex-wrap items-center justify-center text-sm mt-4">
        By signing up, you agree to Hyperbolic's&nbsp;
        <HyperLink
          href="https://hyperbolic.xyz/terms"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
          rel="noreferrer noopener"
          target="_blank"
        >
          privacy policy
        </HyperLink>
        &nbsp;and&nbsp;
        <HyperLink
          href="https://hyperbolic.xyz/privacy"
          className="text-theme-neutral-600 underline hover:text-theme-neutral-700"
          rel="noreferrer noopener"
          target="_blank"
        >
          terms of use
        </HyperLink>
      </div>
    </div>
  );
};

export default AuthForm;
