// import generatedOn from '../assets/generated-on.png';
import logo from '../assets/logo.png';
// @ts-expect-error: no types
import mergeImages from 'merge-images';

export const watermarkImage = (
  image: string,
  { width, height }: { width: number; height: number }
) =>
  mergeImages([
    image,
    { src: logo, x: width - 87, y: height - 87, opacity: 0.7 },
  ]);
