import { useState } from 'react';
import { GoTriangleRight } from 'react-icons/go';
import './ExpandableQuestion.css';
import twClassnames from '../../utils/classnames';
import Markdown from 'react-markdown';
import HyperLink from '../common/HyperLink';
import Button from '../common/Button';
import { openModal } from '../../slices/modals';
import { useDispatch } from 'react-redux';
import { mapStrToModalName } from '../modals';

const ExpandableQuestion = ({
  question,
  answer,
  defaultExpanded,
}: {
  question: string;
  answer?: string;
  defaultExpanded?: boolean;
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <div className="text-theme-neutral-700 flex flex-col gap-3 text-sm">
      <div
        className={twClassnames(
          'hover:text-theme-primary-600 flex items-center transition font-medium cursor-pointer',
          {
            'text-theme-primary-600 font-semibold': expanded,
          }
        )}
        role="button"
        onClick={() => setExpanded(!expanded)}
      >
        <GoTriangleRight
          className={twClassnames('mr-2 transition', { 'rotate-90': expanded })}
        />
        {question}
      </div>
      {expanded && (
        <div className="ml-6">
          <Markdown
            className="expandable-question flex flex-col gap-3 text-theme-neutral-700"
            components={{
              a: (props) => {
                const { href, children, ...rest } = props;
                const isLocalPath = href && href?.startsWith('/');
                const isModal = href && href?.startsWith('~');
                const modalName = href?.split('~')[1];
                if (isModal && modalName) {
                  const modal = mapStrToModalName[modalName];
                  return (
                    <Button
                      variant="link"
                      type="button"
                      className="text-theme-neutral-700 underline hover:text-theme-neutral-600"
                      onClick={() => {
                        dispatch(
                          openModal({
                            name: modal,
                            styles: {
                              content: {
                                maxWidth: '650px',
                              },
                            },
                          })
                        );
                      }}
                    >
                      {children}
                    </Button>
                  );
                }
                return (
                  <HyperLink
                    to={isLocalPath ? href : ''}
                    href={isLocalPath ? undefined : href}
                    className={
                      isLocalPath
                        ? 'text-theme-neutral-700 underline hover:text-theme-neutral-600'
                        : ''
                    }
                    {...(!isLocalPath && {
                      rel: 'noreferrer noopener',
                      target: '_blank',
                    })}
                    {...rest}
                  >
                    {children}
                  </HyperLink>
                );
              },
            }}
          >
            {answer}
          </Markdown>
        </div>
      )}
    </div>
  );
};

export default ExpandableQuestion;
