import hyperFetch from '../utils/fetch';
import { convertKeysToSnake } from '../utils/models';
import { watermarkImage } from '../utils/images';
import Storyblok from '../utils/storyblok';
import { backendHost, isProdEnv } from '../utils/constants';

export const fetchModelsDb = async () => {
  const response = await Storyblok.get('cdn/stories', {
    starts_with: 'models',
    version: isProdEnv ? 'published' : 'draft',
  });
  return response.data.stories.map((s: any) => ({
    ...s.content,
    maxTokens: s.content.maxTokens
      ? parseInt(s.content.maxTokens)
      : s.content.maxTokens,
    defaultMaxTokens: s.content.defaultMaxTokens
      ? parseInt(s.content.defaultMaxTokens)
      : s.content.defaultMaxTokens,
    contextLength: s.content.contextLength
      ? parseInt(s.content.contextLength)
      : s.content.contextLength,
    defaultTemperature: s.content.defaultTemperature
      ? parseFloat(s.content.defaultTemperature)
      : s.content.defaultTemperature,
    defaultTopP: s.content.defaultTopP
      ? parseFloat(s.content.defaultTopP)
      : s.content.defaultTopP,
    slug: s.slug,
  }));
};

export const generateImageDb = async (params: any) => {
  const camelParams: any = convertKeysToSnake(params);
  const response = await hyperFetch(`${backendHost}/v1/image/generation`, {
    method: 'POST',
    body: JSON.stringify({ ...camelParams, backend: 'auto' }),
  });
  const watermarkedImages = await Promise.all(
    response.images.map(async (imageData: any) => {
      const watermarkedImage = await watermarkImage(
        `data:image/png;base64,${imageData.image}`,
        { width: camelParams.width, height: camelParams.height }
      );
      return { ...imageData, watermarkedImage };
    })
  );
  // const json = await response.json();
  return {
    ...response,
    images: watermarkedImages,
    params,
    created: Date.now(),
  };
};

export const generateAudioDb = async (params: any) => {
  const camelParams = convertKeysToSnake(params);
  const response = await hyperFetch(`${backendHost}/v1/audio/generation`, {
    method: 'POST',
    body: JSON.stringify({ ...camelParams, model: 'MeloTTS' }),
  });
  return { ...response, script: params.text, params, created: Date.now() };
};

export const fetchModelPriceDb = async (modelName: string) => {
  const response = await hyperFetch(`${backendHost}/models/${modelName}/price`);
  return { ...response, modelName: modelName.replace('_', '/') };
};
