import { HTMLAttributes, ReactNode } from 'react';
import twClassnames from '../../utils/classnames';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  noHover?: boolean;
  variant?: 'primary' | 'secondary';
}

const Card = ({
  children,
  variant = 'primary',
  className,
  noHover = false,
  ...otherProps
}: CardProps) => (
  <div
    className={twClassnames(
      'rounded-xl bg-theme-primary-50 px-5 py-4 inline-block border border-theme-primary-100',
      {
        'hover:border-theme-primary-600 hover:text-theme-primary-600 transition cursor-pointer':
          !noHover,
        'bg-white shadow-theme-02 rounded-lg border border-theme-primary-200':
          variant === 'secondary',
      },
      className
    )}
    {...otherProps}
  >
    {children}
  </div>
);

export default Card;
