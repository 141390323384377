const GridScanIcon = ({ size = 14, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M4.66667 1.75H3.61667C2.96327 1.75 2.63657 1.75 2.38701 1.87716C2.16749 1.98901 1.98901 2.16749 1.87716 2.38701C1.75 2.63657 1.75 2.96327 1.75 3.61667V4.66667M4.66667 12.25H3.61667C2.96327 12.25 2.63657 12.25 2.38701 12.1228C2.16749 12.011 1.98901 11.8325 1.87716 11.613C1.75 11.3634 1.75 11.0367 1.75 10.3833V9.33333M12.25 4.66667V3.61667C12.25 2.96327 12.25 2.63657 12.1228 2.38701C12.011 2.16749 11.8325 1.98901 11.613 1.87716C11.3634 1.75 11.0367 1.75 10.3833 1.75H9.33333M12.25 9.33333V10.3833C12.25 11.0367 12.25 11.3634 12.1228 11.613C12.011 11.8325 11.8325 12.011 11.613 12.1228C11.3634 12.25 11.0367 12.25 10.3833 12.25H9.33333M9.33333 7C9.33333 8.28866 8.28866 9.33333 7 9.33333C5.71134 9.33333 4.66667 8.28866 4.66667 7C4.66667 5.71134 5.71134 4.66667 7 4.66667C8.28866 4.66667 9.33333 5.71134 9.33333 7Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GridScanIcon;
