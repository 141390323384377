import { sendEmailVerification } from '@firebase/auth';
import Button from '../components/common/Button';
import HyperLink from '../components/common/HyperLink';
import useInterval from '../hooks/useInterval';
import useUser from '../hooks/useUser';
import { fetchUserInfo } from '../slices/auth';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { useCallback } from 'react';
import Layout from '../components/common/Layout';

const VerifyEmail = () => {
  const { user } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const reloadUser = useCallback(async () => {
    if (!user?.emailVerified) {
      await user?.reload();
      // Don't ask, it is some firebase voodoo magic
      if (user?.emailVerified) {
        await user?.getIdToken(true);
        dispatch(fetchUserInfo());
      }
    }
  }, [user, dispatch]);
  useInterval(reloadUser, 5000);
  return (
    <Layout className="justify-center items-center">
      {user?.emailVerified ? (
        <>
          <h1 className="mt-10">Thank you for verifying your email!</h1>
          <div className="mb-8 text-center text-lg">
            Please continue enjoying all the fun AI models and tools.
          </div>
          <HyperLink variant="primary" to="/">
            Return to homepage
          </HyperLink>
        </>
      ) : (
        <>
          <h1 className="mt-10">Please verify your email!</h1>
          <div className="mb-5 text-center text-lg">
            We sent a verification link to your email. Please click through the
            link
            <br />
            so we can verify your email and return here.
          </div>
          <div className="mb-5 text-center text-theme-neutral-500 text-sm">
            If you cannot find the email, please check your spam or click the
            button
            <br />
            below to resend the verification link.
          </div>
          <div>
            <Button
              onClick={() => {
                if (user) sendEmailVerification(user);
              }}
            >
              Resend verification link
            </Button>
          </div>
        </>
      )}
    </Layout>
  );
};

export default VerifyEmail;
