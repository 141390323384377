import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Button from '../common/Button';
import ZapIcon from '../common/icons/ZapIcon';
import { AppDispatch, RootState } from '../../store';
import {
  generateImage,
  getGeneratedImagesStatus,
  getImageInputParameters,
  getModel,
} from '../../slices/models';
import { ImageInputParameters, ResponseStatus } from '../../utils/types';
import { getIsAuthenticated } from '../../slices/auth';
import AuthButton from '../AuthButton';
import twClassnames from '../../utils/classnames';
import omit from 'lodash/omit';
import { usePostHog } from 'posthog-js/react';

const defaultPrompt = 'Kanye west riding a horse on mars';

const GenerateImageButton = ({
  text = 'Generate',
  className = '',
  overrideParams,
  onClick,
}: {
  text?: string;
  className?: string;
  overrideParams?: ImageInputParameters<number, boolean>;
  onClick?: () => void;
}) => {
  const posthog = usePostHog();
  const { modelId } = useParams();
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch<AppDispatch>();
  const inputParameters = useSelector(getImageInputParameters);
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const formParams = {
    modelName: model?.model,
    ...inputParameters.values,
    prompt: inputParameters.values.prompt || defaultPrompt,
  };
  const params = overrideParams || formParams;
  const hasErrors =
    Object.values(inputParameters.errors).length > 0 &&
    Object.values(inputParameters.errors).every((err) => !!err);
  const disabled = hasErrors;

  const generatedImagesStatus = useSelector(getGeneratedImagesStatus);
  const isLoading = generatedImagesStatus === ResponseStatus.Loading;
  if (!isAuthenticated) {
    return (
      <AuthButton
        className={twClassnames(
          'flex w-full items-center justify-center',
          className
        )}
        from={location.pathname}
      />
    );
  }
  return (
    <Button
      className={twClassnames(
        'flex w-full items-center justify-center',
        className
      )}
      onClick={() => {
        posthog?.capture('Generate Image Clicked', { model: modelId });
        dispatch(generateImage(omit(params, 'enableReference')));
        onClick?.();
      }}
      isLoading={isLoading}
      disabled={disabled}
    >
      <ZapIcon size={20} className="mr-2" />
      {text}
    </Button>
  );
};

export default GenerateImageButton;
