import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseStatus } from '../utils/types';
import { RootState } from '../store';
import { redeemCouponCodeDb } from '../services/settings';

interface SettingsSlice {
  couponCodeStatus: ResponseStatus;
  couponCodeError: string;
}

const initialState: SettingsSlice = {
  couponCodeStatus: ResponseStatus.Unfetched,
  couponCodeError: '',
};

const couponCodeConstant = 'couponCode';

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    [`${couponCodeConstant}/pending`]: (state) => {
      state.couponCodeStatus = ResponseStatus.Loading;
      state.couponCodeError = '';
    },
    [`${couponCodeConstant}/fulfilled`]: (state) => {
      state.couponCodeStatus = ResponseStatus.Success;
      state.couponCodeError = '';
    },
    [`${couponCodeConstant}/rejected`]: (state, action: any) => {
      state.couponCodeStatus = ResponseStatus.Failure;
      state.couponCodeError = action.error.message;
    },
    resetCouponCode: () => {
      return initialState;
    },
  },
});

export const { resetCouponCode } = settingsSlice.actions;

export const redeemCouponCode = createAsyncThunk(
  `settings/${couponCodeConstant}`,
  redeemCouponCodeDb
);

export const getCouponCodeStatus = (state: RootState) =>
  state.settings.couponCodeStatus;
export const getCouponCodeError = (state: RootState) =>
  state.settings.couponCodeError;

export default settingsSlice.reducer;
