const AudioWaveSymmetricIcon = ({
  width = 24,
  height = 25,
  color = 'currentColor',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="recording-01">
      <g id="Solid">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 9.5C3.55228 9.5 4 9.94772 4 10.5V14.5C4 15.0523 3.55228 15.5 3 15.5C2.44772 15.5 2 15.0523 2 14.5L2 10.5C2 9.94772 2.44772 9.5 3 9.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 5.5C8.05228 5.5 8.5 5.94772 8.5 6.5L8.5 18.5C8.5 19.0523 8.05229 19.5 7.5 19.5C6.94772 19.5 6.5 19.0523 6.5 18.5L6.5 6.5C6.5 5.94772 6.94772 5.5 7.5 5.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.5C12.5523 2.5 13 2.94772 13 3.5V21.5C13 22.0523 12.5523 22.5 12 22.5C11.4477 22.5 11 22.0523 11 21.5V3.5C11 2.94772 11.4477 2.5 12 2.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 5.5C17.0523 5.5 17.5 5.94772 17.5 6.5V18.5C17.5 19.0523 17.0523 19.5 16.5 19.5C15.9477 19.5 15.5 19.0523 15.5 18.5V6.5C15.5 5.94772 15.9477 5.5 16.5 5.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 9.5C21.5523 9.5 22 9.94772 22 10.5V14.5C22 15.0523 21.5523 15.5 21 15.5C20.4477 15.5 20 15.0523 20 14.5V10.5C20 9.94772 20.4477 9.5 21 9.5Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default AudioWaveSymmetricIcon;
