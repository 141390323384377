import Layout from '../components/common/Layout';

const Team = () => {
  return (
    <Layout>
      <h2>Team</h2>
      <div className="flex items-center text-theme-neutral-700 mb-6">
        <div>Team info coming soon!</div>
      </div>
    </Layout>
  );
};

export default Team;
