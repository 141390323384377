import { ReactNode } from 'react';
import { formatCurrency } from '../../../../../utils/value';

const BillingItemHeader = ({
  title,
  cost,
  subtitle,
}: {
  title: string;
  cost?: number;
  subtitle?: ReactNode;
}) => {
  return (
    <div className="flex flex-col mb-6">
      <div className="flex items-center justify-between font-bold">
        <div className="text-lg font-semibold text-theme-neutral-700">
          {title}
        </div>
        {!!cost && (
          <div>
            {formatCurrency(cost, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        )}
      </div>
      {subtitle}
    </div>
  );
};

export default BillingItemHeader;
