import { TextareaHTMLAttributes, forwardRef } from 'react';
import TextArea from './TextArea';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import twClassnames from '../../utils/classnames';

interface TextAreaContainerProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  error?: string;
  infoTooltip?: string;
}

const TextAreaContainer = forwardRef<
  HTMLTextAreaElement,
  TextAreaContainerProps
>(
  (
    {
      id,
      label,
      labelClassName,
      required = false,
      error,
      infoTooltip,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div className="flex flex-col mb-4">
        {label && (
          <label
            className={twClassnames(
              'text-black mr-3 mb-2 flex items-center text-sm',
              labelClassName
            )}
          >
            <div>
              {label}
              {required && '*'}
            </div>
            {infoTooltip && (
              <AiOutlineInfoCircle
                data-tooltip-id={`info-${id}`}
                className="ml-2 text-theme-neutral-600 outline-theme-primary-700"
              />
            )}
            <Tooltip id={`info-${id}`} className="z-10" place="top">
              {infoTooltip}
            </Tooltip>
          </label>
        )}
        <TextArea
          ref={ref}
          containerClassName={error ? '!border-theme-danger-600' : ''}
          {...otherProps}
        />
        {error && <div className="text-theme-danger-600 mt-2">{error}</div>}
      </div>
    );
  }
);

export default TextAreaContainer;
