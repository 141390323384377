import { ReactNode, useContext } from 'react';
import DropdownContext from './DropdownContext';
import twClassnames from '../../../utils/classnames';

const DropdownOverlay = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { isOpen } = useContext(DropdownContext);
  if (!isOpen) return;
  return (
    <div
      className={twClassnames(
        'w-full absolute right-0 z-20 mt-2 origin-top-right rounded-lg shadow-theme-01 focus:outline-none transform opacity-100 scale-100 bg-white',
        className
      )}
    >
      {children}
    </div>
  );
};

export default DropdownOverlay;
