import ModalClose from '../common/ModalClose';
import { AddFunds } from '../common/AddFunds';

const AddFundsModal = ({
  onClose,
  defaultAmount = 25,
}: {
  onClose: () => void;
  defaultAmount?: number;
}) => {
  return (
    <div className="p-4">
      <ModalClose className="right-6 top-6" onClose={onClose} />
      <AddFunds defaultAmount={defaultAmount} />
    </div>
  );
};

export default AddFundsModal;
