import flatMap from 'lodash/flatMap';
import { InstanceHistory } from '../../slices/usage';
import {
  InstanceHardware,
  InstanceHardwareCPU,
  InstanceHardwareGPU,
  InstanceHardwareRAM,
  InstanceHardwareStorage,
  InstanceStatus,
} from '../../utils/types';
import InstanceCard from './InstanceCard';
import {
  calculateGPURam,
  calculateRamCapacity,
  calculateStorageCapacity,
  computePrice,
  displayDuration,
  displayModelName,
  displayPricePeriod,
  findGpuPrice,
} from '../../utils/instances';
import { differenceInCalendarDays, intervalToDuration } from 'date-fns';

// TODO: move to utils or combine with formatInstance
const formatInstanceHistory = (instanceHistory: InstanceHistory) => {
  const hardwareArr: InstanceHardware[] = flatMap(
    instanceHistory?.hardware || [],
    (item) => Object.values(item)
  );
  const gpu: InstanceHardwareGPU = hardwareArr.find(
    (item) => item.hardware_type === 'gpu'
  ) as InstanceHardwareGPU;
  const cpu: InstanceHardwareCPU = hardwareArr.find(
    (item) => item.hardware_type === 'cpu'
  ) as InstanceHardwareCPU;
  const ram: InstanceHardwareRAM = hardwareArr.find(
    (item) => item.hardware_type === 'ram'
  ) as InstanceHardwareRAM;
  const storage: InstanceHardwareStorage = hardwareArr.find(
    (item) => item.hardware_type === 'storage'
  ) as InstanceHardwareStorage;
  const gpuCount = instanceHistory.gpu_count;
  const gpuRamGB = calculateGPURam(gpu?.ram);
  const totalPrice = computePrice(gpu?.model, gpuCount, {
    price: instanceHistory.price,
  });
  const storageCapacity = calculateStorageCapacity(storage);
  const ramCapacity = calculateRamCapacity(ram);
  return {
    ...instanceHistory,
    gpu,
    cpu,
    ram,
    storage,
    gpuCount,
    gpuRamGB,
    totalPrice,
    storageCapacity,
    ramCapacity,
  };
};

const InstanceHistoryCard = ({
  instanceHistory,
}: {
  instanceHistory: InstanceHistory;
}) => {
  const { gpuCount, gpuRamGB, gpu, storageCapacity, cpu, ramCapacity } =
    formatInstanceHistory(instanceHistory);

  const city = 'San Francisco';
  const region = 'North America';
  const country = 'US';

  // const { upload, download } = instanceHistory?.network || {};

  const endOfYear = new Date('12/31/2024');
  const max = differenceInCalendarDays(endOfYear, Date.now());
  const pricePeriod = instanceHistory?.price?.period;

  const startDate = new Date(instanceHistory.started_at);
  const endDate = new Date(instanceHistory.terminated_at);
  const diff = intervalToDuration({ end: endDate, start: startDate });

  return (
    <InstanceCard
      id={`${instanceHistory.instance_name}-${instanceHistory.started_at}`}
      status={InstanceStatus.offline}
      gpuCount={gpuCount}
      gpuModelName={displayModelName(gpu?.model)}
      gpuRamGB={gpuRamGB}
      storageCapacity={storageCapacity}
      cpuCores={cpu?.cores}
      ramGB={ramCapacity}
      location={`${country || city}${
        region || country ? `, ${region || country}` : ''
      }`}
      maxDays={max}
      endOfYear={endOfYear}
      gpuPrice={findGpuPrice(gpu?.model, { price: instanceHistory.price })}
      pricePeriod={displayPricePeriod(pricePeriod)}
      actionButton={
        <div className="text-xs text-theme-neutral-600">
          Age: {displayDuration(diff)}
        </div>
      }
    />
  );
};

export default InstanceHistoryCard;
