import { ReactNode, useContext } from 'react';
import twClassnames from '../../../utils/classnames';
import DropdownContext from './DropdownContext';
import Button from '../Button';
import { FaChevronDown } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { ButtonVariant } from '../../../utils/button';

const DropdownButton = ({
  children,
  className,
  PositionIcon = FaChevronDown,
  iconClassName,
  variant = 'dropdown',
  displayPosition = true,
  textClassName,
}: {
  children: ReactNode;
  className?: string;
  PositionIcon?: IconType;
  iconClassName?: string;
  variant?: ButtonVariant;
  displayPosition?: boolean;
  textClassName?: string
}) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);
  return (
    <Button
      type="button"
      variant={variant}
      className={twClassnames(
        'flex items-center justify-end w-full text-sm rounded-lg',
        className
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={twClassnames('truncate text-ellipsis flex-1 text-left flex items-center', textClassName)}>
        {children}
      </span>
      {displayPosition && (
        <PositionIcon
          className={twClassnames(
            'ml-1 transition',
            {
              'rotate-180': isOpen,
            },
            iconClassName
          )}
          size={16}
        />
      )}
    </Button>
  );
};

export default DropdownButton;
