import { useForm } from 'react-hook-form';
import FormInput from '../FormInput';
import Button from '../common/Button';
import Card from '../common/Card';
import FormSelect from '../FormSelect';
import { gpuTypes, interconnectNetworks } from '../../utils/instances';
import { Option } from '../../utils/types';
import FormTextArea from '../FormTextArea';
import FormCalendar from '../FormCalendar';

export type NewMachineInputs = {
  email: string;
  name: string;
  phone: string;
  gpuModel: Option;
  interconnectNetwork: Option;
  cpuCores: string;
  storageCapacity: string;
  networkBandwidth: string;
  operatingSystem: string;
  location: string;
  comments: string;
  startDate: Date;
  endDate: Date;
};

const NewMachineForm = ({
  onSubmit,
  isSupply = false,
}: {
  onSubmit: (data: any) => void;
  isSupply?: boolean;
}) => {
  // const [showMinDuration, setShowMinDuration] = useState(false);
  const { control, handleSubmit } = useForm<NewMachineInputs>();

  return (
    <form className="flex flex-col mb-10" onSubmit={handleSubmit(onSubmit)}>
      <Card noHover className="mb-6">
        <h2>Your details</h2>
        <div className="lg:grid lg:grid-cols-2 gap-3">
          <FormInput
            control={control}
            defaultValue=""
            type="email"
            label="Email"
            name="email"
            rules={{ required: true }}
          />
          <FormInput
            control={control}
            defaultValue=""
            label="Name"
            name="name"
            rules={{ required: true }}
          />
          <FormInput
            control={control}
            defaultValue=""
            label="Phone number"
            name="phone"
          />
        </div>
      </Card>
      <Card noHover className="mb-6">
        <h2>GPU details</h2>
        <div className="lg:grid lg:grid-cols-2 gap-3">
          <FormSelect
            control={control}
            options={gpuTypes}
            label="GPU model"
            name="gpuModel"
            rules={{ required: true }}
          />
          <FormInput
            control={control}
            defaultValue=""
            label="Number of GPUs"
            name="gpuCount"
            type="number"
            rules={{ required: true }}
          />
          {isSupply && (
            <>
              <FormSelect
                control={control}
                options={interconnectNetworks}
                label="Interconnect Network"
                name="interconnectNetwork"
              />
              <FormInput
                control={control}
                defaultValue=""
                label="Node CPU RAM (GB)"
                name="ram"
              />
              <FormInput
                control={control}
                defaultValue=""
                label="CPU Cores per node"
                name="cpuCores"
              />
              <FormInput
                control={control}
                defaultValue=""
                label="Node storage capacity (GB)"
                name="storageCapacity"
                type="number"
              />
              <FormInput
                control={control}
                defaultValue=""
                label="Cluster interface (K8s, Slurm, VM etc)"
                name="clusterInterface"
              />
              <FormInput
                control={control}
                defaultValue=""
                type="number"
                label="Network bandwidth"
                name="networkBandwidth"
              />
              <FormInput
                control={control}
                defaultValue=""
                label="Operating System (OS)"
                name="operatingSystem"
              />
            </>
          )}
        </div>
      </Card>
      {isSupply && (
        <Card noHover className="mb-6">
          <h2>Pricing and location</h2>
          <div className="lg:grid lg:grid-cols-2 gap-3">
            <FormInput
              control={control}
              defaultValue=""
              label="Price GPU / hr"
              name="price"
              type="number"
            />
            <FormInput
              control={control}
              defaultValue=""
              label="Location of the machine (Country level is sufficient)"
              name="location"
            />
          </div>
        </Card>
      )}
      <Card noHover className="mb-6">
        <h2>Availability</h2>
        {isSupply && (
          <div className="lg:grid lg:grid-cols-2 gap-3">
            <FormCalendar
              control={control}
              label="Start date"
              name="startDate"
              defaultValue={new Date()}
            />
            <FormCalendar
              control={control}
              label="End date"
              name="endDate"
              rules={{
                validate: {
                  greaterThanStartDate: (
                    value: Date,
                    formValue: NewMachineInputs
                  ) => {
                    if (value) {
                      const response =
                        value?.getTime() >= formValue.startDate?.getTime();
                      return response;
                    }
                    return true;
                  },
                },
              }}
            />
          </div>
        )}
        <div className="flex">
          <FormInput
            label="Minimum duration (days)"
            name="minDuration"
            defaultValue=""
            type="number"
            control={control}
          />
        </div>
      </Card>
      <Card noHover className="mb-6">
        <h2>Additional details</h2>
        <FormTextArea
          control={control}
          defaultValue=""
          label="Anything else you'd like to let us know?"
          name="additionalDetails"
        />
      </Card>

      <Button className="mt-8" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default NewMachineForm;
