import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import { getPublicKeyStatus, updatePublicKey } from '../../slices/auth';
import { ResponseStatus } from '../../utils/types';

const ResetPublicKeyModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const publicKeyStatus = useSelector(getPublicKeyStatus);
  const publicKeyLoading = publicKeyStatus === ResponseStatus.Loading;
  return (
    <div className="flex flex-col gap-3 p-3">
      <ModalClose onClose={onClose} />
      <div className="text-lg font-semibold text-theme-neutral-700">
        Do you want to reset your public key?
      </div>
      <div className="text-sm font-light mb-2">
        You will <span className="text-theme-danger-600">lose access</span> to
        any instances created with your old key.
        <br />
        Consider terminating active instances before proceeding.
      </div>
      <div className="flex items-center gap-3">
        <Button variant="neutral-outline" onClick={onClose} className="flex-1">
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={async () => {
            await dispatch(updatePublicKey({ publicKey: '' }));
            onClose();
          }}
          className="flex-1"
          isLoading={publicKeyLoading}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ResetPublicKeyModal;
