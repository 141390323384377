const ArrowRight = ({ size = 30, color = 'currentColor', ...otherProps }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="arrow-right">
      <path
        id="Icon"
        d="M6.25 15H23.75M23.75 15L15 6.25M23.75 15L15 23.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ArrowRight;
