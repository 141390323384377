import { IoDocumentTextOutline } from 'react-icons/io5';
import { GeneratedAudio } from '../../../slices/models';
import ParamBadge from '../ParamBadge';
import AudioWaveIcon from '../../common/icons/AudioWaveIcon';
import { format } from 'date-fns';
import AudioPlayer from '../../common/AudioPlayer';
import CopyButton from '../../common/CopyButton';
import { FiArrowDownCircle } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import { downloadFile } from '../../../utils/download';
import AudioScript from './AudioScript';

// const generateRandomNumber = () => Math.floor(Math.random() * 10) + 1;

const GeneratedAudioSet = ({
  audio,
  position,
}: {
  audio: GeneratedAudio;
  position: number;
}) => {
  const avgInfTime = audio.inference_time;
  return (
    <div className="pb-4 pt-4 first:pt-0">
      <div className="flex flex-col gap-3.5">
        <div className="flex gap-2">
          <ParamBadge text="Script" icon={<IoDocumentTextOutline />} />
          <CopyButton
            id={audio.id}
            copyText={audio.script}
            className="border border-theme-neutral-300 rounded py-1 px-2 text-theme-neutral-700 hover:border-theme-primary-600 hover:text-theme-primary-600 cursor-pointer !no-underline"
            tooltipText="Script copied!"
            size={12}
            text="Copy"
          />
        </div>
        <div className="flex">
          <AudioScript script={audio.script} />
        </div>
        <div className="flex">
          <ParamBadge text="Audio" icon={<AudioWaveIcon />} />
        </div>
        <div className="flex gap-5">
          <AudioPlayer
            image={`/images/audio-${position % 10 || 10}.png`}
            audio={audio.audio}
            title={`Audio - ${position}`}
          />
          <div className="flex items-center">
            <button
              className="border-theme-primary-600 group/button cursor-pointer transition-color"
              data-tooltip-id={`info-download-${audio.id}`}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(
                  `data:audio/mpeg3;base64,${audio.audio}`,
                  `audio-${position}.mp3`
                );
              }}
            >
              <FiArrowDownCircle className="text-theme-primary-600" size={24} />
              <Tooltip
                id={`info-download-${audio.id}`}
                className="z-10"
                place="top"
              >
                Download
              </Tooltip>
            </button>
          </div>
        </div>
        <div className="text-xs flex items-center">
          <div className="mr-2">
            {format(audio.created, 'h:mm:ss a')}{' '}
            {format(audio.created, 'M/dd/yyyy')}
          </div>
          generated in&nbsp;
          <span className="text-theme-primary-600">
            {Math.round(avgInfTime * 100) / 100} secs
          </span>
          {/* &nbsp;/&nbsp;audio */}
        </div>
      </div>
    </div>
  );
};

export default GeneratedAudioSet;
